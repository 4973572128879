export const tissueColors = new Map();

tissueColors.set("Adipose Tissue", "rgb(242, 104, 34)");
tissueColors.set("Adipose - Subcutaneous", "rgb(242, 104, 34)");
tissueColors.set("Adipose - Visceral (Omentum)", "rgb(251, 169, 26)");
tissueColors.set("Adrenal Gland", "rgb(94, 186, 71)");
tissueColors.set("Artery - Aorta", "rgb(241, 88, 89)");
tissueColors.set("Artery - Coronary", "rgb(248, 167, 153)");
tissueColors.set("Artery - Tibial", "rgb(238, 33, 36)");
tissueColors.set("Bladder", "rgb(169, 29, 34)");
tissueColors.set("Blood", "rgb(221, 66, 151)");
tissueColors.set("Blood Vessel", "rgb(238, 33, 36)");
tissueColors.set("Brain", "rgb(235, 231, 27)");
tissueColors.set("Breast", "rgb(74, 194, 197)");
tissueColors.set("Cervix Uteri", "rgb(203, 170, 209)");
tissueColors.set("Colon", "rgb(202, 152, 85)");
tissueColors.set("Esophagus", "rgb(139, 114, 87)");
tissueColors.set("Heart", "rgb(101, 44, 144)");
tissueColors.set("Kidney", "rgb(116, 202, 193)");
tissueColors.set("Muscle", "rgb(171, 168, 212)");
tissueColors.set("Nerve", "rgb(253, 216, 0)");
tissueColors.set("Salivary Gland", "rgb(153, 187, 137)");
tissueColors.set("Skin", "rgb(111, 116, 182)");
tissueColors.set("Small Intestine", "rgb(86, 86, 36)");
tissueColors.set("Brain - Amygdala", "rgb(235, 231, 27)");
tissueColors.set(
  "Brain - Anterior cingulate cortex (BA24)",
  "rgb(235, 231, 2)"
);
tissueColors.set("Brain - Caudate (basal ganglia)", "rgb(235, 231, 27)");
tissueColors.set("Brain - Cerebellar Hemisphere", "rgb(235, 231, 27)");
tissueColors.set("Brain - Cerebellum", " rgb(235, 231, 27)");
tissueColors.set("Brain - Cortex", "rgb(235, 231, 27)");
tissueColors.set("Brain - Frontal Cortex (BA9)", "rgb(235, 231, 27)");
tissueColors.set("Brain - Hippocampus", "rgb(235, 231, 27)");
tissueColors.set("Brain - Hypothalamus", "rgb(235, 231, 27)");
tissueColors.set(
  "Brain - Nucleus accumbens (basal ganglia)",
  "rgb(235, 231, 27)"
);
tissueColors.set("Brain - Putamen (basal ganglia)", "rgb(235, 231, 27)");
tissueColors.set("Brain - Spinal cord (cervical c-1)", "rgb(235, 231, 27)");
tissueColors.set("Brain - Substantia nigra", "rgb(235, 231, 27)");
tissueColors.set("Breast - Mammary Tissue", "rgb(74, 194, 197)");
tissueColors.set("Cells - Cultured fibroblasts", "rgb(177, 226, 244)");
tissueColors.set("Cells - EBV-transformed lymphocytes", "rgb(163, 114, 177)");
tissueColors.set("Cervix - Ectocervix", "rgb(252, 204, 203)");
tissueColors.set("Cervix - Endocervix", "rgb(203, 170, 209)");
tissueColors.set("Colon - Sigmoid", "rgb(238, 187, 119)");
tissueColors.set("Colon - Transverse", "rgb(202, 152, 85)");
tissueColors.set("Esophagus - Gastroesophageal Junction", "rgb(139, 114, 87)");
tissueColors.set("Esophagus - Mucosa", "rgb(82, 38, 14)");
tissueColors.set("Esophagus - Muscularis", "rgb(187, 153, 136)");
tissueColors.set("Fallopian Tube", "rgb(252, 204, 203)");
tissueColors.set("Heart - Atrial Appendage", "rgb(122, 82, 161)");
tissueColors.set("Heart - Left Ventricle", "rgb(101, 44, 144)");
tissueColors.set("Kidney - Cortex", "rgb(116, 202, 193)");
tissueColors.set("Kidney - Medulla", "rgb(121, 200, 171)");
tissueColors.set("Liver", "rgb(170, 187, 102)");
tissueColors.set("Lung", "rgb(164, 205, 57)");
tissueColors.set("Minor Salivary Gland", "rgb(153, 187, 137)");
tissueColors.set("Muscle - Skeletal", "rgb(171, 168, 212)");
tissueColors.set("Nerve - Tibial", "rgb(253, 216, 0)");
tissueColors.set("Ovary", "rgb(225, 174, 208)");
tissueColors.set("Pancreas", "rgb(153, 87, 36)");
tissueColors.set("Pituitary", "rgb(179, 216, 143)");
tissueColors.set("Prostate", "rgb(221, 221, 22)");
tissueColors.set("Skin - Not Sun Exposed (Suprapubic)", "rgb(58, 83, 164)");
tissueColors.set("Skin - Sun Exposed (Lower leg)", "rgb(111, 116, 182)");
tissueColors.set("Small Intestine - Terminal Ileum", "rgb(86, 86, 36)");
tissueColors.set("Spleen", "rgb(119, 137, 85)");
tissueColors.set("Stomach", "rgb(255, 221, 153)");
tissueColors.set("Testis", "rgb(170, 171, 171)");
tissueColors.set("Thyroid", "rgb(24, 104, 51)");
tissueColors.set("Uterus", "rgb(200, 120, 178)");
tissueColors.set("Vagina", "rgb(239, 88, 152)");
tissueColors.set("Whole Blood", "rgb(221, 66, 151)");

export const tissueTypeColors = {};

tissueTypeColors["Adipose-Subcutaneous"] = "rgb(242, 104, 34)";
tissueTypeColors["Adipose-Visceral-(Omentum)"] = "rgb(251, 169, 26)";
tissueTypeColors["Adrenal-Gland"] = "rgb(94, 186, 71)";
tissueTypeColors["Artery-Aorta"] = "rgb(241, 88, 89)";
tissueTypeColors["Artery-Coronary"] = "rgb(248, 167, 153)";
tissueTypeColors["Artery-Tibial"] = "rgb(238, 33, 36)";
tissueTypeColors["Bladder"] = "rgb(169, 29, 34)";
tissueTypeColors["Brain-Amygdala"] = "rgb(235, 231, 27)";
tissueTypeColors["Brain-Anterior-cingulate-cortex-(BA24)"] =
  "rgb(235, 231, 27)";
tissueTypeColors["Brain-Caudate-(basal-ganglia)"] = "rgb(235, 231, 27)";
tissueTypeColors["Brain-Cerebellar-Hemisphere"] = "rgb(235, 231, 27)";
tissueTypeColors["Brain-Cerebellum"] = "rgb(235, 231, 27)";
tissueTypeColors["Brain-Cortex"] = "rgb(235, 231, 27)";
tissueTypeColors["Brain-Frontal-Cortex-(BA9)"] = "rgb(235, 231, 27)";
tissueTypeColors["Brain-Hippocampus"] = "rgb(235, 231, 27)";
tissueTypeColors["Brain-Hypothalamus"] = "rgb(235, 231, 27)";
tissueTypeColors["Brain-Nucleus-accumbens-(basal-ganglia)"] =
  "rgb(235, 231, 27)";
tissueTypeColors["Brain-Putamen-(basal-ganglia)"] = "rgb(235, 231, 27)";
tissueTypeColors["Brain-Spinal-cord-(cervical-c-1)"] = "rgb(235, 231, 27)";
tissueTypeColors["Brain-Substantia-nigra"] = "rgb(235, 231, 27)";
tissueTypeColors["Breast-Mammary-Tissue"] = "rgb(74, 194, 197)";
tissueTypeColors["Cells-Cultured-fibroblasts"] = "rgb(177, 226, 244)";
tissueTypeColors["Cells-EBV-transformed-lymphocytes"] = "rgb(163, 114, 177)";
tissueTypeColors["Cervix-Ectocervix"] = "rgb(252, 204, 203)";
tissueTypeColors["Cervix-Endocervix"] = "rgb(203, 170, 209)";
tissueTypeColors["Colon-Sigmoid"] = "rgb(238, 187, 119)";
tissueTypeColors["Colon-Transverse"] = "rgb(202, 152, 85)";
tissueTypeColors["Esophagus-Gastroesophageal-Junction"] = "rgb(139, 114, 87)";
tissueTypeColors["Esophagus-Mucosa"] = "rgb(82, 38, 14)";
tissueTypeColors["Esophagus-Muscularis"] = "rgb(187, 153, 136)";
tissueTypeColors["Fallopian-Tube"] = "rgb(252, 204, 203)";
tissueTypeColors["Heart-Atrial-Appendage"] = "rgb(122, 82, 161)";
tissueTypeColors["Heart-Left-Ventricle"] = "rgb(101, 44, 144)";
tissueTypeColors["Kidney-Cortex"] = "rgb(116, 202, 193)";
tissueTypeColors["Kidney-Medulla"] = "rgb(121, 200, 171)";
tissueTypeColors["Liver"] = "rgb(170, 187, 102)";
tissueTypeColors["Lung"] = "rgb(164, 205, 57)";
tissueTypeColors["Minor-Salivary-Gland"] = "rgb(153, 187, 137)";
tissueTypeColors["Muscle-Skeletal"] = "rgb(171, 168, 212)";
tissueTypeColors["Nerve-Tibial"] = "rgb(253, 216, 0)";
tissueTypeColors["Ovary"] = "rgb(225, 174, 208)";
tissueTypeColors["Pancreas"] = "rgb(153, 87, 36)";
tissueTypeColors["Pituitary"] = "rgb(179, 216, 143)";
tissueTypeColors["Prostate"] = "rgb(221, 221, 221)";
tissueTypeColors["Skin-Not-Sun-Exposed-(Suprapubic)"] = "rgb(58, 83, 164)";
tissueTypeColors["Skin-Sun-Exposed-(Lower-leg)"] = "rgb(111, 116, 182)";
tissueTypeColors["Small-Intestine-Terminal-Ileum"] = "rgb(86, 86, 36)";
tissueTypeColors["Spleen"] = "rgb(119, 137, 85)";
tissueTypeColors["Stomach"] = "rgb(255, 221, 153)";
tissueTypeColors["Testis"] = "rgb(170, 171, 171)";
tissueTypeColors["Thyroid"] = "rgb(24, 104, 51)";
tissueTypeColors["Uterus"] = "rgb(200, 120, 178)";
tissueTypeColors["Vagina"] = "rgb(239, 88, 152)";
tissueTypeColors["Whole-Blood"] = "rgb(221, 66, 151)";
