export const GWAS_SIGNIFICANT_SNPS = {
  "PTM-glia": [
    {
      fdr: 1.0210143590303093,
      trait: "ADHD-meta-filtered",
      score: -0.0018630028,
      TF_impact: "none",
      snp: "rs76708321",
      coordinates: { chromosome: "chr2", position: 10810992 },
    },
    {
      fdr: 2.312070131102486,
      trait: "MDD",
      score: 0.062163353,
      TF_impact: "none",
      snp: "rs7206087",
      coordinates: { chromosome: "chr16", position: 65787218 },
    },
    {
      fdr: 1.8242611109175422,
      trait: "ADHD-meta-filtered",
      score: -0.030678272,
      TF_impact: "pos",
      snp: "rs530614",
      coordinates: { chromosome: "chr6", position: 33747004 },
    },
    {
      fdr: 1.7982373841392425,
      trait: "bipolar-2019",
      score: -0.029660702,
      TF_impact: "none",
      snp: "rs28464158",
      coordinates: { chromosome: "chr8", position: 9606262 },
    },
    {
      fdr: 1.3286642124301233,
      trait: "bipolar-2019",
      score: 0.011020184,
      TF_impact: "pos",
      snp: "rs73050128",
      coordinates: { chromosome: "chr7", position: 1922246 },
    },
    {
      fdr: 1.0520894599498234,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.003165245,
      TF_impact: "none",
      snp: "rs4729566",
      coordinates: { chromosome: "chr7", position: 100016690 },
    },
    {
      fdr: 0.009630186209624752,
      trait: "bipolar-2012",
      score: 0.309165,
      TF_impact: "pos",
      snp: "rs13321742",
      coordinates: { chromosome: "chr3", position: 171802003 },
    },
    {
      fdr: 2.161500478959488,
      trait: "MDD",
      score: -0.04945612,
      TF_impact: "pos",
      snp: "rs34002388",
      coordinates: { chromosome: "chr1", position: 49293168 },
    },
    {
      fdr: 1.0609385734065537,
      trait: "bipolar-II",
      score: -0.0034871101,
      TF_impact: "none",
      snp: "rs72674307",
      coordinates: { chromosome: "chr14", position: 42215423 },
    },
    {
      fdr: 0.01372293192548029,
      trait: "anxiety",
      score: 0.30062294,
      TF_impact: "pos",
      snp: "rs16869385",
      coordinates: { chromosome: "chr6", position: 32176214 },
    },
    {
      fdr: 0.0006451067728008638,
      trait: "bipolar-2019",
      score: -0.36689854,
      TF_impact: "pos",
      snp: "rs55848443",
      coordinates: { chromosome: "chr4", position: 37219430 },
    },
    {
      fdr: 4.9641103264425725e-5,
      trait: "MDD",
      score: -0.41411018,
      TF_impact: "neg",
      snp: "rs10263892",
      coordinates: { chromosome: "chr7", position: 32785569 },
    },
    {
      fdr: 0.0005757599587588947,
      trait: "MDD",
      score: 0.36704874,
      TF_impact: "none",
      snp: "rs2000919",
      coordinates: { chromosome: "chr11", position: 112591113 },
    },
    {
      fdr: 1.0980179682493674,
      trait: "scz",
      score: -0.004779339,
      TF_impact: "none",
      snp: "rs7200336",
      coordinates: { chromosome: "chr16", position: 4372606 },
    },
    {
      fdr: 5.6869891864449814e-6,
      trait: "bipolar-II",
      score: -0.45144844,
      TF_impact: "pos",
      snp: "rs10503626",
      coordinates: { chromosome: "chr8", position: 18689574 },
    },
    {
      fdr: 1.870007406958515e-9,
      trait: "bipolar-2019",
      score: 0.5577707,
      TF_impact: "none",
      snp: "rs6478741",
      coordinates: { chromosome: "chr9", position: 126478770 },
    },
    {
      fdr: 1.012550997160931,
      trait: "bipolar-I",
      score: -0.0014882088,
      TF_impact: "pos",
      snp: "rs72669161",
      coordinates: { chromosome: "chr4", position: 122537923 },
    },
    {
      fdr: 2.316095188202996,
      trait: "bipolar-I",
      score: -0.06514692,
      TF_impact: "pos",
      snp: "rs12623170",
      coordinates: { chromosome: "chr2", position: 27797290 },
    },
    {
      fdr: 1.2331926724519924,
      trait: "MDD",
      score: -0.009542942,
      TF_impact: "pos",
      snp: "rs6673687",
      coordinates: { chromosome: "chr1", position: 205701241 },
    },
    {
      fdr: 1.0734804513545049,
      trait: "bipolar-2019",
      score: 0.0021328926,
      TF_impact: "none",
      snp: "rs1995210",
      coordinates: { chromosome: "chr3", position: 2391127 },
    },
    {
      fdr: 0.07021746141954069,
      trait: "bipolar-I",
      score: 0.25666142,
      TF_impact: "none",
      snp: "rs3095337",
      coordinates: { chromosome: "chr6", position: 30769814 },
    },
    {
      fdr: 1.8558205684463998,
      trait: "autism",
      score: 0.030548096,
      TF_impact: "pos",
      snp: "rs2945236",
      coordinates: { chromosome: "chr8", position: 8310872 },
    },
    {
      fdr: 1.0344873376912511,
      trait: "MDD",
      score: -0.0024266243,
      TF_impact: "none",
      snp: "rs116974312",
      coordinates: { chromosome: "chr9", position: 123457752 },
    },
    {
      fdr: 2.297112473751918,
      trait: "anorexia",
      score: 0.060403824,
      TF_impact: "pos",
      snp: "rs17042057",
      coordinates: { chromosome: "chr2", position: 21268991 },
    },
    {
      fdr: 0.40927724477140565,
      trait: "bipolar-2019",
      score: -0.20233488,
      TF_impact: "pos",
      snp: "rs11784417",
      coordinates: { chromosome: "chr8", position: 143936584 },
    },
    {
      fdr: 3.710170547453216e-5,
      trait: "cannabis",
      score: -0.41973877,
      TF_impact: "pos",
      snp: "rs1344047",
      coordinates: { chromosome: "chr21", position: 37144721 },
    },
    {
      fdr: 8.880031630462196e-5,
      trait: "bipolar-II",
      score: 0.4013586,
      TF_impact: "pos",
      snp: "rs75292817",
      coordinates: { chromosome: "chr8", position: 33708848 },
    },
    {
      fdr: 0.8531081733785891,
      trait: "cannabis",
      score: 0.16639233,
      TF_impact: "pos",
      snp: "rs9566899",
      coordinates: { chromosome: "chr13", position: 41996192 },
    },
    {
      fdr: 1.619409507566095,
      trait: "autism",
      score: 0.124583244,
      TF_impact: "none",
      snp: "rs1792313",
      coordinates: { chromosome: "chr11", position: 71364562 },
    },
    {
      fdr: 2.2091878542736314,
      trait: "bipolar-2019",
      score: 0.08856249,
      TF_impact: "none",
      snp: "rs1931895",
      coordinates: { chromosome: "chr6", position: 116251808 },
    },
    {
      fdr: 1.022991348134319,
      trait: "MDD",
      score: 5.2928925e-5,
      TF_impact: "pos",
      snp: "rs12124090",
      coordinates: { chromosome: "chr1", position: 11478363 },
    },
    {
      fdr: 1.2570189907258993,
      trait: "ADHD-meta-filtered",
      score: -0.010332584,
      TF_impact: "pos",
      snp: "rs72773227",
      coordinates: { chromosome: "chr10", position: 16951201 },
    },
    {
      fdr: 0.5196844650689576,
      trait: "bipolar-2019",
      score: -0.19129515,
      TF_impact: "none",
      snp: "rs17028044",
      coordinates: { chromosome: "chr4", position: 151887741 },
    },
    {
      fdr: 2.173308831713071,
      trait: "bipolar-2019",
      score: -0.05033636,
      TF_impact: "none",
      snp: "rs616021",
      coordinates: { chromosome: "chr8", position: 9938864 },
    },
    {
      fdr: 0.004703703543563642,
      trait: "cannabis",
      score: 0.32558966,
      TF_impact: "none",
      snp: "rs7925990",
      coordinates: { chromosome: "chr11", position: 114027745 },
    },
    {
      fdr: 1.7282969914695603,
      trait: "ADHD-meta-filtered",
      score: -0.12100792,
      TF_impact: "pos",
      snp: "rs11741608",
      coordinates: { chromosome: "chr5", position: 104413091 },
    },
    {
      fdr: 5.79015680779196e-8,
      trait: "bipolar-I",
      score: 0.51345015,
      TF_impact: "neg",
      snp: "rs4714055",
      coordinates: { chromosome: "chr6", position: 37325988 },
    },
    {
      fdr: 2.2873125080545273,
      trait: "anxiety",
      score: -0.07778549,
      TF_impact: "pos",
      snp: "rs857166",
      coordinates: { chromosome: "chr16", position: 13839037 },
    },
    {
      fdr: 1.8452794413138675e-13,
      trait: "bipolar-2019",
      score: 0.66287136,
      TF_impact: "none",
      snp: "rs2413720",
      coordinates: { chromosome: "chr22", position: 42802253 },
    },
    {
      fdr: 1.4604488096827333,
      trait: "MDD",
      score: 0.015263557,
      TF_impact: "none",
      snp: "rs10815291",
      coordinates: { chromosome: "chr9", position: 5844824 },
    },
    {
      fdr: 0.002196008348937167,
      trait: "bipolar-2019",
      score: 0.34127474,
      TF_impact: "none",
      snp: "rs7825085",
      coordinates: { chromosome: "chr8", position: 27740783 },
    },
    {
      fdr: 2.1100613140989233,
      trait: "bipolar-I",
      score: -0.046380997,
      TF_impact: "pos",
      snp: "rs8129599",
      coordinates: { chromosome: "chr21", position: 38170889 },
    },
    {
      fdr: 1.533412004975614,
      trait: "ADHD-meta-filtered",
      score: 0.018031597,
      TF_impact: "none",
      snp: "rs12534157",
      coordinates: { chromosome: "chr7", position: 155670674 },
    },
    {
      fdr: 2.2974638985855984,
      trait: "anxiety",
      score: -0.062257767,
      TF_impact: "none",
      snp: "rs7101278",
      coordinates: { chromosome: "chr10", position: 95260855 },
    },
    {
      fdr: 2.037190533262014,
      trait: "MDD",
      score: -0.040561676,
      TF_impact: "none",
      snp: "rs138157115",
      coordinates: { chromosome: "chr12", position: 52041848 },
    },
    {
      fdr: 9.764593589305795e-8,
      trait: "bipolar-2019",
      score: 0.5066695,
      TF_impact: "none",
      snp: "rs7394459",
      coordinates: { chromosome: "chr11", position: 61869877 },
    },
    {
      fdr: 1.3684555266277714,
      trait: "ADHD-meta-filtered",
      score: -0.014153957,
      TF_impact: "none",
      snp: "rs7920108",
      coordinates: { chromosome: "chr10", position: 105017323 },
    },
    {
      fdr: 2.371624189855996e-5,
      trait: "bipolar-I",
      score: 0.42548943,
      TF_impact: "pos",
      snp: "rs11973169",
      coordinates: { chromosome: "chr7", position: 30005798 },
    },
    {
      fdr: 0.007013335160812865,
      trait: "ADHD-meta-filtered",
      score: 0.31700087,
      TF_impact: "none",
      snp: "rs2239339",
      coordinates: { chromosome: "chr16", position: 24218564 },
    },
    {
      fdr: 1.0869914715162052,
      trait: "pgc-panic-disorder",
      score: 0.0025672913,
      TF_impact: "none",
      snp: "rs2293271",
      coordinates: { chromosome: "chr2", position: 48560780 },
    },
    {
      fdr: 4.593518746904795e-5,
      trait: "anxiety",
      score: -0.41554546,
      TF_impact: "none",
      snp: "rs6791614",
      coordinates: { chromosome: "chr3", position: 102038604 },
    },
    {
      fdr: 0.0003855359553271034,
      trait: "autism",
      score: -0.37611294,
      TF_impact: "none",
      snp: "rs80587",
      coordinates: { chromosome: "chr22", position: 36202003 },
    },
    {
      fdr: 1.514303151143477e-9,
      trait: "autism",
      score: -0.56235695,
      TF_impact: "pos",
      snp: "rs2045199",
      coordinates: { chromosome: "chr4", position: 60934467 },
    },
    {
      fdr: 0.28651614678027326,
      trait: "bipolar-2012",
      score: -0.21463633,
      TF_impact: "pos",
      snp: "rs12380490",
      coordinates: { chromosome: "chr9", position: 110426250 },
    },
    {
      fdr: 0.184717334566054,
      trait: "anorexia",
      score: -0.22900295,
      TF_impact: "none",
      snp: "rs4881291",
      coordinates: { chromosome: "chr10", position: 4108859 },
    },
    {
      fdr: 1.5388444445142826,
      trait: "cannabis",
      score: -0.020078659,
      TF_impact: "pos",
      snp: "rs2597277",
      coordinates: { chromosome: "chr7", position: 37683810 },
    },
    {
      fdr: 4.3642353105930176e-5,
      trait: "scz",
      score: 0.41490126,
      TF_impact: "pos",
      snp: "rs198820",
      coordinates: { chromosome: "chr6", position: 26124015 },
    },
    {
      fdr: 0.004064055730937351,
      trait: "bipolar-I",
      score: 0.3287611,
      TF_impact: "none",
      snp: "rs1442104",
      coordinates: { chromosome: "chr4", position: 47210122 },
    },
    {
      fdr: 2.312186044488784,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.0644598,
      TF_impact: "none",
      snp: "rs7649466",
      coordinates: { chromosome: "chr3", position: 105869001 },
    },
    {
      fdr: 2.2625178732237456,
      trait: "anxiety",
      score: -0.0834198,
      TF_impact: "none",
      snp: "rs17628613",
      coordinates: { chromosome: "chr4", position: 170014603 },
    },
    {
      fdr: 0.004119415724679999,
      trait: "MDD",
      score: 0.3283882,
      TF_impact: "neg",
      snp: "rs12036585",
      coordinates: { chromosome: "chr1", position: 72178433 },
    },
    {
      fdr: 1.0852034892379872,
      trait: "ADHD-meta-filtered",
      score: 0.0025157928,
      TF_impact: "none",
      snp: "rs61867294",
      coordinates: { chromosome: "chr10", position: 104809449 },
    },
    {
      fdr: 3.5553233201486825e-5,
      trait: "MDD",
      score: 0.4186182,
      TF_impact: "pos",
      snp: "rs78757334",
      coordinates: { chromosome: "chr13", position: 26548505 },
    },
    {
      fdr: 0.0002201326075055898,
      trait: "anxiety",
      score: 0.38470697,
      TF_impact: "none",
      snp: "rs6706053",
      coordinates: { chromosome: "chr2", position: 44290523 },
    },
    {
      fdr: 4.3124172700381776e-5,
      trait: "bipolar-2019",
      score: -0.41710997,
      TF_impact: "pos",
      snp: "rs325400",
      coordinates: { chromosome: "chr15", position: 99712600 },
    },
    {
      fdr: 1.5006360907837712,
      trait: "MDD",
      score: -0.018593311,
      TF_impact: "none",
      snp: "rs10493806",
      coordinates: { chromosome: "chr1", position: 88228266 },
    },
    {
      fdr: 1.4737715800225866,
      trait: "bipolar-I",
      score: 0.01571703,
      TF_impact: "none",
      snp: "rs9311270",
      coordinates: { chromosome: "chr3", position: 41352679 },
    },
    {
      fdr: 0.07544369002459718,
      trait: "bipolar-2019",
      score: 0.25418377,
      TF_impact: "none",
      snp: "rs7010588",
      coordinates: { chromosome: "chr8", position: 143959608 },
    },
    {
      fdr: 0.001237992050535785,
      trait: "bipolar-II",
      score: 0.35233402,
      TF_impact: "neg",
      snp: "rs9896830",
      coordinates: { chromosome: "chr17", position: 51958032 },
    },
    {
      fdr: 1.5423085545826213e-12,
      trait: "bipolar-I",
      score: 0.6395416,
      TF_impact: "pos",
      snp: "rs4859265",
      coordinates: { chromosome: "chr3", position: 183033342 },
    },
    {
      fdr: 1.0140107082696466,
      trait: "bipolar-2019",
      score: -0.00025320053,
      TF_impact: "none",
      snp: "rs8087493",
      coordinates: { chromosome: "chr18", position: 43159234 },
    },
    {
      fdr: 2.2626886327833735,
      trait: "bipolar-2019",
      score: -0.05834961,
      TF_impact: "none",
      snp: "rs1078773",
      coordinates: { chromosome: "chr10", position: 12514034 },
    },
    {
      fdr: 0.00010858363916943072,
      trait: "anorexia",
      score: -0.39967442,
      TF_impact: "pos",
      snp: "rs12802128",
      coordinates: { chromosome: "chr11", position: 74560127 },
    },
    {
      fdr: 1.6022522149030395e-8,
      trait: "anorexia",
      score: -0.53192043,
      TF_impact: "none",
      snp: "rs7761589",
      coordinates: { chromosome: "chr6", position: 30890470 },
    },
    {
      fdr: 1.3951544128169975,
      trait: "MDD",
      score: 0.0132107735,
      TF_impact: "pos",
      snp: "rs940310",
      coordinates: { chromosome: "chr18", position: 54388911 },
    },
    {
      fdr: 1.4855729052262914,
      trait: "bipolar-I",
      score: 0.13302517,
      TF_impact: "pos",
      snp: "rs62105751",
      coordinates: { chromosome: "chr19", position: 9847213 },
    },
    {
      fdr: 1.5575036597974223,
      trait: "MDD",
      score: 0.018927097,
      TF_impact: "pos",
      snp: "rs61901226",
      coordinates: { chromosome: "chr11", position: 112671366 },
    },
    {
      fdr: 2.084033601291389,
      trait: "opioid-unexposed-vs-exposed",
      score: 0.041543484,
      TF_impact: "pos",
      snp: "rs12602556",
      coordinates: { chromosome: "chr17", position: 67830745 },
    },
    {
      fdr: 1.5860590815535454,
      trait: "bipolar-I",
      score: -0.021732807,
      TF_impact: "none",
      snp: "rs11242436",
      coordinates: { chromosome: "chr5", position: 138601629 },
    },
    {
      fdr: 0.4242694157325299,
      trait: "MDD",
      score: -0.20049429,
      TF_impact: "neg",
      snp: "rs357524",
      coordinates: { chromosome: "chr9", position: 95426883 },
    },
    {
      fdr: 2.3165978389058677,
      trait: "bipolar-2012",
      score: 0.0631237,
      TF_impact: "none",
      snp: "rs6497007",
      coordinates: { chromosome: "chr15", position: 85333786 },
    },
    {
      fdr: 1.7858108488233633,
      trait: "anxiety",
      score: 0.11652136,
      TF_impact: "pos",
      snp: "rs8008675",
      coordinates: { chromosome: "chr14", position: 94868081 },
    },
    {
      fdr: 2.23889851454607,
      trait: "pgc-panic-disorder",
      score: 0.05426216,
      TF_impact: "pos",
      snp: "rs2857106",
      coordinates: { chromosome: "chr6", position: 32819793 },
    },
    {
      fdr: 1.839225792670185e-8,
      trait: "bipolar-I",
      score: -0.5298581,
      TF_impact: "pos",
      snp: "rs9890374",
      coordinates: { chromosome: "chr17", position: 28265336 },
    },
    {
      fdr: 0.5461691385808713,
      trait: "anorexia",
      score: 0.1866579,
      TF_impact: "none",
      snp: "rs56386725",
      coordinates: { chromosome: "chr14", position: 64417633 },
    },
    {
      fdr: 1.014730672404205,
      trait: "autism",
      score: -0.00023078918,
      TF_impact: "pos",
      snp: "rs12550057",
      coordinates: { chromosome: "chr8", position: 8704758 },
    },
    {
      fdr: 1.106330558229512,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.0032720566,
      TF_impact: "none",
      snp: "rs17770142",
      coordinates: { chromosome: "chr16", position: 83717918 },
    },
    {
      fdr: 7.520581734812983e-5,
      trait: "MDD",
      score: 0.4048829,
      TF_impact: "none",
      snp: "rs10868001",
      coordinates: { chromosome: "chr9", position: 83366668 },
    },
    {
      fdr: 2.3025495062882265e-9,
      trait: "anxiety",
      score: -0.55646086,
      TF_impact: "none",
      snp: "rs72802493",
      coordinates: { chromosome: "chr2", position: 46777070 },
    },
    {
      fdr: 1.2056955191759393e-9,
      trait: "bipolar-I",
      score: -0.5661454,
      TF_impact: "none",
      snp: "rs1080500",
      coordinates: { chromosome: "chr3", position: 53141001 },
    },
    {
      fdr: 1.8585097341425032,
      trait: "cannabis",
      score: 0.030723095,
      TF_impact: "none",
      snp: "rs754333",
      coordinates: { chromosome: "chr9", position: 108924616 },
    },
    {
      fdr: 2.2277579173694635,
      trait: "bipolar-I",
      score: -0.055470467,
      TF_impact: "none",
      snp: "rs16865132",
      coordinates: { chromosome: "chr2", position: 6410574 },
    },
    {
      fdr: 2.2277678146271582,
      trait: "bipolar-2019",
      score: 0.08730316,
      TF_impact: "none",
      snp: "rs72769966",
      coordinates: { chromosome: "chr16", position: 9123915 },
    },
    {
      fdr: 1.834745457099586,
      trait: "ADHD-meta-filtered",
      score: 0.11246538,
      TF_impact: "neg",
      snp: "rs4635202",
      coordinates: { chromosome: "chr13", position: 31044522 },
    },
    {
      fdr: 1.7580161027119858,
      trait: "anorexia",
      score: 0.026550293,
      TF_impact: "none",
      snp: "rs249276",
      coordinates: { chromosome: "chr16", position: 9422827 },
    },
    {
      fdr: 1.7794748711664872,
      trait: "MDD",
      score: 0.027105331,
      TF_impact: "none",
      snp: "rs28695930",
      coordinates: { chromosome: "chr8", position: 9606228 },
    },
    {
      fdr: 1.3618387847460098,
      trait: "MDD",
      score: -0.013927937,
      TF_impact: "none",
      snp: "rs12923469",
      coordinates: { chromosome: "chr16", position: 79128723 },
    },
    {
      fdr: 1.6072907673907177,
      trait: "bipolar-I",
      score: 0.12550735,
      TF_impact: "none",
      snp: "rs9559493",
      coordinates: { chromosome: "chr13", position: 109088749 },
    },
    {
      fdr: 1.4291142694948675,
      trait: "bipolar-2019",
      score: -0.016134739,
      TF_impact: "none",
      snp: "rs17774047",
      coordinates: { chromosome: "chr15", position: 42721028 },
    },
    {
      fdr: 4.169695302965282e-6,
      trait: "autism",
      score: 0.45487118,
      TF_impact: "pos",
      snp: "rs73403546",
      coordinates: { chromosome: "chr15", position: 42150625 },
    },
    {
      fdr: 0.009097653479159988,
      trait: "scz",
      score: 0.3105631,
      TF_impact: "none",
      snp: "rs12577525",
      coordinates: { chromosome: "chr11", position: 17238569 },
    },
    {
      fdr: 1.0935416076880774e-12,
      trait: "bipolar-2019",
      score: 0.6434727,
      TF_impact: "none",
      snp: "rs1434984",
      coordinates: { chromosome: "chr2", position: 14641313 },
    },
    {
      fdr: 1.769278565727829,
      trait: "anorexia",
      score: -0.028778553,
      TF_impact: "pos",
      snp: "rs35808061",
      coordinates: { chromosome: "chr11", position: 57779528 },
    },
    {
      fdr: 1.813435313714763,
      trait: "ADHD-meta-filtered",
      score: 0.028511524,
      TF_impact: "pos",
      snp: "rs8040226",
      coordinates: { chromosome: "chr15", position: 47384633 },
    },
    {
      fdr: 2.1446498764608455,
      trait: "anxiety",
      score: -0.09663582,
      TF_impact: "none",
      snp: "rs6575469",
      coordinates: { chromosome: "chr14", position: 94868350 },
    },
    {
      fdr: 1.6251145489480563,
      trait: "MDD",
      score: 0.124094486,
      TF_impact: "none",
      snp: "rs7701817",
      coordinates: { chromosome: "chr5", position: 124650872 },
    },
    {
      fdr: 0.16339949041250532,
      trait: "MDD",
      score: 0.23174,
      TF_impact: "none",
      snp: "rs74831413",
      coordinates: { chromosome: "chr2", position: 237512878 },
    },
    {
      fdr: 0.6537341504471973,
      trait: "bipolar-II",
      score: -0.18034887,
      TF_impact: "pos",
      snp: "rs16891334",
      coordinates: { chromosome: "chr6", position: 26124075 },
    },
    {
      fdr: 0.008748110886050523,
      trait: "bipolar-2012",
      score: -0.31324363,
      TF_impact: "none",
      snp: "rs11681427",
      coordinates: { chromosome: "chr2", position: 240766810 },
    },
    {
      fdr: 0.00015169654959676764,
      trait: "bipolar-I",
      score: -0.393919,
      TF_impact: "pos",
      snp: "rs77457752",
      coordinates: { chromosome: "chr9", position: 13942941 },
    },
    {
      fdr: 0.00523715823145579,
      trait: "cannabis",
      score: -0.32528257,
      TF_impact: "pos",
      snp: "rs10520034",
      coordinates: { chromosome: "chr5", position: 129410822 },
    },
    {
      fdr: 2.0054988756691814,
      trait: "MDD",
      score: 0.10437536,
      TF_impact: "pos",
      snp: "rs2232015",
      coordinates: { chromosome: "chr1", position: 107056636 },
    },
    {
      fdr: 0.0007383714430281695,
      trait: "bipolar-II",
      score: -0.3640051,
      TF_impact: "none",
      snp: "rs55762233",
      coordinates: { chromosome: "chr19", position: 19256510 },
    },
    {
      fdr: 4.792324176518177e-6,
      trait: "MDD",
      score: -0.45419312,
      TF_impact: "none",
      snp: "rs1618400",
      coordinates: { chromosome: "chr11", position: 115707780 },
    },
    {
      fdr: 1.054374260382784,
      trait: "bipolar-I",
      score: -0.0032277107,
      TF_impact: "none",
      snp: "rs1452387",
      coordinates: { chromosome: "chr15", position: 73856484 },
    },
    {
      fdr: 2.2449025557237223,
      trait: "autism",
      score: 0.055205345,
      TF_impact: "pos",
      snp: "rs7014953",
      coordinates: { chromosome: "chr8", position: 8310891 },
    },
    {
      fdr: 1.1954738873595999e-5,
      trait: "ADHD-meta-filtered",
      score: -0.4387231,
      TF_impact: "none",
      snp: "rs11165651",
      coordinates: { chromosome: "chr1", position: 96472692 },
    },
    {
      fdr: 1.955651615521663,
      trait: "bipolar-2019",
      score: -0.036204815,
      TF_impact: "none",
      snp: "rs10173260",
      coordinates: { chromosome: "chr2", position: 209513121 },
    },
    {
      fdr: 0.007187501113832956,
      trait: "anorexia",
      score: 0.31579208,
      TF_impact: "none",
      snp: "rs318198",
      coordinates: { chromosome: "chr16", position: 64207177 },
    },
    {
      fdr: 0.00347793408890201,
      trait: "anxiety",
      score: -0.33388472,
      TF_impact: "none",
      snp: "rs3774117",
      coordinates: { chromosome: "chr3", position: 10857556 },
    },
    {
      fdr: 6.803988799003578e-5,
      trait: "autism",
      score: -0.40868998,
      TF_impact: "none",
      snp: "rs2921074",
      coordinates: { chromosome: "chr8", position: 8503629 },
    },
    {
      fdr: 0.0011615714993781414,
      trait: "anorexia",
      score: 0.3535185,
      TF_impact: "none",
      snp: "rs2405104",
      coordinates: { chromosome: "chr1", position: 208527388 },
    },
    {
      fdr: 2.237621434448744,
      trait: "bipolar-2019",
      score: -0.056331635,
      TF_impact: "pos",
      snp: "rs28728306",
      coordinates: { chromosome: "chr7", position: 1922178 },
    },
    {
      fdr: 0.8557235833770398,
      trait: "ADHD-meta-filtered",
      score: -0.1680913,
      TF_impact: "pos",
      snp: "rs6811025",
      coordinates: { chromosome: "chr4", position: 34159044 },
    },
    {
      fdr: 1.5734007939385046,
      trait: "anxiety",
      score: -0.02116108,
      TF_impact: "none",
      snp: "rs11125089",
      coordinates: { chromosome: "chr2", position: 46781124 },
    },
    {
      fdr: 1.7694936689570298,
      trait: "bipolar-2019",
      score: -0.028782368,
      TF_impact: "pos",
      snp: "rs67263685",
      coordinates: { chromosome: "chr16", position: 9135088 },
    },
    {
      fdr: 2.2546085695463924,
      trait: "bipolar-I",
      score: -0.08616209,
      TF_impact: "none",
      snp: "rs73199533",
      coordinates: { chromosome: "chr12", position: 108175466 },
    },
    {
      fdr: 1.5380179888966428,
      trait: "anorexia",
      score: 0.0181818,
      TF_impact: "none",
      snp: "rs1524682",
      coordinates: { chromosome: "chr4", position: 57379036 },
    },
    {
      fdr: 0.008203023739520775,
      trait: "bipolar-2019",
      score: -0.3146739,
      TF_impact: "none",
      snp: "rs34849561",
      coordinates: { chromosome: "chr13", position: 41716647 },
    },
    {
      fdr: 0.000149807122936891,
      trait: "ADHD",
      score: -0.39419317,
      TF_impact: "none",
      snp: "rs2916197",
      coordinates: { chromosome: "chr1", position: 153355859 },
    },
    {
      fdr: 3.1472833487224605e-5,
      trait: "anorexia",
      score: -0.4226637,
      TF_impact: "none",
      snp: "rs7920451",
      coordinates: { chromosome: "chr10", position: 124907807 },
    },
    {
      fdr: 0.007000739771006797,
      trait: "bipolar-I",
      score: -0.318717,
      TF_impact: "pos",
      snp: "rs11134593",
      coordinates: { chromosome: "chr5", position: 169862631 },
    },
    {
      fdr: 0.037328068889092854,
      trait: "anorexia",
      score: -0.27634335,
      TF_impact: "pos",
      snp: "rs3101018",
      coordinates: { chromosome: "chr6", position: 31738087 },
    },
    {
      fdr: 2.0878925220136324,
      trait: "autism",
      score: -0.04365921,
      TF_impact: "pos",
      snp: "rs132734",
      coordinates: { chromosome: "chr22", position: 36201831 },
    },
    {
      fdr: 2.297157140331965,
      trait: "bipolar-I",
      score: -0.06218958,
      TF_impact: "pos",
      snp: "rs6576976",
      coordinates: { chromosome: "chr2", position: 96580930 },
    },
    {
      fdr: 0.0002278764666722158,
      trait: "scz",
      score: 0.3837347,
      TF_impact: "none",
      snp: "rs500571",
      coordinates: { chromosome: "chr11", position: 83594499 },
    },
    {
      fdr: 0.029898612001962463,
      trait: "bipolar-2019",
      score: 0.2801442,
      TF_impact: "pos",
      snp: "rs596136",
      coordinates: { chromosome: "chr18", position: 11999557 },
    },
    {
      fdr: 2.1015445360357634,
      trait: "pgc-panic-disorder",
      score: -0.044998646,
      TF_impact: "none",
      snp: "rs8042870",
      coordinates: { chromosome: "chr15", position: 96849640 },
    },
    {
      fdr: 1.4893689844027156,
      trait: "autism",
      score: 0.01641941,
      TF_impact: "none",
      snp: "rs13264212",
      coordinates: { chromosome: "chr8", position: 11452340 },
    },
    {
      fdr: 1.8881129098909875,
      trait: "bipolar-I",
      score: -0.1125226,
      TF_impact: "pos",
      snp: "rs62239507",
      coordinates: { chromosome: "chr22", position: 50539788 },
    },
    {
      fdr: 2.288680069918884,
      trait: "ADHD-meta-filtered",
      score: 0.07672334,
      TF_impact: "none",
      snp: "rs6771703",
      coordinates: { chromosome: "chr3", position: 126082224 },
    },
    {
      fdr: 0.0003178784625258325,
      trait: "bipolar-2019",
      score: -0.37938643,
      TF_impact: "neg",
      snp: "rs4845677",
      coordinates: { chromosome: "chr1", position: 154865574 },
    },
    {
      fdr: 1.1693350575878954,
      trait: "autism",
      score: -0.007319927,
      TF_impact: "none",
      snp: "rs62054435",
      coordinates: { chromosome: "chr17", position: 45747973 },
    },
    {
      fdr: 0.5043412497204486,
      trait: "autism",
      score: 0.19117785,
      TF_impact: "none",
      snp: "rs7644373",
      coordinates: { chromosome: "chr3", position: 153997561 },
    },
    {
      fdr: 1.8053126894545255,
      trait: "bipolar-II",
      score: 0.11525869,
      TF_impact: "pos",
      snp: "rs2766557",
      coordinates: { chromosome: "chr6", position: 35757655 },
    },
    {
      fdr: 3.99667119648105e-5,
      trait: "bipolar-2019",
      score: 0.41658545,
      TF_impact: "none",
      snp: "rs74779340",
      coordinates: { chromosome: "chr7", position: 24781326 },
    },
    {
      fdr: 0.4632744441497868,
      trait: "bipolar-II",
      score: 0.19488525,
      TF_impact: "none",
      snp: "rs1469712",
      coordinates: { chromosome: "chr19", position: 19418012 },
    },
    {
      fdr: 1.1493059622224233,
      trait: "anorexia",
      score: 0.004793644,
      TF_impact: "none",
      snp: "rs11722498",
      coordinates: { chromosome: "chr4", position: 57234479 },
    },
    {
      fdr: 0.005453001803525773,
      trait: "ADHD-meta-filtered",
      score: 0.3225422,
      TF_impact: "pos",
      snp: "rs7810323",
      coordinates: { chromosome: "chr7", position: 1804158 },
    },
    {
      fdr: 1.1790449922992547,
      trait: "pgc-panic-disorder",
      score: -0.0076065063,
      TF_impact: "none",
      snp: "rs6979076",
      coordinates: { chromosome: "chr7", position: 1076611 },
    },
    {
      fdr: 1.2464764153528007,
      trait: "anorexia",
      score: 0.14497662,
      TF_impact: "none",
      snp: "rs2532935",
      coordinates: { chromosome: "chr6", position: 30926796 },
    },
    {
      fdr: 1.3457702286807636e-10,
      trait: "autism",
      score: -0.5938983,
      TF_impact: "pos",
      snp: "rs62063295",
      coordinates: { chromosome: "chr17", position: 45967065 },
    },
    {
      fdr: 2.2811840205515552,
      trait: "pgc-panic-disorder",
      score: -0.08198929,
      TF_impact: "none",
      snp: "rs7203458",
      coordinates: { chromosome: "chr16", position: 73192477 },
    },
    {
      fdr: 2.1993550664258445e-5,
      trait: "bipolar-2019",
      score: -0.42902946,
      TF_impact: "none",
      snp: "rs13000014",
      coordinates: { chromosome: "chr2", position: 98766867 },
    },
    {
      fdr: 1.0636285820667952,
      trait: "anxiety",
      score: -0.0035924911,
      TF_impact: "none",
      snp: "rs6896953",
      coordinates: { chromosome: "chr5", position: 83089284 },
    },
    {
      fdr: 2.316465225315171,
      trait: "autism",
      score: 0.06355667,
      TF_impact: "none",
      snp: "rs6431489",
      coordinates: { chromosome: "chr2", position: 236902166 },
    },
    {
      fdr: 0.0001062015098411535,
      trait: "bipolar-I",
      score: 0.39824677,
      TF_impact: "pos",
      snp: "rs6805895",
      coordinates: { chromosome: "chr3", position: 156010985 },
    },
    {
      fdr: 0.28784267875450387,
      trait: "scz",
      score: -0.21444273,
      TF_impact: "pos",
      snp: "rs9661794",
      coordinates: { chromosome: "chr1", position: 97870773 },
    },
    {
      fdr: 0.00025786960696382375,
      trait: "anorexia",
      score: -0.38303185,
      TF_impact: "neg",
      snp: "rs12320284",
      coordinates: { chromosome: "chr12", position: 49429144 },
    },
    {
      fdr: 0.0007007032044029196,
      trait: "anorexia",
      score: 0.3634367,
      TF_impact: "pos",
      snp: "rs10445919",
      coordinates: { chromosome: "chr2", position: 23012697 },
    },
    {
      fdr: 1.0490400938675164,
      trait: "bipolar-2019",
      score: 0.0012454987,
      TF_impact: "pos",
      snp: "rs1953723",
      coordinates: { chromosome: "chr14", position: 29761297 },
    },
    {
      fdr: 1.3145230957242438,
      trait: "anorexia",
      score: -0.012208462,
      TF_impact: "pos",
      snp: "rs7866620",
      coordinates: { chromosome: "chr9", position: 124802650 },
    },
    {
      fdr: 1.0560903883404092e-5,
      trait: "MDD",
      score: -0.4412284,
      TF_impact: "none",
      snp: "rs280299",
      coordinates: { chromosome: "chr6", position: 50656132 },
    },
    {
      fdr: 1.8562484028514536,
      trait: "ADHD-meta-filtered",
      score: 0.03055191,
      TF_impact: "pos",
      snp: "rs28579454",
      coordinates: { chromosome: "chr15", position: 80351159 },
    },
    {
      fdr: 5.650347026667967e-9,
      trait: "ADHD-meta-filtered",
      score: 0.5437231,
      TF_impact: "none",
      snp: "rs1446335",
      coordinates: { chromosome: "chr15", position: 80412033 },
    },
    {
      fdr: 1.9032167903515507,
      trait: "MDD",
      score: -0.034401894,
      TF_impact: "none",
      snp: "rs11207138",
      coordinates: { chromosome: "chr1", position: 39926485 },
    },
    {
      fdr: 1.1511358741836362,
      trait: "bipolar-I",
      score: -0.006752014,
      TF_impact: "none",
      snp: "rs1459540",
      coordinates: { chromosome: "chr4", position: 117773188 },
    },
    {
      fdr: 2.3157287252475527,
      trait: "bipolar-2012",
      score: 0.06634331,
      TF_impact: "none",
      snp: "rs9376004",
      coordinates: { chromosome: "chr6", position: 134035907 },
    },
    {
      fdr: 1.7412632097078315,
      trait: "bipolar-2019",
      score: -0.027657986,
      TF_impact: "none",
      snp: "rs12191379",
      coordinates: { chromosome: "chr6", position: 124683704 },
    },
    {
      fdr: 1.0868401574127629,
      trait: "bipolar-2019",
      score: 0.0025615692,
      TF_impact: "pos",
      snp: "rs2667762",
      coordinates: { chromosome: "chr15", position: 77590075 },
    },
    {
      fdr: 0.15563868078760715,
      trait: "MDD",
      score: 0.2331605,
      TF_impact: "none",
      snp: "rs2678273",
      coordinates: { chromosome: "chr2", position: 215619859 },
    },
    {
      fdr: 1.3978068077948598,
      trait: "bipolar-I",
      score: -0.015182495,
      TF_impact: "none",
      snp: "rs6496774",
      coordinates: { chromosome: "chr15", position: 91227417 },
    },
    {
      fdr: 0.0058974975286946955,
      trait: "autism",
      score: -0.32260227,
      TF_impact: "pos",
      snp: "rs12519002",
      coordinates: { chromosome: "chr5", position: 57889847 },
    },
    {
      fdr: 2.009431647130111,
      trait: "autism",
      score: -0.038569927,
      TF_impact: "pos",
      snp: "rs72828517",
      coordinates: { chromosome: "chr6", position: 19035804 },
    },
    {
      fdr: 1.6313066164051955,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.12554312,
      TF_impact: "pos",
      snp: "rs11097575",
      coordinates: { chromosome: "chr4", position: 97721267 },
    },
    {
      fdr: 0.8862643555633772,
      trait: "autism",
      score: 0.16481256,
      TF_impact: "pos",
      snp: "rs7603584",
      coordinates: { chromosome: "chr2", position: 201440422 },
    },
    {
      fdr: 0.009249984019740438,
      trait: "bipolar-I",
      score: -0.31192398,
      TF_impact: "none",
      snp: "rs6740035",
      coordinates: { chromosome: "chr2", position: 98960115 },
    },
    {
      fdr: 1.4621812395071818,
      trait: "bipolar-I",
      score: 0.01533556,
      TF_impact: "none",
      snp: "rs596179",
      coordinates: { chromosome: "chr3", position: 182855345 },
    },
    {
      fdr: 1.0534583042871768,
      trait: "autism",
      score: -0.0032162666,
      TF_impact: "pos",
      snp: "rs2721811",
      coordinates: { chromosome: "chr7", position: 24709810 },
    },
    {
      fdr: 1.3057855037081332,
      trait: "autism",
      score: -0.011940479,
      TF_impact: "none",
      snp: "rs1908814",
      coordinates: { chromosome: "chr8", position: 11897546 },
    },
    {
      fdr: 1.16846739156032,
      trait: "bipolar-I",
      score: 0.0054256916,
      TF_impact: "none",
      snp: "rs11136345",
      coordinates: { chromosome: "chr8", position: 143985944 },
    },
    {
      fdr: 1.8890603026331552,
      trait: "bipolar-2019",
      score: -0.033795834,
      TF_impact: "none",
      snp: "rs1402030",
      coordinates: { chromosome: "chr17", position: 40068768 },
    },
    {
      fdr: 0.00021830804661163595,
      trait: "anxiety",
      score: -0.3863535,
      TF_impact: "pos",
      snp: "rs4771314",
      coordinates: { chromosome: "chr13", position: 98664201 },
    },
    {
      fdr: 0.0010509604856648014,
      trait: "anxiety",
      score: 0.35539865,
      TF_impact: "none",
      snp: "rs3927078",
      coordinates: { chromosome: "chr2", position: 46670534 },
    },
    {
      fdr: 0.8165458600913457,
      trait: "anxiety",
      score: -0.17021084,
      TF_impact: "none",
      snp: "rs17662779",
      coordinates: { chromosome: "chr17", position: 34567128 },
    },
    {
      fdr: 0.005648208183341587,
      trait: "anxiety",
      score: 0.32175255,
      TF_impact: "none",
      snp: "rs35840820",
      coordinates: { chromosome: "chr4", position: 81527372 },
    },
    {
      fdr: 2.1720318373927388,
      trait: "autism",
      score: 0.04842043,
      TF_impact: "none",
      snp: "rs2976882",
      coordinates: { chromosome: "chr8", position: 8467350 },
    },
    {
      fdr: 1.695870066431286,
      trait: "MDD",
      score: -0.026014805,
      TF_impact: "none",
      snp: "rs149280103",
      coordinates: { chromosome: "chr18", position: 51154196 },
    },
    {
      fdr: 0.966258331169078,
      trait: "MDD",
      score: -0.1610508,
      TF_impact: "pos",
      snp: "rs73738225",
      coordinates: { chromosome: "chr6", position: 27213931 },
    },
    {
      fdr: 1.8056058503838446,
      trait: "bipolar-I",
      score: 0.028111935,
      TF_impact: "pos",
      snp: "rs1480162",
      coordinates: { chromosome: "chr5", position: 147092245 },
    },
    {
      fdr: 1.3466587854892216,
      trait: "anorexia",
      score: 0.011564255,
      TF_impact: "none",
      snp: "rs3781445",
      coordinates: { chromosome: "chr10", position: 124999801 },
    },
    {
      fdr: 1.9582711490930873,
      trait: "ADHD-meta-filtered",
      score: -0.036251545,
      TF_impact: "none",
      snp: "rs7922641",
      coordinates: { chromosome: "chr10", position: 113187401 },
    },
    {
      fdr: 2.242950800397779,
      trait: "anorexia",
      score: 0.08587217,
      TF_impact: "none",
      snp: "rs718947",
      coordinates: { chromosome: "chr10", position: 125050580 },
    },
    {
      fdr: 2.3157210253212115e-9,
      trait: "MDD",
      score: -0.5566983,
      TF_impact: "none",
      snp: "rs11700794",
      coordinates: { chromosome: "chr21", position: 17659083 },
    },
    {
      fdr: 9.61877615187447e-6,
      trait: "bipolar-2019",
      score: 0.44135904,
      TF_impact: "none",
      snp: "rs2721921",
      coordinates: { chromosome: "chr8", position: 132499596 },
    },
    {
      fdr: 1.6655530890422887,
      trait: "bipolar-2019",
      score: -0.024781227,
      TF_impact: "neg",
      snp: "rs34044266",
      coordinates: { chromosome: "chr17", position: 75028091 },
    },
    {
      fdr: 1.7000686861055982e-26,
      trait: "cannabis",
      score: 0.9339309,
      TF_impact: "none",
      snp: "rs11188284",
      coordinates: { chromosome: "chr10", position: 95337497 },
    },
    {
      fdr: 0.0026330544340183466,
      trait: "bipolar-2019",
      score: -0.33969307,
      TF_impact: "none",
      snp: "rs76664163",
      coordinates: { chromosome: "chr19", position: 46413718 },
    },
    {
      fdr: 0.00019325731085133706,
      trait: "MDD",
      score: -0.38887978,
      TF_impact: "neg",
      snp: "rs1107070",
      coordinates: { chromosome: "chr14", position: 103504171 },
    },
    {
      fdr: 7.301315024327771e-9,
      trait: "MDD",
      score: 0.5402703,
      TF_impact: "pos",
      snp: "rs61943400",
      coordinates: { chromosome: "chr12", position: 118398236 },
    },
    {
      fdr: 1.838208716963363e-5,
      trait: "MDD",
      score: -0.431962,
      TF_impact: "pos",
      snp: "rs9958401",
      coordinates: { chromosome: "chr18", position: 61891982 },
    },
    {
      fdr: 1.0674831734007425e-5,
      trait: "bipolar-II",
      score: -0.44139242,
      TF_impact: "none",
      snp: "rs72689945",
      coordinates: { chromosome: "chr8", position: 143146930 },
    },
    {
      fdr: 6.157280003394636e-5,
      trait: "autism",
      score: 0.40870714,
      TF_impact: "none",
      snp: "rs7810588",
      coordinates: { chromosome: "chr7", position: 30182049 },
    },
    {
      fdr: 6.424202699003485e-5,
      trait: "cannabis",
      score: 0.40780592,
      TF_impact: "none",
      snp: "rs77068358",
      coordinates: { chromosome: "chr17", position: 80796816 },
    },
    {
      fdr: 2.691940236993023e-5,
      trait: "MDD",
      score: 0.42342138,
      TF_impact: "none",
      snp: "rs2250002",
      coordinates: { chromosome: "chr10", position: 71345340 },
    },
    {
      fdr: 1.492714338668744e-16,
      trait: "MDD",
      score: 0.7351179,
      TF_impact: "pos",
      snp: "rs1035223",
      coordinates: { chromosome: "chr18", position: 54762511 },
    },
    {
      fdr: 0.03758481607074998,
      trait: "MDD",
      score: 0.2742076,
      TF_impact: "none",
      snp: "rs4608489",
      coordinates: { chromosome: "chr2", position: 217063538 },
    },
    {
      fdr: 1.4315718156240302,
      trait: "ADHD-meta-filtered",
      score: -0.016211987,
      TF_impact: "pos",
      snp: "rs1961979",
      coordinates: { chromosome: "chr4", position: 89001286 },
    },
    {
      fdr: 1.1698200449400553,
      trait: "cannabis",
      score: -0.007334709,
      TF_impact: "pos",
      snp: "rs36195228",
      coordinates: { chromosome: "chr8", position: 85056113 },
    },
    {
      fdr: 1.0726148985439128,
      trait: "anxiety",
      score: 0.0020976067,
      TF_impact: "none",
      snp: "rs4757754",
      coordinates: { chromosome: "chr11", position: 19110654 },
    },
    {
      fdr: 4.4616754444690485e-5,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.4161589,
      TF_impact: "none",
      snp: "rs639218",
      coordinates: { chromosome: "chr17", position: 77273809 },
    },
    {
      fdr: 2.2868380473380414,
      trait: "ADHD-meta-filtered",
      score: 0.07679272,
      TF_impact: "pos",
      snp: "rs7549876",
      coordinates: { chromosome: "chr1", position: 43294565 },
    },
    {
      fdr: 5.001563245206011e-5,
      trait: "cannabis",
      score: -0.41391182,
      TF_impact: "none",
      snp: "rs1922785",
      coordinates: { chromosome: "chr2", position: 58702543 },
    },
    {
      fdr: 1.713389677806493,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.024951458,
      TF_impact: "none",
      snp: "rs13242167",
      coordinates: { chromosome: "chr7", position: 37037296 },
    },
    {
      fdr: 5.1381241173570605e-14,
      trait: "MDD",
      score: -0.67792034,
      TF_impact: "none",
      snp: "rs62038334",
      coordinates: { chromosome: "chr16", position: 12168207 },
    },
    {
      fdr: 1.9395717840733828,
      trait: "MDD",
      score: 0.033965588,
      TF_impact: "none",
      snp: "rs6499040",
      coordinates: { chromosome: "chr16", position: 65787054 },
    },
    {
      fdr: 0.00703804225465649,
      trait: "autism",
      score: -0.31833172,
      TF_impact: "neg",
      snp: "rs2349075",
      coordinates: { chromosome: "chr2", position: 201358843 },
    },
    {
      fdr: 1.1182054825369896,
      trait: "bipolar-I",
      score: -0.005590439,
      TF_impact: "pos",
      snp: "rs1427281",
      coordinates: { chromosome: "chr15", position: 58667074 },
    },
    {
      fdr: 2.084420203997476,
      trait: "MDD",
      score: 0.09848094,
      TF_impact: "pos",
      snp: "rs2403202",
      coordinates: { chromosome: "chr14", position: 103613705 },
    },
    {
      fdr: 1.8127166615949817,
      trait: "cannabis",
      score: 0.11481285,
      TF_impact: "none",
      snp: "rs7793710",
      coordinates: { chromosome: "chr7", position: 73557201 },
    },
    {
      fdr: 1.0100722936637854e-5,
      trait: "MDD",
      score: -0.4424019,
      TF_impact: "none",
      snp: "rs9998816",
      coordinates: { chromosome: "chr4", position: 158914546 },
    },
    {
      fdr: 1.3172838039737707,
      trait: "MDD",
      score: -0.012424469,
      TF_impact: "none",
      snp: "rs7776539",
      coordinates: { chromosome: "chr7", position: 3290148 },
    },
    {
      fdr: 1.0189944957418016,
      trait: "autism",
      score: -0.0017824173,
      TF_impact: "pos",
      snp: "rs62064665",
      coordinates: { chromosome: "chr17", position: 46004161 },
    },
    {
      fdr: 1.2685429858138975,
      trait: "cannabis",
      score: 0.008972406,
      TF_impact: "pos",
      snp: "rs28453960",
      coordinates: { chromosome: "chr22", position: 49933522 },
    },
    {
      fdr: 0.00014687328993052151,
      trait: "bipolar-2019",
      score: -0.3946576,
      TF_impact: "none",
      snp: "rs3024747",
      coordinates: { chromosome: "chr13", position: 113167473 },
    },
    {
      fdr: 0.00019162063609361936,
      trait: "MDD",
      score: -0.38915682,
      TF_impact: "neg",
      snp: "rs12526735",
      coordinates: { chromosome: "chr6", position: 72939099 },
    },
    {
      fdr: 8.425609614319316e-6,
      trait: "bipolar-2019",
      score: 0.44347048,
      TF_impact: "pos",
      snp: "rs13428225",
      coordinates: { chromosome: "chr2", position: 185351793 },
    },
    {
      fdr: 1.9359802879901924,
      trait: "autism",
      score: 0.10802555,
      TF_impact: "none",
      snp: "rs3745300",
      coordinates: { chromosome: "chr19", position: 49155618 },
    },
    {
      fdr: 2.046207272507332,
      trait: "scz",
      score: -0.041166782,
      TF_impact: "none",
      snp: "rs6482674",
      coordinates: { chromosome: "chr10", position: 128390728 },
    },
    {
      fdr: 2.025891468990504,
      trait: "bipolar-2019",
      score: 0.038219452,
      TF_impact: "neg",
      snp: "rs1013278",
      coordinates: { chromosome: "chr7", position: 117963766 },
    },
    {
      fdr: 0.001300313201265741,
      trait: "bipolar-I",
      score: -0.35301018,
      TF_impact: "none",
      snp: "rs9876378",
      coordinates: { chromosome: "chr3", position: 85022127 },
    },
    {
      fdr: 1.8753356803194055,
      trait: "autism",
      score: -0.033205986,
      TF_impact: "pos",
      snp: "rs12992419",
      coordinates: { chromosome: "chr2", position: 141630618 },
    },
    {
      fdr: 0.0002574192031021891,
      trait: "anorexia",
      score: 0.38134003,
      TF_impact: "none",
      snp: "rs2074509",
      coordinates: { chromosome: "chr6", position: 30908461 },
    },
    {
      fdr: 2.3155567911484134,
      trait: "cannabis",
      score: 0.06288004,
      TF_impact: "none",
      snp: "rs521354",
      coordinates: { chromosome: "chr1", position: 156123040 },
    },
    {
      fdr: 0.8349919626888878,
      trait: "autism",
      score: 0.16731024,
      TF_impact: "none",
      snp: "rs1860071",
      coordinates: { chromosome: "chr7", position: 140210460 },
    },
    {
      fdr: 2.772618444488247e-11,
      trait: "MDD",
      score: -0.61122656,
      TF_impact: "none",
      snp: "rs2997754",
      coordinates: { chromosome: "chr10", position: 75144379 },
    },
    {
      fdr: 1.086960851800919e-6,
      trait: "anorexia",
      score: 0.47418594,
      TF_impact: "pos",
      snp: "rs9429742",
      coordinates: { chromosome: "chr1", position: 114380168 },
    },
    {
      fdr: 1.004924712034468,
      trait: "MDD",
      score: -0.0011601448,
      TF_impact: "none",
      snp: "rs298558",
      coordinates: { chromosome: "chr5", position: 17341167 },
    },
    {
      fdr: 1.1008297903493955e-14,
      trait: "bipolar-I",
      score: -0.6937599,
      TF_impact: "pos",
      snp: "rs1214761",
      coordinates: { chromosome: "chr6", position: 43386693 },
    },
    {
      fdr: 1.0658671906573083,
      trait: "anorexia",
      score: -0.003680706,
      TF_impact: "none",
      snp: "rs7831883",
      coordinates: { chromosome: "chr8", position: 26658697 },
    },
    {
      fdr: 2.135034423174061,
      trait: "MDD",
      score: -0.09746981,
      TF_impact: "none",
      snp: "rs1553597",
      coordinates: { chromosome: "chr18", position: 55833792 },
    },
    {
      fdr: 8.858121634614052e-7,
      trait: "autism",
      score: -0.47914314,
      TF_impact: "pos",
      snp: "rs10777725",
      coordinates: { chromosome: "chr12", position: 95764126 },
    },
    {
      fdr: 1.3475836906237948,
      trait: "bipolar-2019",
      score: -0.013437271,
      TF_impact: "none",
      snp: "rs716191",
      coordinates: { chromosome: "chr4", position: 94995768 },
    },
    {
      fdr: 1.3014354031039282e-23,
      trait: "autism",
      score: 0.8786521,
      TF_impact: "neg",
      snp: "rs11986122",
      coordinates: { chromosome: "chr8", position: 10152439 },
    },
    {
      fdr: 0.49616489556853277,
      trait: "bipolar-2012",
      score: -0.19372892,
      TF_impact: "none",
      snp: "rs13012495",
      coordinates: { chromosome: "chr2", position: 98749181 },
    },
    {
      fdr: 1.6289941488523212,
      trait: "anorexia",
      score: 0.02165842,
      TF_impact: "none",
      snp: "rs11814972",
      coordinates: { chromosome: "chr10", position: 98173507 },
    },
    {
      fdr: 1.2702137327861684,
      trait: "bipolar-I",
      score: -0.010880947,
      TF_impact: "none",
      snp: "rs923724",
      coordinates: { chromosome: "chr12", position: 99246152 },
    },
    {
      fdr: 2.2082631018835337,
      trait: "anorexia",
      score: -0.09213829,
      TF_impact: "none",
      snp: "rs7964074",
      coordinates: { chromosome: "chr12", position: 57536591 },
    },
    {
      fdr: 0.007014953021830541,
      trait: "MDD",
      score: 0.31665564,
      TF_impact: "pos",
      snp: "rs28541419",
      coordinates: { chromosome: "chr15", position: 88402647 },
    },
    {
      fdr: 0.393352068729469,
      trait: "anorexia",
      score: -0.20369864,
      TF_impact: "pos",
      snp: "rs2090259",
      coordinates: { chromosome: "chr8", position: 51993877 },
    },
    {
      fdr: 0.16642785071337457,
      trait: "scz",
      score: -0.23279524,
      TF_impact: "none",
      snp: "rs12338960",
      coordinates: { chromosome: "chr9", position: 82128493 },
    },
    {
      fdr: 0.0008577665329580835,
      trait: "bipolar-I",
      score: -0.36097622,
      TF_impact: "none",
      snp: "rs7259187",
      coordinates: { chromosome: "chr19", position: 2686647 },
    },
    {
      fdr: 2.0283011002263933,
      trait: "ADHD-meta-filtered",
      score: 0.03833723,
      TF_impact: "pos",
      snp: "rs4078520",
      coordinates: { chromosome: "chr11", position: 723429 },
    },
    {
      fdr: 1.439314095195234,
      trait: "autism",
      score: 0.014688492,
      TF_impact: "pos",
      snp: "rs7719215",
      coordinates: { chromosome: "chr5", position: 54601388 },
    },
    {
      fdr: 0.016518317176479433,
      trait: "bipolar-I",
      score: 0.2961607,
      TF_impact: "pos",
      snp: "rs10826321",
      coordinates: { chromosome: "chr10", position: 59580445 },
    },
    {
      fdr: 1.5328883093831667,
      trait: "MDD",
      score: -0.019851208,
      TF_impact: "none",
      snp: "rs4886617",
      coordinates: { chromosome: "chr15", position: 73797452 },
    },
    {
      fdr: 0.6144197100738084,
      trait: "bipolar-2019",
      score: 0.18098545,
      TF_impact: "neg",
      snp: "rs1990565",
      coordinates: { chromosome: "chr19", position: 46289409 },
    },
    {
      fdr: 1.8529724283643787,
      trait: "MDD",
      score: 0.030295372,
      TF_impact: "pos",
      snp: "rs28377268",
      coordinates: { chromosome: "chr9", position: 95462774 },
    },
    {
      fdr: 5.99210181235713e-6,
      trait: "ADHD-meta-filtered",
      score: -0.45048714,
      TF_impact: "none",
      snp: "rs10033356",
      coordinates: { chromosome: "chr4", position: 80037099 },
    },
    {
      fdr: 0.0005357334250814573,
      trait: "anorexia",
      score: -0.37031937,
      TF_impact: "none",
      snp: "rs1264307",
      coordinates: { chromosome: "chr6", position: 30912980 },
    },
    {
      fdr: 1.6083862270182119e-6,
      trait: "anorexia",
      score: 0.46854544,
      TF_impact: "neg",
      snp: "rs28544076",
      coordinates: { chromosome: "chr1", position: 241950596 },
    },
    {
      fdr: 8.759795021513095e-5,
      trait: "bipolar-2019",
      score: 0.40187836,
      TF_impact: "pos",
      snp: "rs58988283",
      coordinates: { chromosome: "chr2", position: 209261433 },
    },
    {
      fdr: 0.00017850534046343625,
      trait: "MDD",
      score: -0.39065456,
      TF_impact: "none",
      snp: "rs376358",
      coordinates: { chromosome: "chr2", position: 104786677 },
    },
    {
      fdr: 0.21959517249128474,
      trait: "autism",
      score: 0.22126532,
      TF_impact: "pos",
      snp: "rs413778",
      coordinates: { chromosome: "chr17", position: 45639519 },
    },
    {
      fdr: 2.721569584728226e-10,
      trait: "bipolar-I",
      score: -0.5855384,
      TF_impact: "pos",
      snp: "rs11717383",
      coordinates: { chromosome: "chr3", position: 52253452 },
    },
    {
      fdr: 1.4304409566073513,
      trait: "pgc-panic-disorder",
      score: -0.1375413,
      TF_impact: "none",
      snp: "rs3949492",
      coordinates: { chromosome: "chr20", position: 4040488 },
    },
    {
      fdr: 1.1764796869433507,
      trait: "bipolar-2019",
      score: 0.0056710243,
      TF_impact: "none",
      snp: "rs12754658",
      coordinates: { chromosome: "chr1", position: 59072800 },
    },
    {
      fdr: 1.432192730471028,
      trait: "bipolar-2019",
      score: -0.13762188,
      TF_impact: "neg",
      snp: "rs3817030",
      coordinates: { chromosome: "chr16", position: 69316132 },
    },
    {
      fdr: 1.1148320304409578,
      trait: "MDD",
      score: -0.005469322,
      TF_impact: "none",
      snp: "rs359708",
      coordinates: { chromosome: "chr2", position: 126370357 },
    },
    {
      fdr: 0.25449055695023703,
      trait: "ADHD-meta-filtered",
      score: 0.21627283,
      TF_impact: "pos",
      snp: "rs9560010",
      coordinates: { chromosome: "chr13", position: 111214173 },
    },
    {
      fdr: 0.0002511402962710216,
      trait: "anorexia",
      score: -0.38372993,
      TF_impact: "none",
      snp: "rs3818524",
      coordinates: { chromosome: "chr6", position: 33692918 },
    },
    {
      fdr: 0.5926295105382186,
      trait: "autism",
      score: 0.1831913,
      TF_impact: "none",
      snp: "rs6928669",
      coordinates: { chromosome: "chr6", position: 23689021 },
    },
    {
      fdr: 0.0029721007818420075,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.33728218,
      TF_impact: "none",
      snp: "rs1715491",
      coordinates: { chromosome: "chr17", position: 77282742 },
    },
    {
      fdr: 1.1749351312728384,
      trait: "anorexia",
      score: -0.15016651,
      TF_impact: "pos",
      snp: "rs824202",
      coordinates: { chromosome: "chr15", position: 23764434 },
    },
    {
      fdr: 0.7657965553931468,
      trait: "ADHD-meta-filtered",
      score: 0.17153215,
      TF_impact: "none",
      snp: "rs62206557",
      coordinates: { chromosome: "chr20", position: 37180220 },
    },
    {
      fdr: 1.0638873662752457,
      trait: "ADHD-meta-filtered",
      score: -0.0036101341,
      TF_impact: "none",
      snp: "rs1249582",
      coordinates: { chromosome: "chr11", position: 68998427 },
    },
    {
      fdr: 1.0162954596261515,
      trait: "MDD",
      score: -0.00017261505,
      TF_impact: "pos",
      snp: "rs13248317",
      coordinates: { chromosome: "chr8", position: 20423431 },
    },
    {
      fdr: 7.15699876240694e-5,
      trait: "bipolar-2019",
      score: -0.40774488,
      TF_impact: "none",
      snp: "rs10263430",
      coordinates: { chromosome: "chr7", position: 29975830 },
    },
    {
      fdr: 1.117597447646633,
      trait: "bipolar-2019",
      score: -0.0055594444,
      TF_impact: "pos",
      snp: "rs6426841",
      coordinates: { chromosome: "chr1", position: 163820413 },
    },
    {
      fdr: 4.371636217137775e-5,
      trait: "bipolar-II",
      score: -0.41655445,
      TF_impact: "none",
      snp: "rs77570544",
      coordinates: { chromosome: "chr4", position: 111363088 },
    },
    {
      fdr: 0.5268385185287385,
      trait: "cannabis",
      score: 0.18838501,
      TF_impact: "pos",
      snp: "rs1348716",
      coordinates: { chromosome: "chr5", position: 61451158 },
    },
    {
      fdr: 0.5229262052227557,
      trait: "ADHD-meta-filtered",
      score: -0.1909604,
      TF_impact: "pos",
      snp: "rs7664633",
      coordinates: { chromosome: "chr4", position: 175807763 },
    },
    {
      fdr: 2.2157164402279537,
      trait: "bipolar-2019",
      score: -0.08991909,
      TF_impact: "pos",
      snp: "rs10414516",
      coordinates: { chromosome: "chr19", position: 2019759 },
    },
    {
      fdr: 0.01887330532942372,
      trait: "ADHD-meta-filtered",
      score: 0.291543,
      TF_impact: "pos",
      snp: "rs12483621",
      coordinates: { chromosome: "chr21", position: 36450798 },
    },
    {
      fdr: 2.077726461470332,
      trait: "MDD",
      score: -0.042793274,
      TF_impact: "none",
      snp: "rs11853271",
      coordinates: { chromosome: "chr15", position: 90482598 },
    },
    {
      fdr: 2.00558557243356,
      trait: "bipolar-I",
      score: 0.104074,
      TF_impact: "pos",
      snp: "rs184301136",
      coordinates: { chromosome: "chr12", position: 57591140 },
    },
    {
      fdr: 4.826976352992921e-6,
      trait: "autism",
      score: 0.45247316,
      TF_impact: "none",
      snp: "rs4841541",
      coordinates: { chromosome: "chr8", position: 11454320 },
    },
    {
      fdr: 0.002974216171122778,
      trait: "autism",
      score: 0.33535576,
      TF_impact: "none",
      snp: "rs74466898",
      coordinates: { chromosome: "chr18", position: 58192707 },
    },
    {
      fdr: 0.03741854889018648,
      trait: "anorexia",
      score: -0.27620173,
      TF_impact: "none",
      snp: "rs3804771",
      coordinates: { chromosome: "chr3", position: 141972565 },
    },
    {
      fdr: 1.2643972496861242,
      trait: "MDD",
      score: -0.010654449,
      TF_impact: "pos",
      snp: "rs12892062",
      coordinates: { chromosome: "chr14", position: 103417175 },
    },
    {
      fdr: 0.0008044844458576012,
      trait: "bipolar-2019",
      score: 0.36042166,
      TF_impact: "pos",
      snp: "rs6080766",
      coordinates: { chromosome: "chr20", position: 17662462 },
    },
    {
      fdr: 0.00043552659426092594,
      trait: "opioid-unexposed-vs-exposed",
      score: 0.3720889,
      TF_impact: "neg",
      snp: "rs1966072",
      coordinates: { chromosome: "chr8", position: 104324084 },
    },
    {
      fdr: 2.2434924727705416,
      trait: "MDD",
      score: -0.088339806,
      TF_impact: "pos",
      snp: "rs9564313",
      coordinates: { chromosome: "chr13", position: 66349555 },
    },
    {
      fdr: 2.125462589297745,
      trait: "cannabis",
      score: 0.045318127,
      TF_impact: "pos",
      snp: "rs3903344",
      coordinates: { chromosome: "chr8", position: 85032135 },
    },
    {
      fdr: 2.314504023583169,
      trait: "MDD",
      score: 0.068719864,
      TF_impact: "pos",
      snp: "rs1406713",
      coordinates: { chromosome: "chr15", position: 92918033 },
    },
    {
      fdr: 1.3993399489849612,
      trait: "autism",
      score: -0.015257359,
      TF_impact: "pos",
      snp: "rs73129824",
      coordinates: { chromosome: "chr20", position: 21554779 },
    },
    {
      fdr: 1.4835500552816767e-8,
      trait: "bipolar-2019",
      score: -0.5332575,
      TF_impact: "none",
      snp: "rs2673582",
      coordinates: { chromosome: "chr8", position: 132507817 },
    },
    {
      fdr: 0.0001686603676992234,
      trait: "anorexia",
      score: -0.39185476,
      TF_impact: "pos",
      snp: "rs167849",
      coordinates: { chromosome: "chr1", position: 96389200 },
    },
    {
      fdr: 1.0752225851317527,
      trait: "autism",
      score: 0.0021953583,
      TF_impact: "none",
      snp: "rs66691214",
      coordinates: { chromosome: "chr7", position: 30149188 },
    },
    {
      fdr: 1.8657526238978591e-6,
      trait: "MDD",
      score: -0.46802282,
      TF_impact: "pos",
      snp: "rs11609859",
      coordinates: { chromosome: "chr12", position: 124804115 },
    },
    {
      fdr: 1.3614873802696028,
      trait: "bipolar-I",
      score: -0.1414957,
      TF_impact: "pos",
      snp: "rs4964649",
      coordinates: { chromosome: "chr12", position: 108175527 },
    },
    {
      fdr: 1.5483298234349916,
      trait: "bipolar-2019",
      score: 0.018520355,
      TF_impact: "none",
      snp: "rs11640354",
      coordinates: { chromosome: "chr16", position: 12363065 },
    },
    {
      fdr: 2.0331255279651943,
      trait: "cannabis",
      score: 0.038558006,
      TF_impact: "none",
      snp: "rs611005",
      coordinates: { chromosome: "chr18", position: 35078630 },
    },
    {
      fdr: 1.0511381902688044,
      trait: "bipolar-I",
      score: 0.0012903214,
      TF_impact: "neg",
      snp: "rs6065778",
      coordinates: { chromosome: "chr20", position: 45059511 },
    },
    {
      fdr: 0.559338352230811,
      trait: "autism",
      score: 0.18570328,
      TF_impact: "pos",
      snp: "rs12547721",
      coordinates: { chromosome: "chr8", position: 8713161 },
    },
    {
      fdr: 0.0017907031938860834,
      trait: "anxiety",
      score: 0.3450837,
      TF_impact: "none",
      snp: "rs72817564",
      coordinates: { chromosome: "chr5", position: 165151668 },
    },
    {
      fdr: 1.5790361213652266,
      trait: "ADHD-meta-filtered",
      score: 0.019611359,
      TF_impact: "none",
      snp: "rs5745568",
      coordinates: { chromosome: "chr6", position: 33580617 },
    },
    {
      fdr: 0.07581202062666585,
      trait: "MDD",
      score: -0.25580406,
      TF_impact: "pos",
      snp: "rs9462535",
      coordinates: { chromosome: "chr6", position: 39348016 },
    },
    {
      fdr: 1.8395102451054572,
      trait: "anxiety",
      score: -0.11455536,
      TF_impact: "none",
      snp: "rs7795555",
      coordinates: { chromosome: "chr7", position: 154104933 },
    },
    {
      fdr: 0.3128276945618104,
      trait: "MDD",
      score: -0.21145105,
      TF_impact: "neg",
      snp: "rs4907311",
      coordinates: { chromosome: "chr2", position: 96345624 },
    },
    {
      fdr: 0.0002441604131347247,
      trait: "autism",
      score: 0.38247275,
      TF_impact: "none",
      snp: "rs62057151",
      coordinates: { chromosome: "chr17", position: 45826476 },
    },
    {
      fdr: 0.0008178737586107524,
      trait: "bipolar-2019",
      score: 0.36007357,
      TF_impact: "none",
      snp: "rs56303180",
      coordinates: { chromosome: "chr14", position: 99253536 },
    },
    {
      fdr: 1.0792822075214041,
      trait: "bipolar-I",
      score: 0.0023508072,
      TF_impact: "none",
      snp: "rs2286940",
      coordinates: { chromosome: "chr3", position: 37028615 },
    },
    {
      fdr: 4.630443251847446e-21,
      trait: "autism",
      score: 0.82666206,
      TF_impact: "pos",
      snp: "rs11685464",
      coordinates: { chromosome: "chr2", position: 236875967 },
    },
    {
      fdr: 2.3166408782942653,
      trait: "anxiety",
      score: -0.06468201,
      TF_impact: "none",
      snp: "rs66477321",
      coordinates: { chromosome: "chr17", position: 33523806 },
    },
    {
      fdr: 1.0844068500917319,
      trait: "cannabis",
      score: -0.0043473244,
      TF_impact: "none",
      snp: "rs66791174",
      coordinates: { chromosome: "chr3", position: 100517049 },
    },
    {
      fdr: 2.1882273776724417,
      trait: "bipolar-I",
      score: 0.05060959,
      TF_impact: "none",
      snp: "rs11990215",
      coordinates: { chromosome: "chr8", position: 143995983 },
    },
    {
      fdr: 1.4256650491962197,
      trait: "ADHD-meta-filtered",
      score: 0.014210224,
      TF_impact: "none",
      snp: "rs2236947",
      coordinates: { chromosome: "chr3", position: 50334001 },
    },
    {
      fdr: 1.4603785520042176,
      trait: "autism",
      score: 0.015278339,
      TF_impact: "none",
      snp: "rs17153498",
      coordinates: { chromosome: "chr8", position: 11589446 },
    },
    {
      fdr: 1.9438530082350058,
      trait: "anorexia",
      score: -0.035920143,
      TF_impact: "pos",
      snp: "rs3934393",
      coordinates: { chromosome: "chr6", position: 46410524 },
    },
    {
      fdr: 1.0393565724467726e-9,
      trait: "anorexia",
      score: 0.56690645,
      TF_impact: "pos",
      snp: "rs3227",
      coordinates: { chromosome: "chr6", position: 33694518 },
    },
    {
      fdr: 1.7798099921980577,
      trait: "bipolar-2019",
      score: -0.11858988,
      TF_impact: "none",
      snp: "rs56293342",
      coordinates: { chromosome: "chr2", position: 161427107 },
    },
    {
      fdr: 4.70914486957906e-10,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.5763607,
      TF_impact: "pos",
      snp: "rs6747398",
      coordinates: { chromosome: "chr2", position: 64922741 },
    },
    {
      fdr: 1.0618407821575138,
      trait: "MDD",
      score: -0.0035467148,
      TF_impact: "none",
      snp: "rs146299656",
      coordinates: { chromosome: "chr9", position: 4555436 },
    },
    {
      fdr: 1.5092407013297418,
      trait: "autism",
      score: -0.13279581,
      TF_impact: "pos",
      snp: "rs56101454",
      coordinates: { chromosome: "chr6", position: 29685936 },
    },
    {
      fdr: 1.0499975886677284,
      trait: "bipolar-I",
      score: -0.003115654,
      TF_impact: "pos",
      snp: "rs7656375",
      coordinates: { chromosome: "chr4", position: 142840715 },
    },
    {
      fdr: 2.1818648123708866,
      trait: "bipolar-2019",
      score: 0.049934864,
      TF_impact: "none",
      snp: "rs2043332",
      coordinates: { chromosome: "chr19", position: 10780563 },
    },
    {
      fdr: 1.49544876263725e-8,
      trait: "bipolar-I",
      score: -0.53288555,
      TF_impact: "pos",
      snp: "rs58231871",
      coordinates: { chromosome: "chr7", position: 27604074 },
    },
    {
      fdr: 2.161323821681522,
      trait: "cannabis",
      score: 0.04770136,
      TF_impact: "pos",
      snp: "rs4647102",
      coordinates: { chromosome: "chr5", position: 60903536 },
    },
    {
      fdr: 0.0001840958287069416,
      trait: "MDD",
      score: -0.38994455,
      TF_impact: "none",
      snp: "rs113670431",
      coordinates: { chromosome: "chr4", position: 47270605 },
    },
    {
      fdr: 0.006503842594375793,
      trait: "MDD",
      score: -0.32048607,
      TF_impact: "none",
      snp: "rs12694082",
      coordinates: { chromosome: "chr2", position: 207252032 },
    },
    {
      fdr: 1.077687871121156,
      trait: "autism",
      score: -0.0041446686,
      TF_impact: "pos",
      snp: "rs1497027",
      coordinates: { chromosome: "chr9", position: 73548438 },
    },
    {
      fdr: 2.161056707550409e-6,
      trait: "cannabis",
      score: -0.46590185,
      TF_impact: "none",
      snp: "rs41483748",
      coordinates: { chromosome: "chr2", position: 40258384 },
    },
    {
      fdr: 0.0007330721721501141,
      trait: "anorexia",
      score: -0.36438465,
      TF_impact: "none",
      snp: "rs9830600",
      coordinates: { chromosome: "chr3", position: 94414768 },
    },
    {
      fdr: 1.7844815932321163,
      trait: "autism",
      score: -0.029121399,
      TF_impact: "none",
      snp: "rs3989103",
      coordinates: { chromosome: "chr8", position: 11449337 },
    },
    {
      fdr: 1.1631052665582644,
      trait: "cannabis",
      score: -0.007080078,
      TF_impact: "none",
      snp: "rs17509575",
      coordinates: { chromosome: "chr2", position: 117889976 },
    },
    {
      fdr: 1.8301455699539406,
      trait: "bipolar-2019",
      score: 0.028932095,
      TF_impact: "none",
      snp: "rs34001546",
      coordinates: { chromosome: "chr1", position: 150487239 },
    },
    {
      fdr: 1.3324401528766912e-5,
      trait: "anorexia",
      score: -0.4369526,
      TF_impact: "pos",
      snp: "rs10400567",
      coordinates: { chromosome: "chr12", position: 49331937 },
    },
    {
      fdr: 1.808901072137919e-5,
      trait: "bipolar-2019",
      score: 0.43054342,
      TF_impact: "pos",
      snp: "rs13157748",
      coordinates: { chromosome: "chr5", position: 59831832 },
    },
    {
      fdr: 0.5280497285928576,
      trait: "bipolar-I",
      score: 0.18846178,
      TF_impact: "none",
      snp: "rs2836762",
      coordinates: { chromosome: "chr21", position: 38931202 },
    },
    {
      fdr: 0.00962266220192068,
      trait: "MDD",
      score: -0.3108716,
      TF_impact: "none",
      snp: "rs7923465",
      coordinates: { chromosome: "chr10", position: 12719685 },
    },
    {
      fdr: 0.06016497638246275,
      trait: "cannabis",
      score: 0.26167965,
      TF_impact: "pos",
      snp: "rs10767439",
      coordinates: { chromosome: "chr11", position: 25734803 },
    },
    {
      fdr: 1.0601029204504973e-5,
      trait: "cannabis",
      score: 0.4394269,
      TF_impact: "none",
      snp: "rs7807955",
      coordinates: { chromosome: "chr7", position: 37475854 },
    },
    {
      fdr: 1.2999216873633819,
      trait: "bipolar-2019",
      score: 0.009956121,
      TF_impact: "none",
      snp: "rs1007537",
      coordinates: { chromosome: "chr15", position: 77620375 },
    },
    {
      fdr: 1.9361709160503635,
      trait: "bipolar-2019",
      score: -0.035683155,
      TF_impact: "none",
      snp: "rs6751612",
      coordinates: { chromosome: "chr2", position: 209425702 },
    },
    {
      fdr: 1.2919385553539364e-6,
      trait: "bipolar-I",
      score: 0.4717455,
      TF_impact: "pos",
      snp: "rs576",
      coordinates: { chromosome: "chr3", position: 69967316 },
    },
    {
      fdr: 0.0071796826489635805,
      trait: "bipolar-I",
      score: 0.31597996,
      TF_impact: "none",
      snp: "rs11242430",
      coordinates: { chromosome: "chr5", position: 138487177 },
    },
    {
      fdr: 0.00021542551706887436,
      trait: "anorexia",
      score: -0.3870492,
      TF_impact: "none",
      snp: "rs6668345",
      coordinates: { chromosome: "chr1", position: 71565599 },
    },
    {
      fdr: 2.178556546973205,
      trait: "anxiety",
      score: -0.05126381,
      TF_impact: "none",
      snp: "rs2600073",
      coordinates: { chromosome: "chr3", position: 10804259 },
    },
    {
      fdr: 2.2954041894693815,
      trait: "ADHD-meta-filtered",
      score: 0.077907085,
      TF_impact: "none",
      snp: "rs10950400",
      coordinates: { chromosome: "chr7", position: 1842834 },
    },
    {
      fdr: 2.15331146026914,
      trait: "bipolar-2019",
      score: 0.047296524,
      TF_impact: "pos",
      snp: "rs35138622",
      coordinates: { chromosome: "chr19", position: 58401421 },
    },
    {
      fdr: 0.0031164128819097313,
      trait: "anxiety",
      score: -0.3362236,
      TF_impact: "pos",
      snp: "rs12917636",
      coordinates: { chromosome: "chr16", position: 55036277 },
    },
    {
      fdr: 8.524259618846196e-5,
      trait: "bipolar-I",
      score: 0.40269518,
      TF_impact: "none",
      snp: "rs7570730",
      coordinates: { chromosome: "chr2", position: 27799808 },
    },
    {
      fdr: 0.30071013065883506,
      trait: "autism",
      score: -0.21277428,
      TF_impact: "pos",
      snp: "rs327287",
      coordinates: { chromosome: "chr5", position: 38642450 },
    },
    {
      fdr: 2.1823314005363152,
      trait: "pgc-panic-disorder",
      score: 0.05017662,
      TF_impact: "pos",
      snp: "rs1865093",
      coordinates: { chromosome: "chr19", position: 39445494 },
    },
    {
      fdr: 1.8742097087291082e-23,
      trait: "anorexia",
      score: 0.87082815,
      TF_impact: "none",
      snp: "rs67060340",
      coordinates: { chromosome: "chr3", position: 48877888 },
    },
    {
      fdr: 2.277678388494773e-7,
      trait: "MDD",
      score: -0.49713516,
      TF_impact: "pos",
      snp: "rs71546165",
      coordinates: { chromosome: "chr7", position: 54327009 },
    },
    {
      fdr: 0.0015474753320961236,
      trait: "bipolar-2019",
      score: -0.34979534,
      TF_impact: "none",
      snp: "rs941520",
      coordinates: { chromosome: "chr14", position: 99243365 },
    },
    {
      fdr: 7.26590164384977e-14,
      trait: "ADHD-meta-filtered",
      score: -0.6743021,
      TF_impact: "none",
      snp: "rs2498442",
      coordinates: { chromosome: "chr6", position: 98045334 },
    },
    {
      fdr: 0.0813741066266786,
      trait: "ADHD-meta-filtered",
      score: 0.25196552,
      TF_impact: "pos",
      snp: "rs4690138",
      coordinates: { chromosome: "chr4", position: 80017552 },
    },
    {
      fdr: 1.662764822445777,
      trait: "MDD",
      score: -0.024670124,
      TF_impact: "none",
      snp: "rs9920038",
      coordinates: { chromosome: "chr15", position: 66415523 },
    },
    {
      fdr: 1.0048462888782974,
      trait: "ADHD-meta-filtered",
      score: -0.00069856644,
      TF_impact: "pos",
      snp: "rs718571",
      coordinates: { chromosome: "chr18", position: 42642213 },
    },
    {
      fdr: 3.6620691578243533e-13,
      trait: "ADHD-meta-filtered",
      score: 0.6550698,
      TF_impact: "none",
      snp: "rs17254786",
      coordinates: { chromosome: "chr3", position: 43247135 },
    },
    {
      fdr: 1.3974279215422,
      trait: "anorexia",
      score: -0.015161037,
      TF_impact: "pos",
      snp: "rs7796558",
      coordinates: { chromosome: "chr7", position: 104089931 },
    },
    {
      fdr: 0.0038158351941592864,
      trait: "MDD",
      score: -0.33197403,
      TF_impact: "neg",
      snp: "rs77328845",
      coordinates: { chromosome: "chr10", position: 107299926 },
    },
    {
      fdr: 2.0191739290782015e-14,
      trait: "anxiety",
      score: 0.6858878,
      TF_impact: "none",
      snp: "rs61141806",
      coordinates: { chromosome: "chr4", position: 169900557 },
    },
    {
      fdr: 8.546565865734279e-10,
      trait: "ADHD-meta-filtered",
      score: 0.5695038,
      TF_impact: "pos",
      snp: "rs57191490",
      coordinates: { chromosome: "chr12", position: 49671106 },
    },
    {
      fdr: 0.017441273345106364,
      trait: "bipolar-2012",
      score: -0.29614162,
      TF_impact: "none",
      snp: "rs940455",
      coordinates: { chromosome: "chr7", position: 146966476 },
    },
    {
      fdr: 1.3815826468601127,
      trait: "bipolar-2019",
      score: 0.012640476,
      TF_impact: "none",
      snp: "rs329304",
      coordinates: { chromosome: "chr5", position: 134562139 },
    },
    {
      fdr: 2.668152546938989e-8,
      trait: "bipolar-I",
      score: 0.52336264,
      TF_impact: "pos",
      snp: "rs138322482",
      coordinates: { chromosome: "chr8", position: 26240376 },
    },
    {
      fdr: 2.284938193078874,
      trait: "MDD",
      score: -0.07749748,
      TF_impact: "pos",
      snp: "rs7269614",
      coordinates: { chromosome: "chr20", position: 47205863 },
    },
    {
      fdr: 1.9135968882739711,
      trait: "bipolar-I",
      score: 0.10955763,
      TF_impact: "pos",
      snp: "rs17177789",
      coordinates: { chromosome: "chr14", position: 63212991 },
    },
    {
      fdr: 1.5763273459494918,
      trait: "anorexia",
      score: -0.021329165,
      TF_impact: "none",
      snp: "rs2803340",
      coordinates: { chromosome: "chr1", position: 1946632 },
    },
    {
      fdr: 0.7620216987182956,
      trait: "autism",
      score: 0.171772,
      TF_impact: "pos",
      snp: "rs16875447",
      coordinates: { chromosome: "chr5", position: 78472665 },
    },
    {
      fdr: 1.038988975313365,
      trait: "bipolar-I",
      score: 0.0007610321,
      TF_impact: "none",
      snp: "rs76369658",
      coordinates: { chromosome: "chr11", position: 134398216 },
    },
    {
      fdr: 2.2945028263257465,
      trait: "bipolar-2019",
      score: -0.079413414,
      TF_impact: "none",
      snp: "rs10931887",
      coordinates: { chromosome: "chr2", position: 200104182 },
    },
    {
      fdr: 0.007949105633331835,
      trait: "anxiety",
      score: -0.3154006,
      TF_impact: "none",
      snp: "rs2706240",
      coordinates: { chromosome: "chr13", position: 50682866 },
    },
    {
      fdr: 0.003429883854387049,
      trait: "ADHD-meta-filtered",
      score: 0.33241177,
      TF_impact: "pos",
      snp: "rs727257",
      coordinates: { chromosome: "chr14", position: 91058332 },
    },
    {
      fdr: 0.007321831606665751,
      trait: "bipolar-I",
      score: -0.317173,
      TF_impact: "neg",
      snp: "rs2239017",
      coordinates: { chromosome: "chr12", position: 2180485 },
    },
    {
      fdr: 6.684093759944236e-16,
      trait: "bipolar-II",
      score: 0.7194233,
      TF_impact: "pos",
      snp: "rs9468220",
      coordinates: { chromosome: "chr6", position: 27765197 },
    },
    {
      fdr: 2.104298710500776,
      trait: "bipolar-2019",
      score: -0.098474026,
      TF_impact: "neg",
      snp: "rs267003",
      coordinates: { chromosome: "chr5", position: 155451382 },
    },
    {
      fdr: 1.4759377528727855,
      trait: "MDD",
      score: 0.015825748,
      TF_impact: "pos",
      snp: "rs309452",
      coordinates: { chromosome: "chr9", position: 37433962 },
    },
    {
      fdr: 2.2411610279910077e-7,
      trait: "bipolar-I",
      score: 0.49574184,
      TF_impact: "pos",
      snp: "rs12575395",
      coordinates: { chromosome: "chr11", position: 111301339 },
    },
    {
      fdr: 1.703765979523282,
      trait: "autism",
      score: 0.024538994,
      TF_impact: "pos",
      snp: "rs75732533",
      coordinates: { chromosome: "chr7", position: 158868921 },
    },
    {
      fdr: 1.936094229077389,
      trait: "autism",
      score: -0.035693645,
      TF_impact: "pos",
      snp: "rs62064664",
      coordinates: { chromosome: "chr17", position: 46004096 },
    },
    {
      fdr: 3.52786954550948e-5,
      trait: "bipolar-2012",
      score: -0.42076826,
      TF_impact: "none",
      snp: "rs17149512",
      coordinates: { chromosome: "chr7", position: 87398843 },
    },
    {
      fdr: 0.00670428429741639,
      trait: "cannabis",
      score: -0.31978416,
      TF_impact: "pos",
      snp: "rs12237222",
      coordinates: { chromosome: "chr9", position: 83219828 },
    },
    {
      fdr: 3.497872336042655e-10,
      trait: "bipolar-II",
      score: 0.5804863,
      TF_impact: "pos",
      snp: "rs5758065",
      coordinates: { chromosome: "chr22", position: 40765045 },
    },
    {
      fdr: 1.5571475118413374e-9,
      trait: "bipolar-2019",
      score: -0.56236935,
      TF_impact: "neg",
      snp: "rs12724450",
      coordinates: { chromosome: "chr1", position: 150508095 },
    },
    {
      fdr: 0.004600252659316982,
      trait: "ADHD-meta-filtered",
      score: -0.32792187,
      TF_impact: "none",
      snp: "rs463334",
      coordinates: { chromosome: "chr3", position: 13070723 },
    },
    {
      fdr: 1.9614151775265718,
      trait: "bipolar-2019",
      score: -0.108765125,
      TF_impact: "none",
      snp: "rs2005983",
      coordinates: { chromosome: "chr22", position: 43036313 },
    },
    {
      fdr: 0.4116813282579363,
      trait: "bipolar-2019",
      score: 0.20008755,
      TF_impact: "none",
      snp: "rs10819162",
      coordinates: { chromosome: "chr9", position: 126463259 },
    },
    {
      fdr: 5.470297377351396e-6,
      trait: "autism",
      score: 0.4503708,
      TF_impact: "none",
      snp: "rs7866443",
      coordinates: { chromosome: "chr9", position: 116417597 },
    },
    {
      fdr: 1.4030369355604762,
      trait: "MDD",
      score: -0.015398979,
      TF_impact: "none",
      snp: "rs10067299",
      coordinates: { chromosome: "chr5", position: 124660916 },
    },
    {
      fdr: 8.30342802230338e-5,
      trait: "MDD",
      score: -0.40503597,
      TF_impact: "none",
      snp: "rs4598945",
      coordinates: { chromosome: "chr17", position: 65433859 },
    },
    {
      fdr: 1.9989907087412748,
      trait: "ADHD-meta-filtered",
      score: -0.106743336,
      TF_impact: "none",
      snp: "rs10447760",
      coordinates: { chromosome: "chr7", position: 114083210 },
    },
    {
      fdr: 1.099450796110556,
      trait: "MDD",
      score: 0.0029759407,
      TF_impact: "pos",
      snp: "rs145232240",
      coordinates: { chromosome: "chr4", position: 79598985 },
    },
    {
      fdr: 0.0048350646712504814,
      trait: "cannabis",
      score: -0.3268609,
      TF_impact: "pos",
      snp: "rs10118853",
      coordinates: { chromosome: "chr9", position: 108889742 },
    },
    {
      fdr: 0.0017049944290766638,
      trait: "anorexia",
      score: 0.3460865,
      TF_impact: "none",
      snp: "rs10135188",
      coordinates: { chromosome: "chr14", position: 32004590 },
    },
    {
      fdr: 0.06864415919308846,
      trait: "MDD",
      score: 0.2573514,
      TF_impact: "pos",
      snp: "rs12992411",
      coordinates: { chromosome: "chr2", position: 145038465 },
    },
    {
      fdr: 2.997447428032073e-5,
      trait: "cannabis",
      score: -0.4235778,
      TF_impact: "none",
      snp: "rs11198872",
      coordinates: { chromosome: "chr10", position: 119315803 },
    },
    {
      fdr: 1.3450562850047514,
      trait: "opioid-vs-exposed-ctrl",
      score: -0.013368607,
      TF_impact: "pos",
      snp: "rs6504407",
      coordinates: { chromosome: "chr17", position: 66275871 },
    },
    {
      fdr: 4.103612687175098e-18,
      trait: "ADHD-meta-filtered",
      score: 0.76915073,
      TF_impact: "none",
      snp: "rs11596250",
      coordinates: { chromosome: "chr10", position: 104784635 },
    },
    {
      fdr: 0.0016180784926680056,
      trait: "scz",
      score: -0.34893703,
      TF_impact: "none",
      snp: "rs6930933",
      coordinates: { chromosome: "chr6", position: 32415633 },
    },
    {
      fdr: 0.005965546040360969,
      trait: "autism",
      score: 0.32041407,
      TF_impact: "none",
      snp: "rs17762308",
      coordinates: { chromosome: "chr17", position: 45703582 },
    },
    {
      fdr: 1.3022407988793612,
      trait: "autism",
      score: 0.010008335,
      TF_impact: "pos",
      snp: "rs6945690",
      coordinates: { chromosome: "chr7", position: 3722691 },
    },
    {
      fdr: 1.1368169162635868,
      trait: "bipolar-2019",
      score: -0.006272316,
      TF_impact: "none",
      snp: "rs11669127",
      coordinates: { chromosome: "chr19", position: 58492463 },
    },
    {
      fdr: 1.409729044838851,
      trait: "anxiety",
      score: 0.0137319565,
      TF_impact: "neg",
      snp: "rs11246872",
      coordinates: { chromosome: "chr12", position: 131583545 },
    },
    {
      fdr: 0.8022598223637125,
      trait: "autism",
      score: -0.17128754,
      TF_impact: "neg",
      snp: "rs9961111",
      coordinates: { chromosome: "chr18", position: 52160869 },
    },
    {
      fdr: 0.9502305546712476,
      trait: "bipolar-2019",
      score: -0.1627121,
      TF_impact: "none",
      snp: "rs566370",
      coordinates: { chromosome: "chr4", position: 151990529 },
    },
    {
      fdr: 1.860755556414471,
      trait: "anorexia",
      score: 0.030831337,
      TF_impact: "none",
      snp: "rs1700159",
      coordinates: { chromosome: "chr12", position: 51912002 },
    },
    {
      fdr: 1.886249844118947,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.110731125,
      TF_impact: "none",
      snp: "rs6741762",
      coordinates: { chromosome: "chr2", position: 196068834 },
    },
    {
      fdr: 2.29811917407977,
      trait: "cannabis",
      score: -0.06113434,
      TF_impact: "pos",
      snp: "rs799168",
      coordinates: { chromosome: "chr7", position: 73636534 },
    },
    {
      fdr: 1.369889847188183e-7,
      trait: "autism",
      score: -0.5039959,
      TF_impact: "pos",
      snp: "rs10421292",
      coordinates: { chromosome: "chr19", position: 49146241 },
    },
    {
      fdr: 1.4008768619215635,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.015296936,
      TF_impact: "none",
      snp: "rs1995755",
      coordinates: { chromosome: "chr17", position: 77287459 },
    },
    {
      fdr: 1.5992150000379286,
      trait: "bipolar-I",
      score: -0.022335052,
      TF_impact: "none",
      snp: "rs10480087",
      coordinates: { chromosome: "chr7", position: 29923499 },
    },
    {
      fdr: 0.14868342554667435,
      trait: "bipolar-I",
      score: -0.23679876,
      TF_impact: "none",
      snp: "rs237475",
      coordinates: { chromosome: "chr20", position: 49432969 },
    },
    {
      fdr: 0.654587699883619,
      trait: "MDD",
      score: 0.1781559,
      TF_impact: "none",
      snp: "rs4891478",
      coordinates: { chromosome: "chr18", position: 72368663 },
    },
    {
      fdr: 1.13062064939557,
      trait: "ADHD-meta-filtered",
      score: -0.0060830116,
      TF_impact: "pos",
      snp: "rs4783757",
      coordinates: { chromosome: "chr16", position: 61421349 },
    },
    {
      fdr: 0.2888363463922302,
      trait: "bipolar-2019",
      score: -0.21408081,
      TF_impact: "pos",
      snp: "rs12724815",
      coordinates: { chromosome: "chr1", position: 95084505 },
    },
    {
      fdr: 0.9554738630083817,
      trait: "bipolar-2019",
      score: 0.16064739,
      TF_impact: "none",
      snp: "rs2667772",
      coordinates: { chromosome: "chr15", position: 77581562 },
    },
    {
      fdr: 1.460590774171974,
      trait: "anorexia",
      score: -0.13620758,
      TF_impact: "pos",
      snp: "rs9839226",
      coordinates: { chromosome: "chr3", position: 172160313 },
    },
    {
      fdr: 0.017426669312334775,
      trait: "ADHD-meta-filtered",
      score: 0.29440498,
      TF_impact: "none",
      snp: "rs10759922",
      coordinates: { chromosome: "chr9", position: 117412158 },
    },
    {
      fdr: 1.2914485277028824,
      trait: "MDD",
      score: -0.01153183,
      TF_impact: "none",
      snp: "rs28384416",
      coordinates: { chromosome: "chr12", position: 109477378 },
    },
    {
      fdr: 0.00022720418409430187,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.38371563,
      TF_impact: "pos",
      snp: "rs11706886",
      coordinates: { chromosome: "chr3", position: 105747211 },
    },
  ],
  "PTM-neurons": [
    {
      fdr: 0.052559319944056965,
      trait: "ADHD-meta-filtered",
      score: -0.1939969,
      TF_impact: "none",
      snp: "rs76708321",
      coordinates: { chromosome: "chr2", position: 10810992 },
    },
    {
      fdr: 0.0002302694734671375,
      trait: "MDD",
      score: -0.27891445,
      TF_impact: "none",
      snp: "rs7206087",
      coordinates: { chromosome: "chr16", position: 65787218 },
    },
    {
      fdr: 5.55897020887377e-18,
      trait: "ADHD-meta-filtered",
      score: -0.54687214,
      TF_impact: "pos",
      snp: "rs530614",
      coordinates: { chromosome: "chr6", position: 33747004 },
    },
    {
      fdr: 0.009125221410081766,
      trait: "bipolar-2019",
      score: -0.22529602,
      TF_impact: "none",
      snp: "rs28464158",
      coordinates: { chromosome: "chr8", position: 9606262 },
    },
    {
      fdr: 0.006226775686914676,
      trait: "bipolar-2019",
      score: -0.23253202,
      TF_impact: "pos",
      snp: "rs73050128",
      coordinates: { chromosome: "chr7", position: 1922246 },
    },
    {
      fdr: 1.1779688073398196e-5,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.31442547,
      TF_impact: "none",
      snp: "rs4729566",
      coordinates: { chromosome: "chr7", position: 100016690 },
    },
    {
      fdr: 1.5322703491441814,
      trait: "bipolar-2012",
      score: -0.088823795,
      TF_impact: "pos",
      snp: "rs13321742",
      coordinates: { chromosome: "chr3", position: 171802003 },
    },
    {
      fdr: 7.347183419108925e-8,
      trait: "MDD",
      score: 0.3660531,
      TF_impact: "pos",
      snp: "rs34002388",
      coordinates: { chromosome: "chr1", position: 49293168 },
    },
    {
      fdr: 1.1653511174901958,
      trait: "bipolar-II",
      score: 0.006975174,
      TF_impact: "none",
      snp: "rs72674307",
      coordinates: { chromosome: "chr14", position: 42215423 },
    },
    {
      fdr: 2.6160784764563502e-12,
      trait: "anxiety",
      score: 0.45317793,
      TF_impact: "pos",
      snp: "rs16869385",
      coordinates: { chromosome: "chr6", position: 32176214 },
    },
    {
      fdr: 1.7399623682354617,
      trait: "bipolar-2019",
      score: 0.029410362,
      TF_impact: "pos",
      snp: "rs55848443",
      coordinates: { chromosome: "chr4", position: 37219430 },
    },
    {
      fdr: 1.738530220366512,
      trait: "MDD",
      score: -0.07836056,
      TF_impact: "neg",
      snp: "rs10263892",
      coordinates: { chromosome: "chr7", position: 32785569 },
    },
    {
      fdr: 1.5851569489925694,
      trait: "MDD",
      score: -0.02207756,
      TF_impact: "none",
      snp: "rs2000919",
      coordinates: { chromosome: "chr11", position: 112591113 },
    },
    {
      fdr: 6.3452388890624295e-6,
      trait: "scz",
      score: -0.3217783,
      TF_impact: "none",
      snp: "rs7200336",
      coordinates: { chromosome: "chr16", position: 4372606 },
    },
    {
      fdr: 1.044333702727063,
      trait: "bipolar-II",
      score: -0.001947403,
      TF_impact: "pos",
      snp: "rs10503626",
      coordinates: { chromosome: "chr8", position: 18689574 },
    },
    {
      fdr: 1.5024559531203363,
      trait: "bipolar-2019",
      score: 0.018928528,
      TF_impact: "none",
      snp: "rs6478741",
      coordinates: { chromosome: "chr9", position: 126478770 },
    },
    {
      fdr: 8.159813559571914e-5,
      trait: "bipolar-I",
      score: 0.29229593,
      TF_impact: "pos",
      snp: "rs72669161",
      coordinates: { chromosome: "chr4", position: 122537923 },
    },
    {
      fdr: 0.00019247811243327915,
      trait: "bipolar-I",
      score: 0.2818694,
      TF_impact: "pos",
      snp: "rs12623170",
      coordinates: { chromosome: "chr2", position: 27797290 },
    },
    {
      fdr: 0.0009963872052680322,
      trait: "MDD",
      score: 0.25939035,
      TF_impact: "pos",
      snp: "rs6673687",
      coordinates: { chromosome: "chr1", position: 205701241 },
    },
    {
      fdr: 0.0006052842975610445,
      trait: "bipolar-2019",
      score: 0.2661314,
      TF_impact: "none",
      snp: "rs1995210",
      coordinates: { chromosome: "chr3", position: 2391127 },
    },
    {
      fdr: 0.3024987492315302,
      trait: "bipolar-I",
      score: 0.15358019,
      TF_impact: "none",
      snp: "rs3095337",
      coordinates: { chromosome: "chr6", position: 30769814 },
    },
    {
      fdr: 3.330677368262074e-10,
      trait: "autism",
      score: 0.41476727,
      TF_impact: "pos",
      snp: "rs2945236",
      coordinates: { chromosome: "chr8", position: 8310872 },
    },
    {
      fdr: 0.0005202493438811057,
      trait: "MDD",
      score: -0.26823807,
      TF_impact: "none",
      snp: "rs116974312",
      coordinates: { chromosome: "chr9", position: 123457752 },
    },
    {
      fdr: 1.9898510448245683,
      trait: "anorexia",
      score: -0.04971218,
      TF_impact: "pos",
      snp: "rs17042057",
      coordinates: { chromosome: "chr2", position: 21268991 },
    },
    {
      fdr: 1.8584939491952455,
      trait: "bipolar-2019",
      score: -0.037542343,
      TF_impact: "pos",
      snp: "rs11784417",
      coordinates: { chromosome: "chr8", position: 143936584 },
    },
    {
      fdr: 1.137160867963095,
      trait: "cannabis",
      score: -0.0060105324,
      TF_impact: "pos",
      snp: "rs1344047",
      coordinates: { chromosome: "chr21", position: 37144721 },
    },
    {
      fdr: 1.5661617468390947,
      trait: "bipolar-2019",
      score: -0.087284565,
      TF_impact: "pos",
      snp: "rs75292817",
      coordinates: { chromosome: "chr8", position: 33708848 },
    },
    {
      fdr: 1.0882682363456366,
      trait: "cannabis",
      score: -0.0039577484,
      TF_impact: "pos",
      snp: "rs9566899",
      coordinates: { chromosome: "chr13", position: 41996192 },
    },
    {
      fdr: 4.377652900858903e-8,
      trait: "autism",
      score: 0.37104893,
      TF_impact: "none",
      snp: "rs1792313",
      coordinates: { chromosome: "chr11", position: 71364562 },
    },
    {
      fdr: 1.0067735624806122,
      trait: "bipolar-2019",
      score: 0.11237764,
      TF_impact: "none",
      snp: "rs1931895",
      coordinates: { chromosome: "chr6", position: 116251808 },
    },
    {
      fdr: 4.875766327944152e-11,
      trait: "MDD",
      score: 0.4305315,
      TF_impact: "pos",
      snp: "rs12124090",
      coordinates: { chromosome: "chr1", position: 11478363 },
    },
    {
      fdr: 0.4428006125951844,
      trait: "ADHD-meta-filtered",
      score: 0.14103508,
      TF_impact: "pos",
      snp: "rs72773227",
      coordinates: { chromosome: "chr10", position: 16951201 },
    },
    {
      fdr: 1.013717556637132,
      trait: "bipolar-2019",
      score: 0.00064086914,
      TF_impact: "none",
      snp: "rs17028044",
      coordinates: { chromosome: "chr4", position: 151887741 },
    },
    {
      fdr: 1.5840069266900434,
      trait: "bipolar-2019",
      score: -0.02205658,
      TF_impact: "none",
      snp: "rs616021",
      coordinates: { chromosome: "chr8", position: 9938864 },
    },
    {
      fdr: 1.9141347225361012,
      trait: "cannabis",
      score: 0.04113722,
      TF_impact: "none",
      snp: "rs7925990",
      coordinates: { chromosome: "chr11", position: 114027745 },
    },
    {
      fdr: 1.8613533373581495,
      trait: "ADHD-meta-filtered",
      score: 0.071621895,
      TF_impact: "pos",
      snp: "rs11741608",
      coordinates: { chromosome: "chr5", position: 104413091 },
    },
    {
      fdr: 1.9328376431322525,
      trait: "bipolar-I",
      score: 0.06515074,
      TF_impact: "neg",
      snp: "rs4714055",
      coordinates: { chromosome: "chr6", position: 37325988 },
    },
    {
      fdr: 7.642877603466102e-6,
      trait: "anxiety",
      score: -0.3189993,
      TF_impact: "pos",
      snp: "rs857166",
      coordinates: { chromosome: "chr16", position: 13839037 },
    },
    {
      fdr: 1.8168744632571092,
      trait: "bipolar-2019",
      score: 0.034967422,
      TF_impact: "none",
      snp: "rs2413720",
      coordinates: { chromosome: "chr22", position: 42802253 },
    },
    {
      fdr: 1.1413466539900508,
      trait: "MDD",
      score: 0.0062508583,
      TF_impact: "none",
      snp: "rs10815291",
      coordinates: { chromosome: "chr9", position: 5844824 },
    },
    {
      fdr: 1.7490586972668272,
      trait: "bipolar-2019",
      score: -0.030046463,
      TF_impact: "none",
      snp: "rs7825085",
      coordinates: { chromosome: "chr8", position: 27740783 },
    },
    {
      fdr: 1.0770301812548932,
      trait: "bipolar-I",
      score: -0.1091218,
      TF_impact: "pos",
      snp: "rs8129599",
      coordinates: { chromosome: "chr21", position: 38170889 },
    },
    {
      fdr: 0.0019039057400106361,
      trait: "ADHD-meta-filtered",
      score: -0.2501335,
      TF_impact: "none",
      snp: "rs12534157",
      coordinates: { chromosome: "chr7", position: 155670674 },
    },
    {
      fdr: 3.1593318933055815e-6,
      trait: "anxiety",
      score: -0.3297696,
      TF_impact: "none",
      snp: "rs7101278",
      coordinates: { chromosome: "chr10", position: 95260855 },
    },
    {
      fdr: 0.05570314267513433,
      trait: "MDD",
      score: -0.1928029,
      TF_impact: "none",
      snp: "rs138157115",
      coordinates: { chromosome: "chr12", position: 52041848 },
    },
    {
      fdr: 3.855902696795515e-32,
      trait: "bipolar-2019",
      score: 0.72825,
      TF_impact: "none",
      snp: "rs7394459",
      coordinates: { chromosome: "chr11", position: 61869877 },
    },
    {
      fdr: 1.5255148208574676,
      trait: "ADHD-meta-filtered",
      score: 0.08907032,
      TF_impact: "none",
      snp: "rs7920108",
      coordinates: { chromosome: "chr10", position: 105017323 },
    },
    {
      fdr: 1.7774629407709364,
      trait: "bipolar-I",
      score: -0.032509804,
      TF_impact: "pos",
      snp: "rs11973169",
      coordinates: { chromosome: "chr7", position: 30005798 },
    },
    {
      fdr: 1.4134608719513784,
      trait: "ADHD-meta-filtered",
      score: 0.015506268,
      TF_impact: "none",
      snp: "rs2239339",
      coordinates: { chromosome: "chr16", position: 24218564 },
    },
    {
      fdr: 2.0109965551490084e-5,
      trait: "pgc-panic-disorder",
      score: -0.30821896,
      TF_impact: "none",
      snp: "rs2293271",
      coordinates: { chromosome: "chr2", position: 48560780 },
    },
    {
      fdr: 1.7721872837545496,
      trait: "anxiety",
      score: -0.032190323,
      TF_impact: "none",
      snp: "rs6791614",
      coordinates: { chromosome: "chr3", position: 102038604 },
    },
    {
      fdr: 1.9368523783889897,
      trait: "autism",
      score: -0.06453133,
      TF_impact: "none",
      snp: "rs80587",
      coordinates: { chromosome: "chr22", position: 36202003 },
    },
    {
      fdr: 1.1803639188497783,
      trait: "autism",
      score: 0.007564068,
      TF_impact: "pos",
      snp: "rs2045199",
      coordinates: { chromosome: "chr4", position: 60934467 },
    },
    {
      fdr: 3.2009795901535745e-6,
      trait: "bipolar-2012",
      score: -0.32943726,
      TF_impact: "pos",
      snp: "rs12380490",
      coordinates: { chromosome: "chr9", position: 110426250 },
    },
    {
      fdr: 0.7526469032974448,
      trait: "anorexia",
      score: -0.12403536,
      TF_impact: "none",
      snp: "rs4881291",
      coordinates: { chromosome: "chr10", position: 4108859 },
    },
    {
      fdr: 0.0020298826670835674,
      trait: "cannabis",
      score: 0.24923038,
      TF_impact: "pos",
      snp: "rs2597277",
      coordinates: { chromosome: "chr7", position: 37683810 },
    },
    {
      fdr: 1.1752131658951568,
      trait: "scz",
      score: 0.007334709,
      TF_impact: "pos",
      snp: "rs198820",
      coordinates: { chromosome: "chr6", position: 26124015 },
    },
    {
      fdr: 1.1617087394089303,
      trait: "bipolar-I",
      score: -0.0068793297,
      TF_impact: "none",
      snp: "rs1442104",
      coordinates: { chromosome: "chr4", position: 47210122 },
    },
    {
      fdr: 0.002782166304991942,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.24423838,
      TF_impact: "none",
      snp: "rs7649466",
      coordinates: { chromosome: "chr3", position: 105869001 },
    },
    {
      fdr: 0.0004600729941923169,
      trait: "anxiety",
      score: 0.27019644,
      TF_impact: "none",
      snp: "rs17628613",
      coordinates: { chromosome: "chr4", position: 170014603 },
    },
    {
      fdr: 1.229755976111754,
      trait: "MDD",
      score: -0.009283066,
      TF_impact: "neg",
      snp: "rs12036585",
      coordinates: { chromosome: "chr1", position: 72178433 },
    },
    {
      fdr: 0.007317819111353867,
      trait: "ADHD-meta-filtered",
      score: -0.22980165,
      TF_impact: "none",
      snp: "rs61867294",
      coordinates: { chromosome: "chr10", position: 104809449 },
    },
    {
      fdr: 1.151517408425921,
      trait: "MDD",
      score: -0.006588936,
      TF_impact: "pos",
      snp: "rs78757334",
      coordinates: { chromosome: "chr13", position: 26548505 },
    },
    {
      fdr: 0.00020627188976620722,
      trait: "anxiety",
      score: 0.28093147,
      TF_impact: "none",
      snp: "rs6706053",
      coordinates: { chromosome: "chr2", position: 44290523 },
    },
    {
      fdr: 1.96505966699552,
      trait: "bipolar-2019",
      score: -0.059611797,
      TF_impact: "pos",
      snp: "rs325400",
      coordinates: { chromosome: "chr15", position: 99712600 },
    },
    {
      fdr: 1.8144203703644692,
      trait: "MDD",
      score: -0.034461975,
      TF_impact: "none",
      snp: "rs10493806",
      coordinates: { chromosome: "chr1", position: 88228266 },
    },
    {
      fdr: 0.3137001020858147,
      trait: "bipolar-I",
      score: -0.15252638,
      TF_impact: "none",
      snp: "rs9311270",
      coordinates: { chromosome: "chr3", position: 41352679 },
    },
    {
      fdr: 0.03699901019351295,
      trait: "bipolar-2019",
      score: 0.200346,
      TF_impact: "none",
      snp: "rs7010588",
      coordinates: { chromosome: "chr8", position: 143959608 },
    },
    {
      fdr: 1.4297532066052028,
      trait: "bipolar-II",
      score: -0.016299725,
      TF_impact: "neg",
      snp: "rs9896830",
      coordinates: { chromosome: "chr17", position: 51958032 },
    },
    {
      fdr: 1.595795643888881,
      trait: "bipolar-I",
      score: -0.022513866,
      TF_impact: "pos",
      snp: "rs4859265",
      coordinates: { chromosome: "chr3", position: 183033342 },
    },
    {
      fdr: 1.85924073731049,
      trait: "bipolar-2019",
      score: -0.037710667,
      TF_impact: "none",
      snp: "rs8087493",
      coordinates: { chromosome: "chr18", position: 43159234 },
    },
    {
      fdr: 0.16513847100929652,
      trait: "bipolar-2019",
      score: -0.16860056,
      TF_impact: "none",
      snp: "rs1078773",
      coordinates: { chromosome: "chr10", position: 12514034 },
    },
    {
      fdr: 1.1155711597687288,
      trait: "anorexia",
      score: -0.00519228,
      TF_impact: "pos",
      snp: "rs12802128",
      coordinates: { chromosome: "chr11", position: 74560127 },
    },
    {
      fdr: 1.7150397064371965,
      trait: "anorexia",
      score: -0.07997036,
      TF_impact: "none",
      snp: "rs7761589",
      coordinates: { chromosome: "chr6", position: 30890470 },
    },
    {
      fdr: 3.7566791042664666e-5,
      trait: "MDD",
      score: -0.3013816,
      TF_impact: "pos",
      snp: "rs940310",
      coordinates: { chromosome: "chr18", position: 54388911 },
    },
    {
      fdr: 1.2333379767789636,
      trait: "bipolar-I",
      score: 0.10110569,
      TF_impact: "pos",
      snp: "rs62105751",
      coordinates: { chromosome: "chr19", position: 9847213 },
    },
    {
      fdr: 0.12661827694064268,
      trait: "MDD",
      score: 0.17471457,
      TF_impact: "pos",
      snp: "rs61901226",
      coordinates: { chromosome: "chr11", position: 112671366 },
    },
    {
      fdr: 1.9946766097667266,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.052452564,
      TF_impact: "pos",
      snp: "rs12602556",
      coordinates: { chromosome: "chr17", position: 67830745 },
    },
    {
      fdr: 1.995773027270326,
      trait: "bipolar-I",
      score: -0.050834656,
      TF_impact: "none",
      snp: "rs11242436",
      coordinates: { chromosome: "chr5", position: 138601629 },
    },
    {
      fdr: 0.0555536450168368,
      trait: "MDD",
      score: -0.19292212,
      TF_impact: "neg",
      snp: "rs357524",
      coordinates: { chromosome: "chr9", position: 95426883 },
    },
    {
      fdr: 2.8687703414962605e-12,
      trait: "bipolar-2012",
      score: -0.45181036,
      TF_impact: "none",
      snp: "rs6497007",
      coordinates: { chromosome: "chr15", position: 85333786 },
    },
    {
      fdr: 0.00022852092728656675,
      trait: "anxiety",
      score: 0.27924538,
      TF_impact: "pos",
      snp: "rs8008675",
      coordinates: { chromosome: "chr14", position: 94868081 },
    },
    {
      fdr: 3.149089216989882e-8,
      trait: "pgc-panic-disorder",
      score: 0.3742571,
      TF_impact: "pos",
      snp: "rs2857106",
      coordinates: { chromosome: "chr6", position: 32819793 },
    },
    {
      fdr: 1.9834231211260127,
      trait: "bipolar-I",
      score: 0.049580097,
      TF_impact: "pos",
      snp: "rs9890374",
      coordinates: { chromosome: "chr17", position: 28265336 },
    },
    {
      fdr: 1.576194675381009,
      trait: "anorexia",
      score: 0.021764755,
      TF_impact: "none",
      snp: "rs56386725",
      coordinates: { chromosome: "chr14", position: 64417633 },
    },
    {
      fdr: 1.6004707265368667,
      trait: "autism",
      score: 0.022808552,
      TF_impact: "pos",
      snp: "rs12550057",
      coordinates: { chromosome: "chr8", position: 8704758 },
    },
    {
      fdr: 0.28339145973797497,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.15529537,
      TF_impact: "none",
      snp: "rs17770142",
      coordinates: { chromosome: "chr16", position: 83717918 },
    },
    {
      fdr: 1.2487901263034749,
      trait: "MDD",
      score: 0.010009527,
      TF_impact: "none",
      snp: "rs10868001",
      coordinates: { chromosome: "chr9", position: 83366668 },
    },
    {
      fdr: 1.035284798597216,
      trait: "anxiety",
      score: -0.0015449524,
      TF_impact: "none",
      snp: "rs72802493",
      coordinates: { chromosome: "chr2", position: 46777070 },
    },
    {
      fdr: 1.228899982921669,
      trait: "bipolar-I",
      score: 0.009292603,
      TF_impact: "none",
      snp: "rs1080500",
      coordinates: { chromosome: "chr3", position: 53141001 },
    },
    {
      fdr: 1.1692574207764326,
      trait: "cannabis",
      score: 0.0071282387,
      TF_impact: "none",
      snp: "rs754333",
      coordinates: { chromosome: "chr9", position: 108924616 },
    },
    {
      fdr: 1.8585947931788334,
      trait: "bipolar-I",
      score: 0.03793621,
      TF_impact: "none",
      snp: "rs16865132",
      coordinates: { chromosome: "chr2", position: 6410574 },
    },
    {
      fdr: 1.7210301404630497,
      trait: "bipolar-2019",
      score: 0.028109074,
      TF_impact: "none",
      snp: "rs72769966",
      coordinates: { chromosome: "chr16", position: 9123915 },
    },
    {
      fdr: 0.11975098886854274,
      trait: "ADHD-meta-filtered",
      score: 0.17587328,
      TF_impact: "neg",
      snp: "rs4635202",
      coordinates: { chromosome: "chr13", position: 31044522 },
    },
    {
      fdr: 0.01474318089602351,
      trait: "anorexia",
      score: 0.21718025,
      TF_impact: "none",
      snp: "rs249276",
      coordinates: { chromosome: "chr16", position: 9422827 },
    },
    {
      fdr: 0.002105404565154724,
      trait: "MDD",
      score: -0.24861956,
      TF_impact: "none",
      snp: "rs28695930",
      coordinates: { chromosome: "chr8", position: 9606228 },
    },
    {
      fdr: 1.7292099536529129,
      trait: "MDD",
      score: 0.028386116,
      TF_impact: "none",
      snp: "rs12923469",
      coordinates: { chromosome: "chr16", position: 79128723 },
    },
    {
      fdr: 1.0544775505974538e-14,
      trait: "bipolar-I",
      score: 0.49496746,
      TF_impact: "none",
      snp: "rs9559493",
      coordinates: { chromosome: "chr13", position: 109088749 },
    },
    {
      fdr: 1.2130489161052145,
      trait: "bipolar-2019",
      score: -0.10204697,
      TF_impact: "none",
      snp: "rs17774047",
      coordinates: { chromosome: "chr15", position: 42721028 },
    },
    {
      fdr: 1.1757225787600243,
      trait: "autism",
      score: 0.0073604584,
      TF_impact: "pos",
      snp: "rs73403546",
      coordinates: { chromosome: "chr15", position: 42150625 },
    },
    {
      fdr: 1.7200695314771433,
      trait: "scz",
      score: 0.02795577,
      TF_impact: "none",
      snp: "rs12577525",
      coordinates: { chromosome: "chr11", position: 17238569 },
    },
    {
      fdr: 1.1944819125718262,
      trait: "bipolar-2019",
      score: 0.008128643,
      TF_impact: "none",
      snp: "rs1434984",
      coordinates: { chromosome: "chr2", position: 14641313 },
    },
    {
      fdr: 0.029388116003657837,
      trait: "anorexia",
      score: -0.20510101,
      TF_impact: "pos",
      snp: "rs35808061",
      coordinates: { chromosome: "chr11", position: 57779528 },
    },
    {
      fdr: 1.5388526383488303,
      trait: "ADHD-meta-filtered",
      score: -0.020290375,
      TF_impact: "pos",
      snp: "rs8040226",
      coordinates: { chromosome: "chr15", position: 47384633 },
    },
    {
      fdr: 1.4493027545134223,
      trait: "anxiety",
      score: -0.09244633,
      TF_impact: "none",
      snp: "rs6575469",
      coordinates: { chromosome: "chr14", position: 94868350 },
    },
    {
      fdr: 1.0802999357822431,
      trait: "MDD",
      score: 0.0036273003,
      TF_impact: "none",
      snp: "rs7701817",
      coordinates: { chromosome: "chr5", position: 124650872 },
    },
    {
      fdr: 0.3041207499383492,
      trait: "MDD",
      score: 0.15329027,
      TF_impact: "none",
      snp: "rs74831413",
      coordinates: { chromosome: "chr2", position: 237512878 },
    },
    {
      fdr: 0.007255961590217295,
      trait: "bipolar-I",
      score: -0.22965717,
      TF_impact: "pos",
      snp: "rs16891334",
      coordinates: { chromosome: "chr6", position: 26124075 },
    },
    {
      fdr: 1.3405976558442383,
      trait: "bipolar-2012",
      score: -0.013050556,
      TF_impact: "none",
      snp: "rs11681427",
      coordinates: { chromosome: "chr2", position: 240766810 },
    },
    {
      fdr: 1.1334595139676735,
      trait: "bipolar-I",
      score: -0.005883217,
      TF_impact: "pos",
      snp: "rs77457752",
      coordinates: { chromosome: "chr9", position: 13942941 },
    },
    {
      fdr: 1.9961638736715064,
      trait: "cannabis",
      score: -0.054241657,
      TF_impact: "pos",
      snp: "rs10520034",
      coordinates: { chromosome: "chr5", position: 129410822 },
    },
    {
      fdr: 0.003278309392949428,
      trait: "MDD",
      score: 0.24179935,
      TF_impact: "pos",
      snp: "rs2232015",
      coordinates: { chromosome: "chr1", position: 107056636 },
    },
    {
      fdr: 1.4232540537817258,
      trait: "bipolar-I",
      score: -0.015866756,
      TF_impact: "none",
      snp: "rs55762233",
      coordinates: { chromosome: "chr19", position: 19256510 },
    },
    {
      fdr: 1.7140877323699757,
      trait: "MDD",
      score: -0.07993078,
      TF_impact: "none",
      snp: "rs1618400",
      coordinates: { chromosome: "chr11", position: 115707780 },
    },
    {
      fdr: 0.09241066877602773,
      trait: "bipolar-I",
      score: 0.18170595,
      TF_impact: "none",
      snp: "rs1452387",
      coordinates: { chromosome: "chr15", position: 73856484 },
    },
    {
      fdr: 0.0003987943490940451,
      trait: "autism",
      score: 0.2719469,
      TF_impact: "pos",
      snp: "rs7014953",
      coordinates: { chromosome: "chr8", position: 8310891 },
    },
    {
      fdr: 1.4119441028995805,
      trait: "autism",
      score: -0.015444756,
      TF_impact: "none",
      snp: "rs11165651",
      coordinates: { chromosome: "chr1", position: 96472692 },
    },
    {
      fdr: 0.5086831694493444,
      trait: "bipolar-2019",
      score: 0.13684273,
      TF_impact: "none",
      snp: "rs10173260",
      coordinates: { chromosome: "chr2", position: 209513121 },
    },
    {
      fdr: 1.9484428377711904,
      trait: "anorexia",
      score: 0.062057495,
      TF_impact: "none",
      snp: "rs318198",
      coordinates: { chromosome: "chr16", position: 64207177 },
    },
    {
      fdr: 1.9754936449609106,
      trait: "anxiety",
      score: -0.058392525,
      TF_impact: "none",
      snp: "rs3774117",
      coordinates: { chromosome: "chr3", position: 10857556 },
    },
    {
      fdr: 1.975272197653665,
      trait: "autism",
      score: -0.04909706,
      TF_impact: "none",
      snp: "rs2921074",
      coordinates: { chromosome: "chr8", position: 8503629 },
    },
    {
      fdr: 1.9935828431075828,
      trait: "anorexia",
      score: -0.052997112,
      TF_impact: "none",
      snp: "rs2405104",
      coordinates: { chromosome: "chr1", position: 208527388 },
    },
    {
      fdr: 0.0003483145616640714,
      trait: "bipolar-2019",
      score: -0.27392054,
      TF_impact: "pos",
      snp: "rs28728306",
      coordinates: { chromosome: "chr7", position: 1922178 },
    },
    {
      fdr: 1.4925101053929597,
      trait: "ADHD-meta-filtered",
      score: -0.018500328,
      TF_impact: "pos",
      snp: "rs6811025",
      coordinates: { chromosome: "chr4", position: 34159044 },
    },
    {
      fdr: 1.3168191705570895,
      trait: "anxiety",
      score: -0.012293339,
      TF_impact: "none",
      snp: "rs11125089",
      coordinates: { chromosome: "chr2", position: 46781124 },
    },
    {
      fdr: 0.0007902208424654183,
      trait: "bipolar-2019",
      score: -0.26250458,
      TF_impact: "pos",
      snp: "rs67263685",
      coordinates: { chromosome: "chr16", position: 9135088 },
    },
    {
      fdr: 1.1862756505907919,
      trait: "bipolar-I",
      score: -0.0077462196,
      TF_impact: "none",
      snp: "rs73199533",
      coordinates: { chromosome: "chr12", position: 108175466 },
    },
    {
      fdr: 0.0016096496023387265,
      trait: "anorexia",
      score: 0.25263882,
      TF_impact: "none",
      snp: "rs1524682",
      coordinates: { chromosome: "chr4", position: 57379036 },
    },
    {
      fdr: 1.212552089598225,
      trait: "bipolar-2019",
      score: -0.00869894,
      TF_impact: "none",
      snp: "rs34849561",
      coordinates: { chromosome: "chr13", position: 41716647 },
    },
    {
      fdr: 1.8215137637408272,
      trait: "ADHD",
      score: -0.035035133,
      TF_impact: "none",
      snp: "rs2916197",
      coordinates: { chromosome: "chr1", position: 153355859 },
    },
    {
      fdr: 1.740831099812156,
      trait: "anorexia",
      score: -0.029125929,
      TF_impact: "none",
      snp: "rs7920451",
      coordinates: { chromosome: "chr10", position: 124907807 },
    },
    {
      fdr: 1.5863201425890616,
      trait: "bipolar-I",
      score: 0.022148132,
      TF_impact: "pos",
      snp: "rs11134593",
      coordinates: { chromosome: "chr5", position: 169862631 },
    },
    {
      fdr: 1.9534588095206458,
      trait: "anorexia",
      score: -0.04587078,
      TF_impact: "pos",
      snp: "rs3101018",
      coordinates: { chromosome: "chr6", position: 31738087 },
    },
    {
      fdr: 0.0076102221836976645,
      trait: "autism",
      score: -0.22849512,
      TF_impact: "pos",
      snp: "rs132734",
      coordinates: { chromosome: "chr22", position: 36201831 },
    },
    {
      fdr: 1.9060900820277527,
      trait: "bipolar-I",
      score: -0.06863499,
      TF_impact: "pos",
      snp: "rs6576976",
      coordinates: { chromosome: "chr2", position: 96580930 },
    },
    {
      fdr: 1.6206439738261955,
      trait: "scz",
      score: 0.023408413,
      TF_impact: "none",
      snp: "rs500571",
      coordinates: { chromosome: "chr11", position: 83594499 },
    },
    {
      fdr: 3.913467332327632e-18,
      trait: "bipolar-2019",
      score: 0.5495262,
      TF_impact: "pos",
      snp: "rs596136",
      coordinates: { chromosome: "chr18", position: 11999557 },
    },
    {
      fdr: 1.0631448067533218,
      trait: "pgc-panic-disorder",
      score: 0.002887249,
      TF_impact: "none",
      snp: "rs8042870",
      coordinates: { chromosome: "chr15", position: 96849640 },
    },
    {
      fdr: 9.990430712547754e-8,
      trait: "autism",
      score: 0.36310625,
      TF_impact: "none",
      snp: "rs13264212",
      coordinates: { chromosome: "chr8", position: 11452340 },
    },
    {
      fdr: 1.96281970412439,
      trait: "bipolar-I",
      score: -0.05996132,
      TF_impact: "pos",
      snp: "rs62239507",
      coordinates: { chromosome: "chr22", position: 50539788 },
    },
    {
      fdr: 1.4566840664638498,
      trait: "ADHD-meta-filtered",
      score: -0.0914979,
      TF_impact: "none",
      snp: "rs6771703",
      coordinates: { chromosome: "chr3", position: 126082224 },
    },
    {
      fdr: 1.2441275481759122,
      trait: "bipolar-2019",
      score: -0.009765625,
      TF_impact: "neg",
      snp: "rs4845677",
      coordinates: { chromosome: "chr1", position: 154865574 },
    },
    {
      fdr: 0.00868875544008494,
      trait: "autism",
      score: -0.22626877,
      TF_impact: "none",
      snp: "rs62054435",
      coordinates: { chromosome: "chr17", position: 45747973 },
    },
    {
      fdr: 1.9659643058570178,
      trait: "autism",
      score: 0.04750204,
      TF_impact: "none",
      snp: "rs7644373",
      coordinates: { chromosome: "chr3", position: 153997561 },
    },
    {
      fdr: 0.026790121909945005,
      trait: "bipolar-II",
      score: 0.20677853,
      TF_impact: "pos",
      snp: "rs2766557",
      coordinates: { chromosome: "chr6", position: 35757655 },
    },
    {
      fdr: 1.4032280655706029,
      trait: "bipolar-2019",
      score: -0.015115738,
      TF_impact: "none",
      snp: "rs74779340",
      coordinates: { chromosome: "chr7", position: 24781326 },
    },
    {
      fdr: 1.1091474867779213e-10,
      trait: "bipolar-2019",
      score: 0.42362452,
      TF_impact: "none",
      snp: "rs1469712",
      coordinates: { chromosome: "chr19", position: 19418012 },
    },
    {
      fdr: 6.562894392519336e-6,
      trait: "anorexia",
      score: 0.32128096,
      TF_impact: "none",
      snp: "rs11722498",
      coordinates: { chromosome: "chr4", position: 57234479 },
    },
    {
      fdr: 0.011313878518038374,
      trait: "ADHD-meta-filtered",
      score: 0.2215519,
      TF_impact: "pos",
      snp: "rs7810323",
      coordinates: { chromosome: "chr7", position: 1804158 },
    },
    {
      fdr: 1.4337442493811484e-13,
      trait: "pgc-panic-disorder",
      score: -0.47628784,
      TF_impact: "none",
      snp: "rs6979076",
      coordinates: { chromosome: "chr7", position: 1076611 },
    },
    {
      fdr: 1.1933282335922029,
      trait: "anorexia",
      score: 0.008026123,
      TF_impact: "none",
      snp: "rs2532935",
      coordinates: { chromosome: "chr6", position: 30926796 },
    },
    {
      fdr: 1.924500601407688,
      trait: "autism",
      score: 0.042495728,
      TF_impact: "pos",
      snp: "rs62063295",
      coordinates: { chromosome: "chr17", position: 45967065 },
    },
    {
      fdr: 0.0009292362161742016,
      trait: "pgc-panic-disorder",
      score: -0.26034212,
      TF_impact: "none",
      snp: "rs7203458",
      coordinates: { chromosome: "chr16", position: 73192477 },
    },
    {
      fdr: 1.3661491069629519,
      trait: "bipolar-2019",
      score: 0.013851643,
      TF_impact: "none",
      snp: "rs13000014",
      coordinates: { chromosome: "chr2", position: 98766867 },
    },
    {
      fdr: 0.0004023699376048715,
      trait: "anxiety",
      score: -0.27202272,
      TF_impact: "none",
      snp: "rs6896953",
      coordinates: { chromosome: "chr5", position: 83089284 },
    },
    {
      fdr: 0.0029375195839596796,
      trait: "autism",
      score: 0.24333954,
      TF_impact: "none",
      snp: "rs6431489",
      coordinates: { chromosome: "chr2", position: 236902166 },
    },
    {
      fdr: 1.426615159428696,
      trait: "bipolar-I",
      score: 0.016170025,
      TF_impact: "pos",
      snp: "rs6805895",
      coordinates: { chromosome: "chr3", position: 156010985 },
    },
    {
      fdr: 0.6100596374577056,
      trait: "scz",
      score: -0.13111115,
      TF_impact: "pos",
      snp: "rs9661794",
      coordinates: { chromosome: "chr1", position: 97870773 },
    },
    {
      fdr: 1.888136848334638,
      trait: "anorexia",
      score: -0.03903103,
      TF_impact: "neg",
      snp: "rs12320284",
      coordinates: { chromosome: "chr12", position: 49429144 },
    },
    {
      fdr: 1.0808353828541584,
      trait: "anorexia",
      score: 0.00364542,
      TF_impact: "pos",
      snp: "rs10445919",
      coordinates: { chromosome: "chr2", position: 23012697 },
    },
    {
      fdr: 4.246227722327282e-5,
      trait: "bipolar-2019",
      score: -0.2996664,
      TF_impact: "pos",
      snp: "rs1953723",
      coordinates: { chromosome: "chr14", position: 29761297 },
    },
    {
      fdr: 5.436813289215083e-6,
      trait: "anorexia",
      score: 0.3234458,
      TF_impact: "pos",
      snp: "rs7866620",
      coordinates: { chromosome: "chr9", position: 124802650 },
    },
    {
      fdr: 1.171899146550589,
      trait: "MDD",
      score: -0.00718832,
      TF_impact: "none",
      snp: "rs280299",
      coordinates: { chromosome: "chr6", position: 50656132 },
    },
    {
      fdr: 1.3364962988520864e-5,
      trait: "ADHD-meta-filtered",
      score: 0.31290007,
      TF_impact: "pos",
      snp: "rs28579454",
      coordinates: { chromosome: "chr15", position: 80351159 },
    },
    {
      fdr: 1.6601579541216809,
      trait: "ADHD-meta-filtered",
      score: -0.083058834,
      TF_impact: "none",
      snp: "rs1446335",
      coordinates: { chromosome: "chr15", position: 80412033 },
    },
    {
      fdr: 0.023635864050998306,
      trait: "MDD",
      score: 0.20938492,
      TF_impact: "none",
      snp: "rs11207138",
      coordinates: { chromosome: "chr1", position: 39926485 },
    },
    {
      fdr: 0.08588976915504441,
      trait: "bipolar-I",
      score: -0.18338108,
      TF_impact: "none",
      snp: "rs1459540",
      coordinates: { chromosome: "chr4", position: 117773188 },
    },
    {
      fdr: 1.9990457290597952,
      trait: "bipolar-2012",
      score: 0.055360794,
      TF_impact: "none",
      snp: "rs9376004",
      coordinates: { chromosome: "chr6", position: 134035907 },
    },
    {
      fdr: 0.009653423967628666,
      trait: "bipolar-2019",
      score: 0.22415781,
      TF_impact: "none",
      snp: "rs12191379",
      coordinates: { chromosome: "chr6", position: 124683704 },
    },
    {
      fdr: 9.967814435284635e-9,
      trait: "bipolar-2019",
      score: 0.3845744,
      TF_impact: "pos",
      snp: "rs2667762",
      coordinates: { chromosome: "chr15", position: 77590075 },
    },
    {
      fdr: 0.5985550465060538,
      trait: "MDD",
      score: 0.13157845,
      TF_impact: "none",
      snp: "rs2678273",
      coordinates: { chromosome: "chr2", position: 215619859 },
    },
    {
      fdr: 1.1539195789574916,
      trait: "bipolar-I",
      score: 0.0067210197,
      TF_impact: "none",
      snp: "rs6496774",
      coordinates: { chromosome: "chr15", position: 91227417 },
    },
    {
      fdr: 0.015953290675788375,
      trait: "autism",
      score: -0.21583319,
      TF_impact: "pos",
      snp: "rs12519002",
      coordinates: { chromosome: "chr5", position: 57889847 },
    },
    {
      fdr: 2.5842264445797477e-27,
      trait: "autism",
      score: 0.66812134,
      TF_impact: "pos",
      snp: "rs72828517",
      coordinates: { chromosome: "chr6", position: 19035804 },
    },
    {
      fdr: 1.9385943143701998,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.064692974,
      TF_impact: "pos",
      snp: "rs11097575",
      coordinates: { chromosome: "chr4", position: 97721267 },
    },
    {
      fdr: 1.2269307758880479,
      trait: "autism",
      score: 0.009227753,
      TF_impact: "pos",
      snp: "rs7603584",
      coordinates: { chromosome: "chr2", position: 201440422 },
    },
    {
      fdr: 1.1602572920521057,
      trait: "bipolar-I",
      score: 0.0068831444,
      TF_impact: "none",
      snp: "rs6740035",
      coordinates: { chromosome: "chr2", position: 98960115 },
    },
    {
      fdr: 9.98590173593529e-8,
      trait: "bipolar-I",
      score: -0.362772,
      TF_impact: "none",
      snp: "rs596179",
      coordinates: { chromosome: "chr3", position: 182855345 },
    },
    {
      fdr: 6.72882147422237e-6,
      trait: "autism",
      score: 0.32066488,
      TF_impact: "pos",
      snp: "rs2721811",
      coordinates: { chromosome: "chr7", position: 24709810 },
    },
    {
      fdr: 1.5352949102212858,
      trait: "autism",
      score: -0.088511944,
      TF_impact: "none",
      snp: "rs1908814",
      coordinates: { chromosome: "chr8", position: 11897546 },
    },
    {
      fdr: 0.005000380513112888,
      trait: "bipolar-I",
      score: 0.23567343,
      TF_impact: "none",
      snp: "rs11136345",
      coordinates: { chromosome: "chr8", position: 143985944 },
    },
    {
      fdr: 0.40915763863647325,
      trait: "bipolar-2019",
      score: -0.14350033,
      TF_impact: "none",
      snp: "rs1402030",
      coordinates: { chromosome: "chr17", position: 40068768 },
    },
    {
      fdr: 1.5934907322358858,
      trait: "anxiety",
      score: 0.022424221,
      TF_impact: "pos",
      snp: "rs4771314",
      coordinates: { chromosome: "chr13", position: 98664201 },
    },
    {
      fdr: 1.9953466400824986,
      trait: "anxiety",
      score: 0.05072975,
      TF_impact: "none",
      snp: "rs3927078",
      coordinates: { chromosome: "chr2", position: 46670534 },
    },
    {
      fdr: 1.4868440047344698,
      trait: "anxiety",
      score: -0.018291473,
      TF_impact: "none",
      snp: "rs17662779",
      coordinates: { chromosome: "chr17", position: 34567128 },
    },
    {
      fdr: 1.9727957803425942,
      trait: "anxiety",
      score: 0.04819727,
      TF_impact: "none",
      snp: "rs35840820",
      coordinates: { chromosome: "chr4", position: 81527372 },
    },
    {
      fdr: 1.9308653642536664,
      trait: "autism",
      score: -0.04287672,
      TF_impact: "none",
      snp: "rs2976882",
      coordinates: { chromosome: "chr8", position: 8467350 },
    },
    {
      fdr: 0.008028320342828145,
      trait: "MDD",
      score: -0.22765875,
      TF_impact: "none",
      snp: "rs149280103",
      coordinates: { chromosome: "chr18", position: 51154196 },
    },
    {
      fdr: 0.00968357584934884,
      trait: "MDD",
      score: -0.2242279,
      TF_impact: "pos",
      snp: "rs73738225",
      coordinates: { chromosome: "chr6", position: 27213931 },
    },
    {
      fdr: 0.003582375320695022,
      trait: "bipolar-I",
      score: -0.24044466,
      TF_impact: "pos",
      snp: "rs1480162",
      coordinates: { chromosome: "chr5", position: 147092245 },
    },
    {
      fdr: 1.637260619583585,
      trait: "anorexia",
      score: -0.084187984,
      TF_impact: "none",
      snp: "rs3781445",
      coordinates: { chromosome: "chr10", position: 124999801 },
    },
    {
      fdr: 0.002144173778791829,
      trait: "ADHD-meta-filtered",
      score: -0.24815416,
      TF_impact: "none",
      snp: "rs7922641",
      coordinates: { chromosome: "chr10", position: 113187401 },
    },
    {
      fdr: 1.9936003366007873,
      trait: "anorexia",
      score: -0.054862022,
      TF_impact: "none",
      snp: "rs718947",
      coordinates: { chromosome: "chr10", position: 125050580 },
    },
    {
      fdr: 1.8291181705708315,
      trait: "MDD",
      score: 0.035526276,
      TF_impact: "none",
      snp: "rs11700794",
      coordinates: { chromosome: "chr21", position: 17659083 },
    },
    {
      fdr: 1.8903531034973278,
      trait: "bipolar-2019",
      score: 0.039126873,
      TF_impact: "none",
      snp: "rs2721921",
      coordinates: { chromosome: "chr8", position: 132499596 },
    },
    {
      fdr: 1.0377847904486037,
      trait: "bipolar-2019",
      score: -0.0016613007,
      TF_impact: "neg",
      snp: "rs34044266",
      coordinates: { chromosome: "chr17", position: 75028091 },
    },
    {
      fdr: 0.008342712481582465,
      trait: "cannabis",
      score: 0.22690916,
      TF_impact: "none",
      snp: "rs11188284",
      coordinates: { chromosome: "chr10", position: 95337497 },
    },
    {
      fdr: 0.001365400586920048,
      trait: "bipolar-2019",
      score: -0.25478935,
      TF_impact: "none",
      snp: "rs76664163",
      coordinates: { chromosome: "chr19", position: 46413718 },
    },
    {
      fdr: 1.1907297982974323,
      trait: "MDD",
      score: -0.007901192,
      TF_impact: "neg",
      snp: "rs1107070",
      coordinates: { chromosome: "chr14", position: 103504171 },
    },
    {
      fdr: 1.9686772772383174,
      trait: "MDD",
      score: 0.04790163,
      TF_impact: "pos",
      snp: "rs61943400",
      coordinates: { chromosome: "chr12", position: 118398236 },
    },
    {
      fdr: 4.0337465841008396e-13,
      trait: "MDD",
      score: -0.46841145,
      TF_impact: "pos",
      snp: "rs9958401",
      coordinates: { chromosome: "chr18", position: 61891982 },
    },
    {
      fdr: 1.9975048460543048,
      trait: "bipolar-II",
      score: -0.054199696,
      TF_impact: "none",
      snp: "rs72689945",
      coordinates: { chromosome: "chr8", position: 143146930 },
    },
    {
      fdr: 1.653984066145698,
      trait: "autism",
      score: -0.024847984,
      TF_impact: "none",
      snp: "rs7810588",
      coordinates: { chromosome: "chr7", position: 30182049 },
    },
    {
      fdr: 1.7952421157554357,
      trait: "cannabis",
      score: 0.033364773,
      TF_impact: "none",
      snp: "rs77068358",
      coordinates: { chromosome: "chr17", position: 80796816 },
    },
    {
      fdr: 1.3555597652621993,
      trait: "MDD",
      score: 0.013525963,
      TF_impact: "none",
      snp: "rs2250002",
      coordinates: { chromosome: "chr10", position: 71345340 },
    },
    {
      fdr: 1.9386276140639804,
      trait: "MDD",
      score: -0.06305599,
      TF_impact: "pos",
      snp: "rs1035223",
      coordinates: { chromosome: "chr18", position: 54762511 },
    },
    {
      fdr: 0.008145134251810317,
      trait: "MDD",
      score: 0.22728968,
      TF_impact: "none",
      snp: "rs4608489",
      coordinates: { chromosome: "chr2", position: 217063538 },
    },
    {
      fdr: 0.8784614871835,
      trait: "ADHD-meta-filtered",
      score: -0.11783886,
      TF_impact: "pos",
      snp: "rs1961979",
      coordinates: { chromosome: "chr4", position: 89001286 },
    },
    {
      fdr: 4.146282058939585e-7,
      trait: "cannabis",
      score: 0.34954023,
      TF_impact: "pos",
      snp: "rs36195228",
      coordinates: { chromosome: "chr8", position: 85056113 },
    },
    {
      fdr: 1.2668736474392045e-5,
      trait: "anxiety",
      score: -0.3135929,
      TF_impact: "none",
      snp: "rs4757754",
      coordinates: { chromosome: "chr11", position: 19110654 },
    },
    {
      fdr: 1.0063984285986263,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.000207901,
      TF_impact: "none",
      snp: "rs639218",
      coordinates: { chromosome: "chr17", position: 77273809 },
    },
    {
      fdr: 0.0578923779210437,
      trait: "ADHD-meta-filtered",
      score: 0.19210005,
      TF_impact: "pos",
      snp: "rs7549876",
      coordinates: { chromosome: "chr1", position: 43294565 },
    },
    {
      fdr: 1.034009176883965,
      trait: "cannabis",
      score: -0.0014719963,
      TF_impact: "none",
      snp: "rs1922785",
      coordinates: { chromosome: "chr2", position: 58702543 },
    },
    {
      fdr: 0.009134252265726788,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.22547626,
      TF_impact: "none",
      snp: "rs13242167",
      coordinates: { chromosome: "chr7", position: 37037296 },
    },
    {
      fdr: 1.4499696081654325,
      trait: "MDD",
      score: -0.09195709,
      TF_impact: "none",
      snp: "rs62038334",
      coordinates: { chromosome: "chr16", position: 12168207 },
    },
    {
      fdr: 0.21461607289160398,
      trait: "MDD",
      score: 0.1619525,
      TF_impact: "none",
      snp: "rs6499040",
      coordinates: { chromosome: "chr16", position: 65787054 },
    },
    {
      fdr: 1.7773715164091037,
      trait: "autism",
      score: -0.07664251,
      TF_impact: "neg",
      snp: "rs2349075",
      coordinates: { chromosome: "chr2", position: 201358843 },
    },
    {
      fdr: 1.8820460173634715e-5,
      trait: "bipolar-I",
      score: -0.3090763,
      TF_impact: "pos",
      snp: "rs1427281",
      coordinates: { chromosome: "chr15", position: 58667074 },
    },
    {
      fdr: 0.03319773508525988,
      trait: "MDD",
      score: 0.2027359,
      TF_impact: "pos",
      snp: "rs2403202",
      coordinates: { chromosome: "chr14", position: 103613705 },
    },
    {
      fdr: 1.2636264453480706,
      trait: "cannabis",
      score: 0.09981823,
      TF_impact: "none",
      snp: "rs7793710",
      coordinates: { chromosome: "chr7", position: 73557201 },
    },
    {
      fdr: 1.2625517273351294,
      trait: "MDD",
      score: 0.010477066,
      TF_impact: "none",
      snp: "rs9998816",
      coordinates: { chromosome: "chr4", position: 158914546 },
    },
    {
      fdr: 1.150855532354074,
      trait: "MDD",
      score: -0.10548973,
      TF_impact: "none",
      snp: "rs7776539",
      coordinates: { chromosome: "chr7", position: 3290148 },
    },
    {
      fdr: 0.0021581638183216104,
      trait: "autism",
      score: -0.24789906,
      TF_impact: "pos",
      snp: "rs62064665",
      coordinates: { chromosome: "chr17", position: 46004161 },
    },
    {
      fdr: 0.36484302936036467,
      trait: "cannabis",
      score: -0.14683294,
      TF_impact: "pos",
      snp: "rs28453960",
      coordinates: { chromosome: "chr22", position: 49933522 },
    },
    {
      fdr: 0.9240739777991713,
      trait: "bipolar-2019",
      score: -0.11545038,
      TF_impact: "none",
      snp: "rs3024747",
      coordinates: { chromosome: "chr13", position: 113167473 },
    },
    {
      fdr: 1.0135707496328528,
      trait: "MDD",
      score: 0.00063323975,
      TF_impact: "neg",
      snp: "rs12526735",
      coordinates: { chromosome: "chr6", position: 72939099 },
    },
    {
      fdr: 0.32938882305048006,
      trait: "bipolar-2019",
      score: 0.1507163,
      TF_impact: "pos",
      snp: "rs13428225",
      coordinates: { chromosome: "chr2", position: 185351793 },
    },
    {
      fdr: 0.13820108648050636,
      trait: "autism",
      score: 0.17215776,
      TF_impact: "none",
      snp: "rs3745300",
      coordinates: { chromosome: "chr19", position: 49155618 },
    },
    {
      fdr: 1.5121804383014528,
      trait: "scz",
      score: -0.019396782,
      TF_impact: "none",
      snp: "rs6482674",
      coordinates: { chromosome: "chr10", position: 128390728 },
    },
    {
      fdr: 0.12725692118598098,
      trait: "bipolar-2019",
      score: -0.17453527,
      TF_impact: "neg",
      snp: "rs1013278",
      coordinates: { chromosome: "chr7", position: 117963766 },
    },
    {
      fdr: 1.3636259641742186,
      trait: "bipolar-I",
      score: 0.013784885,
      TF_impact: "none",
      snp: "rs9876378",
      coordinates: { chromosome: "chr3", position: 85022127 },
    },
    {
      fdr: 1.9566073313512247,
      trait: "autism",
      score: 0.046528816,
      TF_impact: "pos",
      snp: "rs12992419",
      coordinates: { chromosome: "chr2", position: 141630618 },
    },
    {
      fdr: 1.307645477025591,
      trait: "anorexia",
      score: -0.011984825,
      TF_impact: "none",
      snp: "rs2074509",
      coordinates: { chromosome: "chr6", position: 30908461 },
    },
    {
      fdr: 1.4015152792691754,
      trait: "cannabis",
      score: 0.015113354,
      TF_impact: "none",
      snp: "rs521354",
      coordinates: { chromosome: "chr1", position: 156123040 },
    },
    {
      fdr: 1.665643593341093,
      trait: "autism",
      score: 0.025395393,
      TF_impact: "none",
      snp: "rs1860071",
      coordinates: { chromosome: "chr7", position: 140210460 },
    },
    {
      fdr: 1.5980124247178797,
      trait: "MDD",
      score: 0.02270317,
      TF_impact: "none",
      snp: "rs2997754",
      coordinates: { chromosome: "chr10", position: 75144379 },
    },
    {
      fdr: 1.2520572828309822,
      trait: "anorexia",
      score: 0.010143995,
      TF_impact: "pos",
      snp: "rs9429742",
      coordinates: { chromosome: "chr1", position: 114380168 },
    },
    {
      fdr: 1.2173845289108858,
      trait: "MDD",
      score: 0.10251665,
      TF_impact: "none",
      snp: "rs298558",
      coordinates: { chromosome: "chr5", position: 17341167 },
    },
    {
      fdr: 3.0067749619378203e-18,
      trait: "bipolar-I",
      score: -0.55226755,
      TF_impact: "pos",
      snp: "rs1214761",
      coordinates: { chromosome: "chr6", position: 43386693 },
    },
    {
      fdr: 1.9141342955856329,
      trait: "anorexia",
      score: 0.041181087,
      TF_impact: "none",
      snp: "rs7831883",
      coordinates: { chromosome: "chr8", position: 26658697 },
    },
    {
      fdr: 0.002152943171489754,
      trait: "MDD",
      score: -0.24821806,
      TF_impact: "none",
      snp: "rs1553597",
      coordinates: { chromosome: "chr18", position: 55833792 },
    },
    {
      fdr: 0.09257220206392328,
      trait: "autism",
      score: 0.1813755,
      TF_impact: "pos",
      snp: "rs10777725",
      coordinates: { chromosome: "chr12", position: 95764126 },
    },
    {
      fdr: 6.820203220794261e-6,
      trait: "bipolar-2019",
      score: 0.3208022,
      TF_impact: "none",
      snp: "rs716191",
      coordinates: { chromosome: "chr4", position: 94995768 },
    },
    {
      fdr: 1.1594373505866051,
      trait: "bipolar-2019",
      score: 0.10453892,
      TF_impact: "neg",
      snp: "rs11986122",
      coordinates: { chromosome: "chr8", position: 10152439 },
    },
    {
      fdr: 1.178091306913432,
      trait: "bipolar-2012",
      score: -0.0074534416,
      TF_impact: "none",
      snp: "rs13012495",
      coordinates: { chromosome: "chr2", position: 98749181 },
    },
    {
      fdr: 1.1900065402606623,
      trait: "anorexia",
      score: 0.10355377,
      TF_impact: "none",
      snp: "rs11814972",
      coordinates: { chromosome: "chr10", position: 98173507 },
    },
    {
      fdr: 0.10992851682324874,
      trait: "bipolar-I",
      score: -0.17799854,
      TF_impact: "none",
      snp: "rs923724",
      coordinates: { chromosome: "chr12", position: 99246152 },
    },
    {
      fdr: 1.9112195317858458,
      trait: "anorexia",
      score: -0.04099512,
      TF_impact: "none",
      snp: "rs7964074",
      coordinates: { chromosome: "chr12", position: 57536591 },
    },
    {
      fdr: 1.607846097686511,
      trait: "MDD",
      score: 0.022991657,
      TF_impact: "pos",
      snp: "rs28541419",
      coordinates: { chromosome: "chr15", position: 88402647 },
    },
    {
      fdr: 1.5688593987563515,
      trait: "anorexia",
      score: -0.02139759,
      TF_impact: "pos",
      snp: "rs2090259",
      coordinates: { chromosome: "chr8", position: 51993877 },
    },
    {
      fdr: 1.9021670838388218,
      trait: "scz",
      score: -0.039723396,
      TF_impact: "none",
      snp: "rs12338960",
      coordinates: { chromosome: "chr9", position: 82128493 },
    },
    {
      fdr: 0.5497379544482007,
      trait: "bipolar-I",
      score: -0.1340456,
      TF_impact: "none",
      snp: "rs7259187",
      coordinates: { chromosome: "chr19", position: 2686647 },
    },
    {
      fdr: 0.8945876932791709,
      trait: "ADHD-meta-filtered",
      score: 0.11719799,
      TF_impact: "pos",
      snp: "rs4078520",
      coordinates: { chromosome: "chr11", position: 723429 },
    },
    {
      fdr: 7.43887894792899e-6,
      trait: "autism",
      score: -0.319448,
      TF_impact: "pos",
      snp: "rs7719215",
      coordinates: { chromosome: "chr5", position: 54601388 },
    },
    {
      fdr: 0.07363846590887158,
      trait: "bipolar-I",
      score: 0.18704987,
      TF_impact: "pos",
      snp: "rs10826321",
      coordinates: { chromosome: "chr10", position: 59580445 },
    },
    {
      fdr: 0.006799286834457739,
      trait: "MDD",
      score: -0.23101616,
      TF_impact: "none",
      snp: "rs4886617",
      coordinates: { chromosome: "chr15", position: 73797452 },
    },
    {
      fdr: 1.8895548787613539,
      trait: "bipolar-2019",
      score: 0.07071924,
      TF_impact: "neg",
      snp: "rs1990565",
      coordinates: { chromosome: "chr19", position: 46289409 },
    },
    {
      fdr: 5.480623938916788e-5,
      trait: "MDD",
      score: 0.29670954,
      TF_impact: "pos",
      snp: "rs28377268",
      coordinates: { chromosome: "chr9", position: 95462774 },
    },
    {
      fdr: 1.3979417835498666,
      trait: "ADHD-meta-filtered",
      score: 0.01495266,
      TF_impact: "none",
      snp: "rs10033356",
      coordinates: { chromosome: "chr4", position: 80037099 },
    },
    {
      fdr: 1.4788622719943705,
      trait: "anorexia",
      score: -0.090856075,
      TF_impact: "none",
      snp: "rs1264307",
      coordinates: { chromosome: "chr6", position: 30912980 },
    },
    {
      fdr: 1.1075480965378937,
      trait: "anorexia",
      score: 0.0048189163,
      TF_impact: "neg",
      snp: "rs28544076",
      coordinates: { chromosome: "chr1", position: 241950596 },
    },
    {
      fdr: 2.852314022913247e-15,
      trait: "bipolar-2019",
      score: 0.50474215,
      TF_impact: "pos",
      snp: "rs58988283",
      coordinates: { chromosome: "chr2", position: 209261433 },
    },
    {
      fdr: 1.5354678291878787,
      trait: "MDD",
      score: 0.020229816,
      TF_impact: "none",
      snp: "rs376358",
      coordinates: { chromosome: "chr2", position: 104786677 },
    },
    {
      fdr: 1.7919419204589433,
      trait: "autism",
      score: 0.07528496,
      TF_impact: "pos",
      snp: "rs413778",
      coordinates: { chromosome: "chr17", position: 45639519 },
    },
    {
      fdr: 1.6510624312405433,
      trait: "bipolar-I",
      score: 0.02475357,
      TF_impact: "pos",
      snp: "rs11717383",
      coordinates: { chromosome: "chr3", position: 52253452 },
    },
    {
      fdr: 6.716917564838084e-10,
      trait: "pgc-panic-disorder",
      score: -0.40806627,
      TF_impact: "none",
      snp: "rs3949492",
      coordinates: { chromosome: "chr20", position: 4040488 },
    },
    {
      fdr: 0.14981272615775953,
      trait: "bipolar-2019",
      score: 0.17057896,
      TF_impact: "none",
      snp: "rs12754658",
      coordinates: { chromosome: "chr1", position: 59072800 },
    },
    {
      fdr: 1.9135744366937308,
      trait: "bipolar-2019",
      score: -0.04112816,
      TF_impact: "neg",
      snp: "rs3817030",
      coordinates: { chromosome: "chr16", position: 69316132 },
    },
    {
      fdr: 0.40687983797421806,
      trait: "MDD",
      score: 0.14394426,
      TF_impact: "none",
      snp: "rs359708",
      coordinates: { chromosome: "chr2", position: 126370357 },
    },
    {
      fdr: 1.343268559232397,
      trait: "ADHD-meta-filtered",
      score: 0.096419334,
      TF_impact: "pos",
      snp: "rs9560010",
      coordinates: { chromosome: "chr13", position: 111214173 },
    },
    {
      fdr: 0.05817235498157324,
      trait: "anorexia",
      score: 0.19194698,
      TF_impact: "none",
      snp: "rs3818524",
      coordinates: { chromosome: "chr6", position: 33692918 },
    },
    {
      fdr: 0.45829420812631033,
      trait: "autism",
      score: 0.13982916,
      TF_impact: "none",
      snp: "rs6928669",
      coordinates: { chromosome: "chr6", position: 23689021 },
    },
    {
      fdr: 1.527488017566429,
      trait: "opioid-unexposed-vs-exposed",
      score: 0.019934654,
      TF_impact: "none",
      snp: "rs1715491",
      coordinates: { chromosome: "chr17", position: 77282742 },
    },
    {
      fdr: 1.52071119038822,
      trait: "anorexia",
      score: -0.019691944,
      TF_impact: "pos",
      snp: "rs824202",
      coordinates: { chromosome: "chr15", position: 23764434 },
    },
    {
      fdr: 1.9905736341606193,
      trait: "ADHD-meta-filtered",
      score: 0.05211258,
      TF_impact: "none",
      snp: "rs62206557",
      coordinates: { chromosome: "chr20", position: 37180220 },
    },
    {
      fdr: 0.7822530995023764,
      trait: "ADHD-meta-filtered",
      score: 0.122578144,
      TF_impact: "none",
      snp: "rs1249582",
      coordinates: { chromosome: "chr11", position: 68998427 },
    },
    {
      fdr: 0.00022457830726616766,
      trait: "MDD",
      score: 0.27959013,
      TF_impact: "pos",
      snp: "rs13248317",
      coordinates: { chromosome: "chr8", position: 20423431 },
    },
    {
      fdr: 1.1246567045719968,
      trait: "bipolar-2019",
      score: -0.0055294037,
      TF_impact: "none",
      snp: "rs10263430",
      coordinates: { chromosome: "chr7", position: 29975830 },
    },
    {
      fdr: 0.042516473578658884,
      trait: "bipolar-2019",
      score: -0.19776535,
      TF_impact: "pos",
      snp: "rs6426841",
      coordinates: { chromosome: "chr1", position: 163820413 },
    },
    {
      fdr: 1.2372758411628093,
      trait: "bipolar-II",
      score: 0.0095191,
      TF_impact: "none",
      snp: "rs77570544",
      coordinates: { chromosome: "chr4", position: 111363088 },
    },
    {
      fdr: 1.10641163582079,
      trait: "cannabis",
      score: -0.107658386,
      TF_impact: "pos",
      snp: "rs1348716",
      coordinates: { chromosome: "chr5", position: 61451158 },
    },
    {
      fdr: 1.7060836827604535,
      trait: "ADHD-meta-filtered",
      score: 0.0270257,
      TF_impact: "pos",
      snp: "rs7664633",
      coordinates: { chromosome: "chr4", position: 175807763 },
    },
    {
      fdr: 4.879776770294316e-6,
      trait: "bipolar-2019",
      score: 0.32481003,
      TF_impact: "pos",
      snp: "rs10414516",
      coordinates: { chromosome: "chr19", position: 2019759 },
    },
    {
      fdr: 1.3174248131325448,
      trait: "ADHD-meta-filtered",
      score: 0.012341261,
      TF_impact: "pos",
      snp: "rs12483621",
      coordinates: { chromosome: "chr21", position: 36450798 },
    },
    {
      fdr: 0.006808793642001723,
      trait: "MDD",
      score: -0.2311039,
      TF_impact: "none",
      snp: "rs11853271",
      coordinates: { chromosome: "chr15", position: 90482598 },
    },
    {
      fdr: 1.9622248339931927,
      trait: "bipolar-I",
      score: 0.06036997,
      TF_impact: "pos",
      snp: "rs184301136",
      coordinates: { chromosome: "chr12", position: 57591140 },
    },
    {
      fdr: 1.852399928409253,
      trait: "autism",
      score: 0.03709507,
      TF_impact: "none",
      snp: "rs4841541",
      coordinates: { chromosome: "chr8", position: 11454320 },
    },
    {
      fdr: 1.8457445853004475,
      trait: "autism",
      score: 0.03642273,
      TF_impact: "none",
      snp: "rs74466898",
      coordinates: { chromosome: "chr18", position: 58192707 },
    },
    {
      fdr: 0.0002038244324517728,
      trait: "anorexia",
      score: -0.28081512,
      TF_impact: "none",
      snp: "rs3804771",
      coordinates: { chromosome: "chr3", position: 141972565 },
    },
    {
      fdr: 0.001751148841648477,
      trait: "MDD",
      score: 0.251338,
      TF_impact: "pos",
      snp: "rs12892062",
      coordinates: { chromosome: "chr14", position: 103417175 },
    },
    {
      fdr: 1.4056810233242818,
      trait: "bipolar-2019",
      score: 0.0152692795,
      TF_impact: "pos",
      snp: "rs6080766",
      coordinates: { chromosome: "chr20", position: 17662462 },
    },
    {
      fdr: 1.885637244103009,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.03900671,
      TF_impact: "neg",
      snp: "rs1966072",
      coordinates: { chromosome: "chr8", position: 104324084 },
    },
    {
      fdr: 1.9319245858393055,
      trait: "MDD",
      score: -0.06547451,
      TF_impact: "pos",
      snp: "rs9564313",
      coordinates: { chromosome: "chr13", position: 66349555 },
    },
    {
      fdr: 0.001100214808150548,
      trait: "cannabis",
      score: 0.25811005,
      TF_impact: "pos",
      snp: "rs3903344",
      coordinates: { chromosome: "chr8", position: 85032135 },
    },
    {
      fdr: 0.6218329524045618,
      trait: "MDD",
      score: -0.13062811,
      TF_impact: "pos",
      snp: "rs1406713",
      coordinates: { chromosome: "chr15", position: 92918033 },
    },
    {
      fdr: 0.03332140314486409,
      trait: "autism",
      score: 0.20263815,
      TF_impact: "pos",
      snp: "rs73129824",
      coordinates: { chromosome: "chr20", position: 21554779 },
    },
    {
      fdr: 1.975509599731686,
      trait: "bipolar-2019",
      score: -0.048532486,
      TF_impact: "none",
      snp: "rs2673582",
      coordinates: { chromosome: "chr8", position: 132507817 },
    },
    {
      fdr: 1.9927749565117572,
      trait: "bipolar-I",
      score: -0.051404953,
      TF_impact: "pos",
      snp: "rs167849",
      coordinates: { chromosome: "chr1", position: 96389200 },
    },
    {
      fdr: 2.8844608932935646e-12,
      trait: "autism",
      score: -0.45227003,
      TF_impact: "none",
      snp: "rs66691214",
      coordinates: { chromosome: "chr7", position: 30149188 },
    },
    {
      fdr: 1.1313307902584653,
      trait: "MDD",
      score: -0.10662413,
      TF_impact: "pos",
      snp: "rs11609859",
      coordinates: { chromosome: "chr12", position: 124804115 },
    },
    {
      fdr: 4.79032731929237e-6,
      trait: "bipolar-I",
      score: -0.32506323,
      TF_impact: "pos",
      snp: "rs4964649",
      coordinates: { chromosome: "chr12", position: 108175527 },
    },
    {
      fdr: 0.0006240609676414071,
      trait: "bipolar-2019",
      score: 0.26570034,
      TF_impact: "none",
      snp: "rs11640354",
      coordinates: { chromosome: "chr16", position: 12363065 },
    },
    {
      fdr: 0.005486859075258123,
      trait: "cannabis",
      score: -0.23434639,
      TF_impact: "none",
      snp: "rs611005",
      coordinates: { chromosome: "chr18", position: 35078630 },
    },
    {
      fdr: 0.00229124848290552,
      trait: "bipolar-I",
      score: 0.24710512,
      TF_impact: "neg",
      snp: "rs6065778",
      coordinates: { chromosome: "chr20", position: 45059511 },
    },
    {
      fdr: 1.68610730512209,
      trait: "autism",
      score: 0.026104927,
      TF_impact: "pos",
      snp: "rs12547721",
      coordinates: { chromosome: "chr8", position: 8713161 },
    },
    {
      fdr: 1.271374263481292,
      trait: "anxiety",
      score: 0.01078558,
      TF_impact: "none",
      snp: "rs72817564",
      coordinates: { chromosome: "chr5", position: 165151668 },
    },
    {
      fdr: 6.35482083724986e-7,
      trait: "ADHD-meta-filtered",
      score: 0.3454604,
      TF_impact: "none",
      snp: "rs5745568",
      coordinates: { chromosome: "chr6", position: 33580617 },
    },
    {
      fdr: 2.6166779604180303e-9,
      trait: "MDD",
      score: -0.3959322,
      TF_impact: "pos",
      snp: "rs9462535",
      coordinates: { chromosome: "chr6", position: 39348016 },
    },
    {
      fdr: 1.919903647468401,
      trait: "anxiety",
      score: -0.06642723,
      TF_impact: "none",
      snp: "rs7795555",
      coordinates: { chromosome: "chr7", position: 154104933 },
    },
    {
      fdr: 1.9505996616578474,
      trait: "MDD",
      score: -0.04542446,
      TF_impact: "neg",
      snp: "rs4907311",
      coordinates: { chromosome: "chr2", position: 96345624 },
    },
    {
      fdr: 0.09486158610290678,
      trait: "autism",
      score: 0.18093014,
      TF_impact: "none",
      snp: "rs62057151",
      coordinates: { chromosome: "chr17", position: 45826476 },
    },
    {
      fdr: 1.207313497631739,
      trait: "bipolar-2019",
      score: -0.008505821,
      TF_impact: "none",
      snp: "rs56303180",
      coordinates: { chromosome: "chr14", position: 99253536 },
    },
    {
      fdr: 5.656255804297607e-13,
      trait: "bipolar-I",
      score: 0.46521807,
      TF_impact: "none",
      snp: "rs2286940",
      coordinates: { chromosome: "chr3", position: 37028615 },
    },
    {
      fdr: 1.231771467195404,
      trait: "autism",
      score: 0.10125637,
      TF_impact: "pos",
      snp: "rs11685464",
      coordinates: { chromosome: "chr2", position: 236875967 },
    },
    {
      fdr: 0.0001738669802850057,
      trait: "anxiety",
      score: -0.28353596,
      TF_impact: "none",
      snp: "rs66477321",
      coordinates: { chromosome: "chr17", position: 33523806 },
    },
    {
      fdr: 1.1047198827035618,
      trait: "cannabis",
      score: 0.0046458244,
      TF_impact: "none",
      snp: "rs66791174",
      coordinates: { chromosome: "chr3", position: 100517049 },
    },
    {
      fdr: 3.756767036593826e-10,
      trait: "bipolar-I",
      score: -0.41349936,
      TF_impact: "none",
      snp: "rs11990215",
      coordinates: { chromosome: "chr8", position: 143995983 },
    },
    {
      fdr: 0.0004924513870236982,
      trait: "ADHD-meta-filtered",
      score: -0.26902342,
      TF_impact: "none",
      snp: "rs2236947",
      coordinates: { chromosome: "chr3", position: 50334001 },
    },
    {
      fdr: 4.5955892457127774e-5,
      trait: "autism",
      score: 0.2987666,
      TF_impact: "none",
      snp: "rs17153498",
      coordinates: { chromosome: "chr8", position: 11589446 },
    },
    {
      fdr: 0.0029094642109506485,
      trait: "anorexia",
      score: 0.2435751,
      TF_impact: "pos",
      snp: "rs3934393",
      coordinates: { chromosome: "chr6", position: 46410524 },
    },
    {
      fdr: 1.7133808758105282,
      trait: "anorexia",
      score: 0.08041811,
      TF_impact: "pos",
      snp: "rs3227",
      coordinates: { chromosome: "chr6", position: 33694518 },
    },
    {
      fdr: 0.3153469219948272,
      trait: "bipolar-2019",
      score: -0.1520834,
      TF_impact: "none",
      snp: "rs56293342",
      coordinates: { chromosome: "chr2", position: 161427107 },
    },
    {
      fdr: 0.0012195993922310174,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.25640297,
      TF_impact: "pos",
      snp: "rs6747398",
      coordinates: { chromosome: "chr2", position: 64922741 },
    },
    {
      fdr: 8.881449365136386e-10,
      trait: "MDD",
      score: -0.40544367,
      TF_impact: "none",
      snp: "rs146299656",
      coordinates: { chromosome: "chr9", position: 4555436 },
    },
    {
      fdr: 0.0013173682262497194,
      trait: "autism",
      score: -0.25529575,
      TF_impact: "pos",
      snp: "rs56101454",
      coordinates: { chromosome: "chr6", position: 29685936 },
    },
    {
      fdr: 1.8304770222828841,
      trait: "bipolar-I",
      score: 0.03572178,
      TF_impact: "pos",
      snp: "rs7656375",
      coordinates: { chromosome: "chr4", position: 142840715 },
    },
    {
      fdr: 1.9992867693212975,
      trait: "bipolar-2019",
      score: -0.05576372,
      TF_impact: "none",
      snp: "rs2043332",
      coordinates: { chromosome: "chr19", position: 10780563 },
    },
    {
      fdr: 0.0037359856366085274,
      trait: "bipolar-I",
      score: -0.23983812,
      TF_impact: "pos",
      snp: "rs58231871",
      coordinates: { chromosome: "chr7", position: 27604074 },
    },
    {
      fdr: 0.00024556787669239183,
      trait: "cannabis",
      score: 0.27813387,
      TF_impact: "pos",
      snp: "rs4647102",
      coordinates: { chromosome: "chr5", position: 60903536 },
    },
    {
      fdr: 1.0521180254786495,
      trait: "MDD",
      score: -0.0023126602,
      TF_impact: "none",
      snp: "rs113670431",
      coordinates: { chromosome: "chr4", position: 47270605 },
    },
    {
      fdr: 1.9935292884578826,
      trait: "MDD",
      score: -0.052253723,
      TF_impact: "none",
      snp: "rs12694082",
      coordinates: { chromosome: "chr2", position: 207252032 },
    },
    {
      fdr: 8.978277361731359e-13,
      trait: "autism",
      score: 0.4611888,
      TF_impact: "pos",
      snp: "rs1497027",
      coordinates: { chromosome: "chr9", position: 73548438 },
    },
    {
      fdr: 1.2912924466197533,
      trait: "cannabis",
      score: 0.011432171,
      TF_impact: "none",
      snp: "rs41483748",
      coordinates: { chromosome: "chr2", position: 40258384 },
    },
    {
      fdr: 1.236812214568836,
      trait: "anorexia",
      score: -0.10085678,
      TF_impact: "none",
      snp: "rs9830600",
      coordinates: { chromosome: "chr3", position: 94414768 },
    },
    {
      fdr: 1.882023684024635e-9,
      trait: "autism",
      score: 0.39889812,
      TF_impact: "none",
      snp: "rs3989103",
      coordinates: { chromosome: "chr8", position: 11449337 },
    },
    {
      fdr: 0.0032307063794556534,
      trait: "cannabis",
      score: -0.24201727,
      TF_impact: "none",
      snp: "rs17509575",
      coordinates: { chromosome: "chr2", position: 117889976 },
    },
    {
      fdr: 0.007322803943939664,
      trait: "bipolar-2019",
      score: 0.22930384,
      TF_impact: "none",
      snp: "rs34001546",
      coordinates: { chromosome: "chr1", position: 150487239 },
    },
    {
      fdr: 1.8033907714898965,
      trait: "anorexia",
      score: -0.034018517,
      TF_impact: "pos",
      snp: "rs10400567",
      coordinates: { chromosome: "chr12", position: 49331937 },
    },
    {
      fdr: 1.5533111157718222,
      trait: "bipolar-2019",
      score: 0.020915985,
      TF_impact: "pos",
      snp: "rs13157748",
      coordinates: { chromosome: "chr5", position: 59831832 },
    },
    {
      fdr: 1.9719024634172426,
      trait: "bipolar-I",
      score: 0.057658195,
      TF_impact: "none",
      snp: "rs2836762",
      coordinates: { chromosome: "chr21", position: 38931202 },
    },
    {
      fdr: 1.2279417286652798,
      trait: "MDD",
      score: 0.009279251,
      TF_impact: "none",
      snp: "rs7923465",
      coordinates: { chromosome: "chr10", position: 12719685 },
    },
    {
      fdr: 0.0006990590448943749,
      trait: "cannabis",
      score: 0.26407814,
      TF_impact: "pos",
      snp: "rs10767439",
      coordinates: { chromosome: "chr11", position: 25734803 },
    },
    {
      fdr: 1.9777212294214244,
      trait: "cannabis",
      score: 0.049190044,
      TF_impact: "none",
      snp: "rs7807955",
      coordinates: { chromosome: "chr7", position: 37475854 },
    },
    {
      fdr: 1.0656045130064191,
      trait: "bipolar-2019",
      score: 0.0029945374,
      TF_impact: "none",
      snp: "rs1007537",
      coordinates: { chromosome: "chr15", position: 77620375 },
    },
    {
      fdr: 7.233152195560567e-20,
      trait: "bipolar-2019",
      score: -0.57634974,
      TF_impact: "none",
      snp: "rs6751612",
      coordinates: { chromosome: "chr2", position: 209425702 },
    },
    {
      fdr: 1.1926588067753288,
      trait: "bipolar-I",
      score: 0.10344982,
      TF_impact: "pos",
      snp: "rs576",
      coordinates: { chromosome: "chr3", position: 69967316 },
    },
    {
      fdr: 1.0092968624981309,
      trait: "bipolar-I",
      score: -0.00039482117,
      TF_impact: "none",
      snp: "rs11242430",
      coordinates: { chromosome: "chr5", position: 138487177 },
    },
    {
      fdr: 0.6630255375518204,
      trait: "anorexia",
      score: -0.12838888,
      TF_impact: "none",
      snp: "rs6668345",
      coordinates: { chromosome: "chr1", position: 71565599 },
    },
    {
      fdr: 1.063598767881144,
      trait: "anxiety",
      score: -0.10986757,
      TF_impact: "none",
      snp: "rs2600073",
      coordinates: { chromosome: "chr3", position: 10804259 },
    },
    {
      fdr: 0.0011798131055959775,
      trait: "ADHD-meta-filtered",
      score: 0.25706053,
      TF_impact: "none",
      snp: "rs10950400",
      coordinates: { chromosome: "chr7", position: 1842834 },
    },
    {
      fdr: 7.50311870083605e-8,
      trait: "bipolar-2019",
      score: 0.36608315,
      TF_impact: "pos",
      snp: "rs35138622",
      coordinates: { chromosome: "chr19", position: 58401421 },
    },
    {
      fdr: 1.8203140084107627,
      trait: "anxiety",
      score: -0.07352018,
      TF_impact: "pos",
      snp: "rs12917636",
      coordinates: { chromosome: "chr16", position: 55036277 },
    },
    {
      fdr: 1.0518053596154446,
      trait: "bipolar-I",
      score: -0.0023040771,
      TF_impact: "none",
      snp: "rs7570730",
      coordinates: { chromosome: "chr2", position: 27799808 },
    },
    {
      fdr: 8.56788859557106e-14,
      trait: "autism",
      score: 0.48034954,
      TF_impact: "pos",
      snp: "rs327287",
      coordinates: { chromosome: "chr5", position: 38642450 },
    },
    {
      fdr: 0.08253135930045614,
      trait: "pgc-panic-disorder",
      score: 0.18449068,
      TF_impact: "pos",
      snp: "rs1865093",
      coordinates: { chromosome: "chr19", position: 39445494 },
    },
    {
      fdr: 1.4888382190901417,
      trait: "anorexia",
      score: 0.01838231,
      TF_impact: "none",
      snp: "rs67060340",
      coordinates: { chromosome: "chr3", position: 48877888 },
    },
    {
      fdr: 1.72852334518085,
      trait: "MDD",
      score: -0.028313637,
      TF_impact: "pos",
      snp: "rs71546165",
      coordinates: { chromosome: "chr7", position: 54327009 },
    },
    {
      fdr: 1.856094328585668,
      trait: "bipolar-2019",
      score: -0.037258625,
      TF_impact: "none",
      snp: "rs941520",
      coordinates: { chromosome: "chr14", position: 99243365 },
    },
    {
      fdr: 1.8459681756539636,
      trait: "ADHD-meta-filtered",
      score: -0.07244539,
      TF_impact: "none",
      snp: "rs2498442",
      coordinates: { chromosome: "chr6", position: 98045334 },
    },
    {
      fdr: 0.3449857793860054,
      trait: "ADHD-meta-filtered",
      score: 0.1484847,
      TF_impact: "pos",
      snp: "rs4690138",
      coordinates: { chromosome: "chr4", position: 80017552 },
    },
    {
      fdr: 0.00034585474102192875,
      trait: "MDD",
      score: -0.27386189,
      TF_impact: "none",
      snp: "rs9920038",
      coordinates: { chromosome: "chr15", position: 66415523 },
    },
    {
      fdr: 3.1801434957334267e-8,
      trait: "ADHD-meta-filtered",
      score: -0.37391806,
      TF_impact: "pos",
      snp: "rs718571",
      coordinates: { chromosome: "chr18", position: 42642213 },
    },
    {
      fdr: 0.5461753171661884,
      trait: "ADHD-meta-filtered",
      score: 0.13433981,
      TF_impact: "none",
      snp: "rs17254786",
      coordinates: { chromosome: "chr3", position: 43247135 },
    },
    {
      fdr: 0.0017308248554628123,
      trait: "anorexia",
      score: -0.2515769,
      TF_impact: "pos",
      snp: "rs7796558",
      coordinates: { chromosome: "chr7", position: 104089931 },
    },
    {
      fdr: 1.8858777066987955,
      trait: "MDD",
      score: -0.07077694,
      TF_impact: "neg",
      snp: "rs77328845",
      coordinates: { chromosome: "chr10", position: 107299926 },
    },
    {
      fdr: 1.3008093645980383,
      trait: "anxiety",
      score: 0.09832001,
      TF_impact: "none",
      snp: "rs61141806",
      coordinates: { chromosome: "chr4", position: 169900557 },
    },
    {
      fdr: 1.3023041167876215,
      trait: "ADHD-meta-filtered",
      score: 0.0118227005,
      TF_impact: "pos",
      snp: "rs57191490",
      coordinates: { chromosome: "chr12", position: 49671106 },
    },
    {
      fdr: 0.3248578446690743,
      trait: "bipolar-2012",
      score: 0.15114212,
      TF_impact: "none",
      snp: "rs940455",
      coordinates: { chromosome: "chr7", position: 146966476 },
    },
    {
      fdr: 1.6294092913218972,
      trait: "bipolar-2019",
      score: 0.08499384,
      TF_impact: "none",
      snp: "rs329304",
      coordinates: { chromosome: "chr5", position: 134562139 },
    },
    {
      fdr: 1.08511320867762,
      trait: "bipolar-I",
      score: -0.00379467,
      TF_impact: "pos",
      snp: "rs138322482",
      coordinates: { chromosome: "chr8", position: 26240376 },
    },
    {
      fdr: 8.178017004836571e-18,
      trait: "MDD",
      score: -0.542809,
      TF_impact: "pos",
      snp: "rs7269614",
      coordinates: { chromosome: "chr20", position: 47205863 },
    },
    {
      fdr: 1.2293193235903261,
      trait: "bipolar-I",
      score: -0.009267807,
      TF_impact: "pos",
      snp: "rs17177789",
      coordinates: { chromosome: "chr14", position: 63212991 },
    },
    {
      fdr: 0.007344604214068854,
      trait: "anorexia",
      score: -0.2291336,
      TF_impact: "none",
      snp: "rs2803340",
      coordinates: { chromosome: "chr1", position: 1946632 },
    },
    {
      fdr: 1.8086478597921195e-16,
      trait: "autism",
      score: 0.52268934,
      TF_impact: "pos",
      snp: "rs16875447",
      coordinates: { chromosome: "chr5", position: 78472665 },
    },
    {
      fdr: 6.677936541102455e-10,
      trait: "bipolar-I",
      score: 0.4085436,
      TF_impact: "none",
      snp: "rs76369658",
      coordinates: { chromosome: "chr11", position: 134398216 },
    },
    {
      fdr: 0.0006354852959969353,
      trait: "bipolar-2019",
      score: 0.2653532,
      TF_impact: "none",
      snp: "rs10931887",
      coordinates: { chromosome: "chr2", position: 200104182 },
    },
    {
      fdr: 1.1796602142717874,
      trait: "anxiety",
      score: -0.007508278,
      TF_impact: "none",
      snp: "rs2706240",
      coordinates: { chromosome: "chr13", position: 50682866 },
    },
    {
      fdr: 1.9939012384398735,
      trait: "ADHD-meta-filtered",
      score: 0.053827286,
      TF_impact: "pos",
      snp: "rs727257",
      coordinates: { chromosome: "chr14", position: 91058332 },
    },
    {
      fdr: 1.6819318815078477,
      trait: "bipolar-I",
      score: -0.025898457,
      TF_impact: "neg",
      snp: "rs2239017",
      coordinates: { chromosome: "chr12", position: 2180485 },
    },
    {
      fdr: 1.931340745819462,
      trait: "bipolar-2019",
      score: -0.065261364,
      TF_impact: "pos",
      snp: "rs9468220",
      coordinates: { chromosome: "chr6", position: 27765197 },
    },
    {
      fdr: 0.4270454364495155,
      trait: "bipolar-2019",
      score: -0.1423912,
      TF_impact: "neg",
      snp: "rs267003",
      coordinates: { chromosome: "chr5", position: 155451382 },
    },
    {
      fdr: 9.116217320220886e-6,
      trait: "MDD",
      score: 0.31715488,
      TF_impact: "pos",
      snp: "rs309452",
      coordinates: { chromosome: "chr9", position: 37433962 },
    },
    {
      fdr: 0.4271599734132919,
      trait: "bipolar-I",
      score: 0.14245987,
      TF_impact: "pos",
      snp: "rs12575395",
      coordinates: { chromosome: "chr11", position: 111301339 },
    },
    {
      fdr: 1.9335810867653755,
      trait: "autism",
      score: 0.064463615,
      TF_impact: "pos",
      snp: "rs75732533",
      coordinates: { chromosome: "chr7", position: 158868921 },
    },
    {
      fdr: 0.006812852691080435,
      trait: "autism",
      score: -0.23125744,
      TF_impact: "pos",
      snp: "rs62064664",
      coordinates: { chromosome: "chr17", position: 46004096 },
    },
    {
      fdr: 1.1831719429377783,
      trait: "bipolar-2012",
      score: 0.0077085495,
      TF_impact: "none",
      snp: "rs17149512",
      coordinates: { chromosome: "chr7", position: 87398843 },
    },
    {
      fdr: 1.3292943477790383e-8,
      trait: "cannabis",
      score: -0.38187122,
      TF_impact: "pos",
      snp: "rs12237222",
      coordinates: { chromosome: "chr9", position: 83219828 },
    },
    {
      fdr: 1.7329499486144926,
      trait: "bipolar-2019",
      score: 0.07860184,
      TF_impact: "pos",
      snp: "rs5758065",
      coordinates: { chromosome: "chr22", position: 40765045 },
    },
    {
      fdr: 1.997883390621756,
      trait: "bipolar-2019",
      score: -0.056001663,
      TF_impact: "neg",
      snp: "rs12724450",
      coordinates: { chromosome: "chr1", position: 150508095 },
    },
    {
      fdr: 0.7378609968761193,
      trait: "ADHD-meta-filtered",
      score: -0.12499714,
      TF_impact: "none",
      snp: "rs463334",
      coordinates: { chromosome: "chr3", position: 13070723 },
    },
    {
      fdr: 0.002269624250224655,
      trait: "bipolar-2019",
      score: -0.24697256,
      TF_impact: "none",
      snp: "rs2005983",
      coordinates: { chromosome: "chr22", position: 43036313 },
    },
    {
      fdr: 1.6835037272930493,
      trait: "bipolar-2019",
      score: 0.025986671,
      TF_impact: "none",
      snp: "rs10819162",
      coordinates: { chromosome: "chr9", position: 126463259 },
    },
    {
      fdr: 1.9953323737837136,
      trait: "autism",
      score: -0.050686836,
      TF_impact: "none",
      snp: "rs7866443",
      coordinates: { chromosome: "chr9", position: 116417597 },
    },
    {
      fdr: 0.0011706221250278243,
      trait: "MDD",
      score: 0.25697136,
      TF_impact: "none",
      snp: "rs10067299",
      coordinates: { chromosome: "chr5", position: 124660916 },
    },
    {
      fdr: 1.5511820165537775,
      trait: "MDD",
      score: -0.020690441,
      TF_impact: "none",
      snp: "rs4598945",
      coordinates: { chromosome: "chr17", position: 65433859 },
    },
    {
      fdr: 0.009775494640984761,
      trait: "ADHD-meta-filtered",
      score: 0.22431564,
      TF_impact: "none",
      snp: "rs10447760",
      coordinates: { chromosome: "chr7", position: 114083210 },
    },
    {
      fdr: 1.015470161245701,
      trait: "MDD",
      score: -0.111846924,
      TF_impact: "pos",
      snp: "rs145232240",
      coordinates: { chromosome: "chr4", position: 79598985 },
    },
    {
      fdr: 1.0506689073033166,
      trait: "cannabis",
      score: -0.0022649765,
      TF_impact: "pos",
      snp: "rs10118853",
      coordinates: { chromosome: "chr9", position: 108889742 },
    },
    {
      fdr: 1.121105184577533,
      trait: "anorexia",
      score: 0.005449295,
      TF_impact: "none",
      snp: "rs10135188",
      coordinates: { chromosome: "chr14", position: 32004590 },
    },
    {
      fdr: 1.9120061422788215,
      trait: "MDD",
      score: 0.06792164,
      TF_impact: "pos",
      snp: "rs12992411",
      coordinates: { chromosome: "chr2", position: 145038465 },
    },
    {
      fdr: 1.4224748041600708,
      trait: "cannabis",
      score: 0.015841007,
      TF_impact: "none",
      snp: "rs11198872",
      coordinates: { chromosome: "chr10", position: 119315803 },
    },
    {
      fdr: 0.002174600509046734,
      trait: "opioid-vs-exposed-ctrl",
      score: -0.2479167,
      TF_impact: "pos",
      snp: "rs6504407",
      coordinates: { chromosome: "chr17", position: 66275871 },
    },
    {
      fdr: 2.0004261530419707,
      trait: "ADHD-meta-filtered",
      score: 0.05554366,
      TF_impact: "none",
      snp: "rs11596250",
      coordinates: { chromosome: "chr10", position: 104784635 },
    },
    {
      fdr: 6.872745580746699e-10,
      trait: "scz",
      score: -0.4076252,
      TF_impact: "none",
      snp: "rs6930933",
      coordinates: { chromosome: "chr6", position: 32415633 },
    },
    {
      fdr: 1.9358432608898937,
      trait: "autism",
      score: 0.06375694,
      TF_impact: "none",
      snp: "rs17762308",
      coordinates: { chromosome: "chr17", position: 45703582 },
    },
    {
      fdr: 4.094916818472901e-5,
      trait: "autism",
      score: 0.3003254,
      TF_impact: "pos",
      snp: "rs6945690",
      coordinates: { chromosome: "chr7", position: 3722691 },
    },
    {
      fdr: 3.518178271403776e-5,
      trait: "bipolar-2019",
      score: 0.30227423,
      TF_impact: "none",
      snp: "rs11669127",
      coordinates: { chromosome: "chr19", position: 58492463 },
    },
    {
      fdr: 1.1562331438191518,
      trait: "anxiety",
      score: -0.104720116,
      TF_impact: "neg",
      snp: "rs11246872",
      coordinates: { chromosome: "chr12", position: 131583545 },
    },
    {
      fdr: 1.9163706993268,
      trait: "autism",
      score: 0.06828213,
      TF_impact: "neg",
      snp: "rs9961111",
      coordinates: { chromosome: "chr18", position: 52160869 },
    },
    {
      fdr: 1.773649831040454,
      trait: "bipolar-2019",
      score: -0.07695055,
      TF_impact: "none",
      snp: "rs566370",
      coordinates: { chromosome: "chr4", position: 151990529 },
    },
    {
      fdr: 0.42922614283090205,
      trait: "anorexia",
      score: 0.14222288,
      TF_impact: "none",
      snp: "rs1700159",
      coordinates: { chromosome: "chr12", position: 51912002 },
    },
    {
      fdr: 0.03098217058881747,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.20403624,
      TF_impact: "none",
      snp: "rs6741762",
      coordinates: { chromosome: "chr2", position: 196068834 },
    },
    {
      fdr: 0.01957588850989902,
      trait: "cannabis",
      score: -0.21244955,
      TF_impact: "pos",
      snp: "rs799168",
      coordinates: { chromosome: "chr7", position: 73636534 },
    },
    {
      fdr: 1.4262002729960492,
      trait: "autism",
      score: -0.016102314,
      TF_impact: "pos",
      snp: "rs10421292",
      coordinates: { chromosome: "chr19", position: 49146241 },
    },
    {
      fdr: 0.00016542781784057982,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.28417635,
      TF_impact: "none",
      snp: "rs1995755",
      coordinates: { chromosome: "chr17", position: 77287459 },
    },
    {
      fdr: 1.6569281596326209,
      trait: "bipolar-I",
      score: 0.025073051,
      TF_impact: "none",
      snp: "rs10480087",
      coordinates: { chromosome: "chr7", position: 29923499 },
    },
    {
      fdr: 1.3977123798732418,
      trait: "bipolar-I",
      score: 0.014944077,
      TF_impact: "none",
      snp: "rs237475",
      coordinates: { chromosome: "chr20", position: 49432969 },
    },
    {
      fdr: 1.8882663834667401,
      trait: "MDD",
      score: 0.07041788,
      TF_impact: "none",
      snp: "rs4891478",
      coordinates: { chromosome: "chr18", position: 72368663 },
    },
    {
      fdr: 0.0005730124985552859,
      trait: "ADHD-meta-filtered",
      score: -0.2668891,
      TF_impact: "pos",
      snp: "rs4783757",
      coordinates: { chromosome: "chr16", position: 61421349 },
    },
    {
      fdr: 1.4394296423989528,
      trait: "bipolar-2019",
      score: -0.016901016,
      TF_impact: "pos",
      snp: "rs12724815",
      coordinates: { chromosome: "chr1", position: 95084505 },
    },
    {
      fdr: 1.8576527028324785,
      trait: "bipolar-2019",
      score: -0.037598133,
      TF_impact: "none",
      snp: "rs2667772",
      coordinates: { chromosome: "chr15", position: 77581562 },
    },
    {
      fdr: 0.05493304166051314,
      trait: "anorexia",
      score: -0.19325495,
      TF_impact: "pos",
      snp: "rs9839226",
      coordinates: { chromosome: "chr3", position: 172160313 },
    },
    {
      fdr: 0.20325007987473515,
      trait: "ADHD-meta-filtered",
      score: 0.16370201,
      TF_impact: "none",
      snp: "rs10759922",
      coordinates: { chromosome: "chr9", position: 117412158 },
    },
    {
      fdr: 0.004371526835656476,
      trait: "MDD",
      score: -0.23759842,
      TF_impact: "none",
      snp: "rs28384416",
      coordinates: { chromosome: "chr12", position: 109477378 },
    },
    {
      fdr: 1.2890363035243986,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.011313915,
      TF_impact: "pos",
      snp: "rs11706886",
      coordinates: { chromosome: "chr3", position: 105747211 },
    },
  ],
  "VLPFC-glia": [
    {
      fdr: 1.5479986622557453,
      trait: "ADHD-meta-filtered",
      score: -0.024437428,
      TF_impact: "none",
      snp: "rs76708321",
      coordinates: { chromosome: "chr2", position: 10810992 },
    },
    {
      fdr: 0.09014554677199794,
      trait: "MDD",
      score: -0.12010956,
      TF_impact: "none",
      snp: "rs7206087",
      coordinates: { chromosome: "chr16", position: 65787218 },
    },
    {
      fdr: 0.7968387833518082,
      trait: "ADHD-meta-filtered",
      score: -0.07922363,
      TF_impact: "pos",
      snp: "rs530614",
      coordinates: { chromosome: "chr6", position: 33747004 },
    },
    {
      fdr: 1.5658684517504886,
      trait: "bipolar-2019",
      score: -0.025736809,
      TF_impact: "none",
      snp: "rs28464158",
      coordinates: { chromosome: "chr8", position: 9606262 },
    },
    {
      fdr: 1.2908929053621936,
      trait: "bipolar-2019",
      score: -0.0634284,
      TF_impact: "pos",
      snp: "rs73050128",
      coordinates: { chromosome: "chr7", position: 1922246 },
    },
    {
      fdr: 1.474986064435732,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.019016743,
      TF_impact: "none",
      snp: "rs4729566",
      coordinates: { chromosome: "chr7", position: 100016690 },
    },
    {
      fdr: 1.0810531016675409,
      trait: "bipolar-2012",
      score: -0.0036811829,
      TF_impact: "pos",
      snp: "rs13321742",
      coordinates: { chromosome: "chr3", position: 171802003 },
    },
    {
      fdr: 0.9116388386006908,
      trait: "MDD",
      score: -0.07603836,
      TF_impact: "pos",
      snp: "rs34002388",
      coordinates: { chromosome: "chr1", position: 49293168 },
    },
    {
      fdr: 1.623045905868579,
      trait: "bipolar-II",
      score: 0.03224182,
      TF_impact: "none",
      snp: "rs72674307",
      coordinates: { chromosome: "chr14", position: 42215423 },
    },
    {
      fdr: 0.03114483246815065,
      trait: "anxiety",
      score: 0.13391924,
      TF_impact: "pos",
      snp: "rs16869385",
      coordinates: { chromosome: "chr6", position: 32176214 },
    },
    {
      fdr: 1.6175163248680047,
      trait: "bipolar-2019",
      score: 0.029592514,
      TF_impact: "pos",
      snp: "rs55848443",
      coordinates: { chromosome: "chr4", position: 37219430 },
    },
    {
      fdr: 1.3406511251594153,
      trait: "MDD",
      score: -0.013560295,
      TF_impact: "neg",
      snp: "rs10263892",
      coordinates: { chromosome: "chr7", position: 32785569 },
    },
    {
      fdr: 1.5609803607131825,
      trait: "MDD",
      score: -0.02518034,
      TF_impact: "none",
      snp: "rs2000919",
      coordinates: { chromosome: "chr11", position: 112591113 },
    },
    {
      fdr: 0.008931490037011316,
      trait: "scz",
      score: -0.14794588,
      TF_impact: "none",
      snp: "rs7200336",
      coordinates: { chromosome: "chr16", position: 4372606 },
    },
    {
      fdr: 1.3429762485401626,
      trait: "bipolar-II",
      score: -0.013604164,
      TF_impact: "pos",
      snp: "rs10503626",
      coordinates: { chromosome: "chr8", position: 18689574 },
    },
    {
      fdr: 1.186068981355486,
      trait: "bipolar-2019",
      score: 0.007434368,
      TF_impact: "none",
      snp: "rs6478741",
      coordinates: { chromosome: "chr9", position: 126478770 },
    },
    {
      fdr: 1.6287363849927723,
      trait: "bipolar-I",
      score: 0.036799908,
      TF_impact: "pos",
      snp: "rs72669161",
      coordinates: { chromosome: "chr4", position: 122537923 },
    },
    {
      fdr: 0.239334615270616,
      trait: "bipolar-I",
      score: 0.10463858,
      TF_impact: "pos",
      snp: "rs12623170",
      coordinates: { chromosome: "chr2", position: 27797290 },
    },
    {
      fdr: 1.6148878511711247,
      trait: "MDD",
      score: 0.04121685,
      TF_impact: "pos",
      snp: "rs6673687",
      coordinates: { chromosome: "chr1", position: 205701241 },
    },
    {
      fdr: 0.009123101473087392,
      trait: "bipolar-2019",
      score: 0.14750767,
      TF_impact: "none",
      snp: "rs1995210",
      coordinates: { chromosome: "chr3", position: 2391127 },
    },
    {
      fdr: 0.6572757904630881,
      trait: "bipolar-I",
      score: 0.08414841,
      TF_impact: "none",
      snp: "rs3095337",
      coordinates: { chromosome: "chr6", position: 30769814 },
    },
    {
      fdr: 0.0003774685546229705,
      trait: "autism",
      score: 0.178195,
      TF_impact: "pos",
      snp: "rs2945236",
      coordinates: { chromosome: "chr8", position: 8310872 },
    },
    {
      fdr: 0.004346185350847875,
      trait: "MDD",
      score: -0.15542364,
      TF_impact: "none",
      snp: "rs116974312",
      coordinates: { chromosome: "chr9", position: 123457752 },
    },
    {
      fdr: 1.5680856090994542,
      trait: "anorexia",
      score: 0.025764942,
      TF_impact: "pos",
      snp: "rs17042057",
      coordinates: { chromosome: "chr2", position: 21268991 },
    },
    {
      fdr: 1.4669185940781144,
      trait: "bipolar-2019",
      score: -0.054704666,
      TF_impact: "pos",
      snp: "rs11784417",
      coordinates: { chromosome: "chr8", position: 143936584 },
    },
    {
      fdr: 1.485297105786213,
      trait: "cannabis",
      score: -0.019535542,
      TF_impact: "pos",
      snp: "rs1344047",
      coordinates: { chromosome: "chr21", position: 37144721 },
    },
    {
      fdr: 1.5248972616795715,
      trait: "bipolar-II",
      score: -0.0513072,
      TF_impact: "pos",
      snp: "rs75292817",
      coordinates: { chromosome: "chr8", position: 33708848 },
    },
    {
      fdr: 1.4995782421390962,
      trait: "cannabis",
      score: 0.020183086,
      TF_impact: "pos",
      snp: "rs9566899",
      coordinates: { chromosome: "chr13", position: 41996192 },
    },
    {
      fdr: 0.009303863327402857,
      trait: "autism",
      score: 0.14711857,
      TF_impact: "none",
      snp: "rs1792313",
      coordinates: { chromosome: "chr11", position: 71364562 },
    },
    {
      fdr: 0.7631588222749568,
      trait: "bipolar-2019",
      score: 0.08048344,
      TF_impact: "none",
      snp: "rs1931895",
      coordinates: { chromosome: "chr6", position: 116251808 },
    },
    {
      fdr: 1.4104589181555571e-6,
      trait: "MDD",
      score: 0.22183037,
      TF_impact: "pos",
      snp: "rs12124090",
      coordinates: { chromosome: "chr1", position: 11478363 },
    },
    {
      fdr: 0.0003775090331287206,
      trait: "ADHD-meta-filtered",
      score: 0.1781416,
      TF_impact: "pos",
      snp: "rs72773227",
      coordinates: { chromosome: "chr10", position: 16951201 },
    },
    {
      fdr: 1.0035337765267056,
      trait: "bipolar-2019",
      score: 0.00024366379,
      TF_impact: "none",
      snp: "rs17028044",
      coordinates: { chromosome: "chr4", position: 151887741 },
    },
    {
      fdr: 1.3652593026842494,
      trait: "bipolar-2019",
      score: -0.014323711,
      TF_impact: "none",
      snp: "rs616021",
      coordinates: { chromosome: "chr8", position: 9938864 },
    },
    {
      fdr: 1.6227049207874618,
      trait: "cannabis",
      score: 0.029874802,
      TF_impact: "none",
      snp: "rs7925990",
      coordinates: { chromosome: "chr11", position: 114027745 },
    },
    {
      fdr: 0.003561360651657629,
      trait: "ADHD-meta-filtered",
      score: 0.15754747,
      TF_impact: "pos",
      snp: "rs11741608",
      coordinates: { chromosome: "chr5", position: 104413091 },
    },
    {
      fdr: 1.1966127309866539,
      trait: "bipolar-I",
      score: 0.0077323914,
      TF_impact: "neg",
      snp: "rs4714055",
      coordinates: { chromosome: "chr6", position: 37325988 },
    },
    {
      fdr: 0.9420200266420327,
      trait: "anxiety",
      score: -0.07446623,
      TF_impact: "pos",
      snp: "rs857166",
      coordinates: { chromosome: "chr16", position: 13839037 },
    },
    {
      fdr: 0.44071468506593964,
      trait: "bipolar-2019",
      score: 0.09280133,
      TF_impact: "none",
      snp: "rs2413720",
      coordinates: { chromosome: "chr22", position: 42802253 },
    },
    {
      fdr: 1.2567398033402584,
      trait: "MDD",
      score: -0.009916782,
      TF_impact: "none",
      snp: "rs10815291",
      coordinates: { chromosome: "chr9", position: 5844824 },
    },
    {
      fdr: 1.0347231828387982,
      trait: "bipolar-2019",
      score: 0.0016765594,
      TF_impact: "none",
      snp: "rs7825085",
      coordinates: { chromosome: "chr8", position: 27740783 },
    },
    {
      fdr: 1.630162667257704,
      trait: "bipolar-I",
      score: -0.034883976,
      TF_impact: "pos",
      snp: "rs8129599",
      coordinates: { chromosome: "chr21", position: 38170889 },
    },
    {
      fdr: 1.5942290298683062,
      trait: "ADHD-meta-filtered",
      score: 0.02814436,
      TF_impact: "none",
      snp: "rs12534157",
      coordinates: { chromosome: "chr7", position: 155670674 },
    },
    {
      fdr: 0.004183301501134644,
      trait: "anxiety",
      score: -0.15603161,
      TF_impact: "none",
      snp: "rs7101278",
      coordinates: { chromosome: "chr10", position: 95260855 },
    },
    {
      fdr: 0.1994718155260091,
      trait: "MDD",
      score: -0.10768032,
      TF_impact: "none",
      snp: "rs138157115",
      coordinates: { chromosome: "chr12", position: 52041848 },
    },
    {
      fdr: 1.2760516878258193e-7,
      trait: "bipolar-2019",
      score: 0.23741674,
      TF_impact: "none",
      snp: "rs7394459",
      coordinates: { chromosome: "chr11", position: 61869877 },
    },
    {
      fdr: 1.564863411512987,
      trait: "ADHD-meta-filtered",
      score: 0.045873165,
      TF_impact: "none",
      snp: "rs7920108",
      coordinates: { chromosome: "chr10", position: 105017323 },
    },
    {
      fdr: 1.0136106915207859,
      trait: "bipolar-I",
      score: -0.00085401535,
      TF_impact: "pos",
      snp: "rs11973169",
      coordinates: { chromosome: "chr7", position: 30005798 },
    },
    {
      fdr: 1.3847677149977062,
      trait: "ADHD-meta-filtered",
      score: 0.058956623,
      TF_impact: "none",
      snp: "rs2239339",
      coordinates: { chromosome: "chr16", position: 24218564 },
    },
    {
      fdr: 6.853718673242767e-6,
      trait: "pgc-panic-disorder",
      score: -0.21098137,
      TF_impact: "none",
      snp: "rs2293271",
      coordinates: { chromosome: "chr2", position: 48560780 },
    },
    {
      fdr: 1.3095198804857788,
      trait: "anxiety",
      score: -0.011942387,
      TF_impact: "none",
      snp: "rs6791614",
      coordinates: { chromosome: "chr3", position: 102038604 },
    },
    {
      fdr: 1.5316967704746107,
      trait: "autism",
      score: -0.023312569,
      TF_impact: "none",
      snp: "rs80587",
      coordinates: { chromosome: "chr22", position: 36202003 },
    },
    {
      fdr: 1.4284567616367154,
      trait: "autism",
      score: 0.01656437,
      TF_impact: "pos",
      snp: "rs2045199",
      coordinates: { chromosome: "chr4", position: 60934467 },
    },
    {
      fdr: 3.13651202965506e-6,
      trait: "bipolar-2012",
      score: -0.21651268,
      TF_impact: "pos",
      snp: "rs12380490",
      coordinates: { chromosome: "chr9", position: 110426250 },
    },
    {
      fdr: 1.1011723247823974e-5,
      trait: "anorexia",
      score: -0.20705175,
      TF_impact: "none",
      snp: "rs4881291",
      coordinates: { chromosome: "chr10", position: 4108859 },
    },
    {
      fdr: 1.1488699009504773,
      trait: "cannabis",
      score: 0.006207943,
      TF_impact: "pos",
      snp: "rs2597277",
      coordinates: { chromosome: "chr7", position: 37683810 },
    },
    {
      fdr: 1.62978839264378,
      trait: "scz",
      score: -0.036958694,
      TF_impact: "pos",
      snp: "rs198820",
      coordinates: { chromosome: "chr6", position: 26124015 },
    },
    {
      fdr: 1.0772763980741682,
      trait: "bipolar-I",
      score: -0.0035586357,
      TF_impact: "none",
      snp: "rs1442104",
      coordinates: { chromosome: "chr4", position: 47210122 },
    },
    {
      fdr: 0.003448199862042682,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.15804386,
      TF_impact: "none",
      snp: "rs7649466",
      coordinates: { chromosome: "chr3", position: 105869001 },
    },
    {
      fdr: 1.789726578159251e-5,
      trait: "anxiety",
      score: 0.20271921,
      TF_impact: "none",
      snp: "rs17628613",
      coordinates: { chromosome: "chr4", position: 170014603 },
    },
    {
      fdr: 1.4509199188957735,
      trait: "MDD",
      score: -0.017796516,
      TF_impact: "neg",
      snp: "rs12036585",
      coordinates: { chromosome: "chr1", position: 72178433 },
    },
    {
      fdr: 1.5669272707889082e-5,
      trait: "ADHD-meta-filtered",
      score: -0.20433187,
      TF_impact: "none",
      snp: "rs61867294",
      coordinates: { chromosome: "chr10", position: 104809449 },
    },
    {
      fdr: 1.1397621292975548,
      trait: "MDD",
      score: -0.0059838295,
      TF_impact: "pos",
      snp: "rs78757334",
      coordinates: { chromosome: "chr13", position: 26548505 },
    },
    {
      fdr: 3.0846188038575666e-5,
      trait: "anxiety",
      score: 0.19810867,
      TF_impact: "none",
      snp: "rs6706053",
      coordinates: { chromosome: "chr2", position: 44290523 },
    },
    {
      fdr: 1.6170659493237984,
      trait: "bipolar-2019",
      score: -0.040733814,
      TF_impact: "pos",
      snp: "rs325400",
      coordinates: { chromosome: "chr15", position: 99712600 },
    },
    {
      fdr: 1.429841037653408,
      trait: "MDD",
      score: -0.016796112,
      TF_impact: "none",
      snp: "rs10493806",
      coordinates: { chromosome: "chr1", position: 88228266 },
    },
    {
      fdr: 0.00020469651483462093,
      trait: "bipolar-I",
      score: -0.18371344,
      TF_impact: "none",
      snp: "rs9311270",
      coordinates: { chromosome: "chr3", position: 41352679 },
    },
    {
      fdr: 1.4136074044962294e-10,
      trait: "bipolar-2019",
      score: 0.27620983,
      TF_impact: "none",
      snp: "rs7010588",
      coordinates: { chromosome: "chr8", position: 143959608 },
    },
    {
      fdr: 1.3999832915163883,
      trait: "bipolar-II",
      score: 0.015673637,
      TF_impact: "neg",
      snp: "rs9896830",
      coordinates: { chromosome: "chr17", position: 51958032 },
    },
    {
      fdr: 1.3274187825178754,
      trait: "bipolar-I",
      score: -0.062008858,
      TF_impact: "pos",
      snp: "rs4859265",
      coordinates: { chromosome: "chr3", position: 183033342 },
    },
    {
      fdr: 0.005712256536155218,
      trait: "bipolar-2019",
      score: -0.15271282,
      TF_impact: "none",
      snp: "rs8087493",
      coordinates: { chromosome: "chr18", position: 43159234 },
    },
    {
      fdr: 0.0054471938326617845,
      trait: "bipolar-2019",
      score: -0.15328121,
      TF_impact: "none",
      snp: "rs1078773",
      coordinates: { chromosome: "chr10", position: 12514034 },
    },
    {
      fdr: 1.1538654422757701,
      trait: "anorexia",
      score: 0.006342888,
      TF_impact: "pos",
      snp: "rs12802128",
      coordinates: { chromosome: "chr11", position: 74560127 },
    },
    {
      fdr: 1.491687282399197,
      trait: "anorexia",
      score: -0.053292274,
      TF_impact: "none",
      snp: "rs7761589",
      coordinates: { chromosome: "chr6", position: 30890470 },
    },
    {
      fdr: 1.5694512896444393,
      trait: "MDD",
      score: -0.026625633,
      TF_impact: "pos",
      snp: "rs940310",
      coordinates: { chromosome: "chr18", position: 54388911 },
    },
    {
      fdr: 0.0010341155404963787,
      trait: "bipolar-I",
      score: 0.16902256,
      TF_impact: "pos",
      snp: "rs62105751",
      coordinates: { chromosome: "chr19", position: 9847213 },
    },
    {
      fdr: 1.0346541024067473e-5,
      trait: "MDD",
      score: 0.20756435,
      TF_impact: "pos",
      snp: "rs61901226",
      coordinates: { chromosome: "chr11", position: 112671366 },
    },
    {
      fdr: 0.00042468299516934885,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.17713165,
      TF_impact: "pos",
      snp: "rs12602556",
      coordinates: { chromosome: "chr17", position: 67830745 },
    },
    {
      fdr: 1.1413214264331126,
      trait: "bipolar-I",
      score: 0.0059223175,
      TF_impact: "none",
      snp: "rs11242436",
      coordinates: { chromosome: "chr5", position: 138601629 },
    },
    {
      fdr: 0.9845141524045019,
      trait: "MDD",
      score: -0.07282972,
      TF_impact: "neg",
      snp: "rs357524",
      coordinates: { chromosome: "chr9", position: 95426883 },
    },
    {
      fdr: 1.0948369082536048e-5,
      trait: "bipolar-2012",
      score: -0.20725155,
      TF_impact: "none",
      snp: "rs6497007",
      coordinates: { chromosome: "chr15", position: 85333786 },
    },
    {
      fdr: 1.4558401806311623,
      trait: "anxiety",
      score: 0.05569887,
      TF_impact: "pos",
      snp: "rs8008675",
      coordinates: { chromosome: "chr14", position: 94868081 },
    },
    {
      fdr: 1.0391936301247447e-41,
      trait: "pgc-panic-disorder",
      score: 0.53366375,
      TF_impact: "pos",
      snp: "rs2857106",
      coordinates: { chromosome: "chr6", position: 32819793 },
    },
    {
      fdr: 1.238383624533386,
      trait: "bipolar-I",
      score: -0.009274483,
      TF_impact: "pos",
      snp: "rs9890374",
      coordinates: { chromosome: "chr17", position: 28265336 },
    },
    {
      fdr: 1.4530181092637684,
      trait: "anorexia",
      score: 0.05581665,
      TF_impact: "none",
      snp: "rs56386725",
      coordinates: { chromosome: "chr14", position: 64417633 },
    },
    {
      fdr: 1.4590245666236479,
      trait: "autism",
      score: 0.05531645,
      TF_impact: "pos",
      snp: "rs12550057",
      coordinates: { chromosome: "chr8", position: 8704758 },
    },
    {
      fdr: 2.534804223484949e-10,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.2731123,
      TF_impact: "none",
      snp: "rs17770142",
      coordinates: { chromosome: "chr16", position: 83717918 },
    },
    {
      fdr: 1.156717942580772,
      trait: "MDD",
      score: 0.0064578056,
      TF_impact: "none",
      snp: "rs10868001",
      coordinates: { chromosome: "chr9", position: 83366668 },
    },
    {
      fdr: 1.0159511724536432,
      trait: "anxiety",
      score: -0.000975132,
      TF_impact: "none",
      snp: "rs72802493",
      coordinates: { chromosome: "chr2", position: 46777070 },
    },
    {
      fdr: 1.616723929397682,
      trait: "bipolar-I",
      score: 0.029587746,
      TF_impact: "none",
      snp: "rs1080500",
      coordinates: { chromosome: "chr3", position: 53141001 },
    },
    {
      fdr: 1.6319247136097257,
      trait: "cannabis",
      score: 0.035859108,
      TF_impact: "none",
      snp: "rs754333",
      coordinates: { chromosome: "chr9", position: 108924616 },
    },
    {
      fdr: 1.062568144181498,
      trait: "bipolar-I",
      score: 0.0028386116,
      TF_impact: "none",
      snp: "rs16865132",
      coordinates: { chromosome: "chr2", position: 6410574 },
    },
    {
      fdr: 1.5123257325448851,
      trait: "bipolar-2019",
      score: 0.05184698,
      TF_impact: "none",
      snp: "rs72769966",
      coordinates: { chromosome: "chr16", position: 9123915 },
    },
    {
      fdr: 0.004385017563691429,
      trait: "ADHD-meta-filtered",
      score: 0.15528727,
      TF_impact: "neg",
      snp: "rs4635202",
      coordinates: { chromosome: "chr13", position: 31044522 },
    },
    {
      fdr: 1.4558333962131393,
      trait: "anorexia",
      score: 0.05520153,
      TF_impact: "none",
      snp: "rs249276",
      coordinates: { chromosome: "chr16", position: 9422827 },
    },
    {
      fdr: 1.4199573788115085,
      trait: "MDD",
      score: -0.016462803,
      TF_impact: "none",
      snp: "rs28695930",
      coordinates: { chromosome: "chr8", position: 9606228 },
    },
    {
      fdr: 1.5365772066887964,
      trait: "MDD",
      score: 0.023423195,
      TF_impact: "none",
      snp: "rs12923469",
      coordinates: { chromosome: "chr16", position: 79128723 },
    },
    {
      fdr: 8.725092130530347e-12,
      trait: "bipolar-I",
      score: 0.2923298,
      TF_impact: "none",
      snp: "rs9559493",
      coordinates: { chromosome: "chr13", position: 109088749 },
    },
    {
      fdr: 0.3511250858644537,
      trait: "bipolar-2019",
      score: -0.09808636,
      TF_impact: "none",
      snp: "rs17774047",
      coordinates: { chromosome: "chr15", position: 42721028 },
    },
    {
      fdr: 1.5435735014800378,
      trait: "autism",
      score: 0.023897171,
      TF_impact: "pos",
      snp: "rs73403546",
      coordinates: { chromosome: "chr15", position: 42150625 },
    },
    {
      fdr: 1.332598064110586,
      trait: "scz",
      score: 0.012923241,
      TF_impact: "none",
      snp: "rs12577525",
      coordinates: { chromosome: "chr11", position: 17238569 },
    },
    {
      fdr: 1.562747728091054,
      trait: "bipolar-2019",
      score: 0.025521755,
      TF_impact: "none",
      snp: "rs1434984",
      coordinates: { chromosome: "chr2", position: 14641313 },
    },
    {
      fdr: 1.1315596236975699e-11,
      trait: "anorexia",
      score: 0.29342365,
      TF_impact: "pos",
      snp: "rs35808061",
      coordinates: { chromosome: "chr11", position: 57779528 },
    },
    {
      fdr: 0.00873201214356025,
      trait: "ADHD-meta-filtered",
      score: -0.14833069,
      TF_impact: "pos",
      snp: "rs8040226",
      coordinates: { chromosome: "chr15", position: 47384633 },
    },
    {
      fdr: 1.6183514549887468,
      trait: "anxiety",
      score: -0.04065752,
      TF_impact: "none",
      snp: "rs6575469",
      coordinates: { chromosome: "chr14", position: 94868350 },
    },
    {
      fdr: 1.2330073967646387,
      trait: "MDD",
      score: -0.064995766,
      TF_impact: "none",
      snp: "rs7701817",
      coordinates: { chromosome: "chr5", position: 124650872 },
    },
    {
      fdr: 1.4006518989703802,
      trait: "MDD",
      score: 0.058016777,
      TF_impact: "none",
      snp: "rs74831413",
      coordinates: { chromosome: "chr2", position: 237512878 },
    },
    {
      fdr: 0.7909591592288588,
      trait: "bipolar-II",
      score: -0.07975817,
      TF_impact: "pos",
      snp: "rs16891334",
      coordinates: { chromosome: "chr6", position: 26124075 },
    },
    {
      fdr: 1.0590652974239918,
      trait: "bipolar-2012",
      score: 0.002641201,
      TF_impact: "none",
      snp: "rs11681427",
      coordinates: { chromosome: "chr2", position: 240766810 },
    },
    {
      fdr: 1.059026923742573,
      trait: "bipolar-I",
      score: -0.0027809143,
      TF_impact: "pos",
      snp: "rs77457752",
      coordinates: { chromosome: "chr9", position: 13942941 },
    },
    {
      fdr: 1.1310058510546095,
      trait: "cannabis",
      score: -0.005533695,
      TF_impact: "pos",
      snp: "rs10520034",
      coordinates: { chromosome: "chr5", position: 129410822 },
    },
    {
      fdr: 1.001835064152541,
      trait: "MDD",
      score: 0.07208824,
      TF_impact: "pos",
      snp: "rs2232015",
      coordinates: { chromosome: "chr1", position: 107056636 },
    },
    {
      fdr: 1.6173943432711635,
      trait: "bipolar-II",
      score: 0.032958508,
      TF_impact: "none",
      snp: "rs55762233",
      coordinates: { chromosome: "chr19", position: 19256510 },
    },
    {
      fdr: 1.4495083671863123,
      trait: "MDD",
      score: -0.056061745,
      TF_impact: "none",
      snp: "rs1618400",
      coordinates: { chromosome: "chr11", position: 115707780 },
    },
    {
      fdr: 1.8116725332209595e-9,
      trait: "bipolar-I",
      score: 0.2620139,
      TF_impact: "none",
      snp: "rs1452387",
      coordinates: { chromosome: "chr15", position: 73856484 },
    },
    {
      fdr: 1.5703796162557997,
      trait: "autism",
      score: -0.04827833,
      TF_impact: "pos",
      snp: "rs7014953",
      coordinates: { chromosome: "chr8", position: 8310891 },
    },
    {
      fdr: 1.0586280892804434,
      trait: "ADHD-meta-filtered",
      score: -0.0027713776,
      TF_impact: "none",
      snp: "rs11165651",
      coordinates: { chromosome: "chr1", position: 96472692 },
    },
    {
      fdr: 0.001383405212602681,
      trait: "bipolar-2019",
      score: 0.1659832,
      TF_impact: "none",
      snp: "rs10173260",
      coordinates: { chromosome: "chr2", position: 209513121 },
    },
    {
      fdr: 1.0509850842576203,
      trait: "anorexia",
      score: 0.002401352,
      TF_impact: "none",
      snp: "rs318198",
      coordinates: { chromosome: "chr16", position: 64207177 },
    },
    {
      fdr: 0.35082548809497177,
      trait: "anxiety",
      score: 0.097886086,
      TF_impact: "none",
      snp: "rs3774117",
      coordinates: { chromosome: "chr3", position: 10857556 },
    },
    {
      fdr: 1.4149332577568532,
      trait: "autism",
      score: -0.016260624,
      TF_impact: "none",
      snp: "rs2921074",
      coordinates: { chromosome: "chr8", position: 8503629 },
    },
    {
      fdr: 1.1154934152033629,
      trait: "anorexia",
      score: -0.0688858,
      TF_impact: "none",
      snp: "rs2405104",
      coordinates: { chromosome: "chr1", position: 208527388 },
    },
    {
      fdr: 0.09233732826276218,
      trait: "bipolar-2019",
      score: -0.11957312,
      TF_impact: "pos",
      snp: "rs28728306",
      coordinates: { chromosome: "chr7", position: 1922178 },
    },
    {
      fdr: 1.1855189010469696,
      trait: "ADHD-meta-filtered",
      score: -0.007543087,
      TF_impact: "pos",
      snp: "rs6811025",
      coordinates: { chromosome: "chr4", position: 34159044 },
    },
    {
      fdr: 1.611790163025788,
      trait: "anxiety",
      score: -0.041548252,
      TF_impact: "none",
      snp: "rs11125089",
      coordinates: { chromosome: "chr2", position: 46781124 },
    },
    {
      fdr: 1.5383250126585128,
      trait: "bipolar-2019",
      score: -0.04993868,
      TF_impact: "pos",
      snp: "rs67263685",
      coordinates: { chromosome: "chr16", position: 9135088 },
    },
    {
      fdr: 1.5730985586646995,
      trait: "bipolar-I",
      score: -0.044107914,
      TF_impact: "none",
      snp: "rs73199533",
      coordinates: { chromosome: "chr12", position: 108175466 },
    },
    {
      fdr: 1.594738237057994,
      trait: "anorexia",
      score: 0.042607307,
      TF_impact: "none",
      snp: "rs1524682",
      coordinates: { chromosome: "chr4", position: 57379036 },
    },
    {
      fdr: 1.3805451347128148,
      trait: "bipolar-2019",
      score: -0.0149998665,
      TF_impact: "none",
      snp: "rs34849561",
      coordinates: { chromosome: "chr13", position: 41716647 },
    },
    {
      fdr: 1.015847045416985,
      trait: "ADHD",
      score: 0.0008249283,
      TF_impact: "none",
      snp: "rs2916197",
      coordinates: { chromosome: "chr1", position: 153355859 },
    },
    {
      fdr: 1.61795427379596,
      trait: "anorexia",
      score: -0.03276801,
      TF_impact: "none",
      snp: "rs7920451",
      coordinates: { chromosome: "chr10", position: 124907807 },
    },
    {
      fdr: 1.417926609202203,
      trait: "bipolar-I",
      score: -0.016370296,
      TF_impact: "pos",
      snp: "rs11134593",
      coordinates: { chromosome: "chr5", position: 169862631 },
    },
    {
      fdr: 1.2878282790213655,
      trait: "anorexia",
      score: -0.0631609,
      TF_impact: "pos",
      snp: "rs3101018",
      coordinates: { chromosome: "chr6", position: 31738087 },
    },
    {
      fdr: 0.3395182030367615,
      trait: "autism",
      score: -0.09900379,
      TF_impact: "pos",
      snp: "rs132734",
      coordinates: { chromosome: "chr22", position: 36201831 },
    },
    {
      fdr: 1.4903712758860186,
      trait: "bipolar-I",
      score: -0.05347252,
      TF_impact: "pos",
      snp: "rs6576976",
      coordinates: { chromosome: "chr2", position: 96580930 },
    },
    {
      fdr: 1.3204946902619594,
      trait: "scz",
      score: 0.012289047,
      TF_impact: "none",
      snp: "rs500571",
      coordinates: { chromosome: "chr11", position: 83594499 },
    },
    {
      fdr: 0.3810247860461752,
      trait: "bipolar-2019",
      score: 0.09605837,
      TF_impact: "pos",
      snp: "rs596136",
      coordinates: { chromosome: "chr18", position: 11999557 },
    },
    {
      fdr: 1.5277367015038132,
      trait: "pgc-panic-disorder",
      score: 0.022188187,
      TF_impact: "none",
      snp: "rs8042870",
      coordinates: { chromosome: "chr15", position: 96849640 },
    },
    {
      fdr: 0.07716205769771692,
      trait: "autism",
      score: 0.12193966,
      TF_impact: "none",
      snp: "rs13264212",
      coordinates: { chromosome: "chr8", position: 11452340 },
    },
    {
      fdr: 1.097452961512029,
      trait: "bipolar-I",
      score: 0.0043096542,
      TF_impact: "pos",
      snp: "rs62239507",
      coordinates: { chromosome: "chr22", position: 50539788 },
    },
    {
      fdr: 1.547705142558727,
      trait: "ADHD-meta-filtered",
      score: 0.024051666,
      TF_impact: "none",
      snp: "rs6771703",
      coordinates: { chromosome: "chr3", position: 126082224 },
    },
    {
      fdr: 1.2389671956001411,
      trait: "bipolar-2019",
      score: -0.009291887,
      TF_impact: "neg",
      snp: "rs4845677",
      coordinates: { chromosome: "chr1", position: 154865574 },
    },
    {
      fdr: 1.0150763566463945,
      trait: "autism",
      score: 0.0008096695,
      TF_impact: "none",
      snp: "rs62054435",
      coordinates: { chromosome: "chr17", position: 45747973 },
    },
    {
      fdr: 1.6223976736983912,
      trait: "autism",
      score: 0.03158617,
      TF_impact: "none",
      snp: "rs7644373",
      coordinates: { chromosome: "chr3", position: 153997561 },
    },
    {
      fdr: 6.40628566673035e-5,
      trait: "bipolar-II",
      score: 0.19267654,
      TF_impact: "pos",
      snp: "rs2766557",
      coordinates: { chromosome: "chr6", position: 35757655 },
    },
    {
      fdr: 1.0598417120035912,
      trait: "bipolar-2019",
      score: -0.0028185844,
      TF_impact: "none",
      snp: "rs74779340",
      coordinates: { chromosome: "chr7", position: 24781326 },
    },
    {
      fdr: 0.11954270302251248,
      trait: "bipolar-II",
      score: 0.11561394,
      TF_impact: "none",
      snp: "rs1469712",
      coordinates: { chromosome: "chr19", position: 19418012 },
    },
    {
      fdr: 0.008042357385011165,
      trait: "anorexia",
      score: 0.14914703,
      TF_impact: "none",
      snp: "rs11722498",
      coordinates: { chromosome: "chr4", position: 57234479 },
    },
    {
      fdr: 1.491105038752676,
      trait: "ADHD-meta-filtered",
      score: 0.053370476,
      TF_impact: "pos",
      snp: "rs7810323",
      coordinates: { chromosome: "chr7", position: 1804158 },
    },
    {
      fdr: 0.05404958714774673,
      trait: "pgc-panic-disorder",
      score: -0.12675428,
      TF_impact: "none",
      snp: "rs6979076",
      coordinates: { chromosome: "chr7", position: 1076611 },
    },
    {
      fdr: 1.3323686116149904,
      trait: "anorexia",
      score: 0.012910843,
      TF_impact: "none",
      snp: "rs2532935",
      coordinates: { chromosome: "chr6", position: 30926796 },
    },
    {
      fdr: 1.6160860919764841,
      trait: "autism",
      score: 0.03287363,
      TF_impact: "pos",
      snp: "rs62063295",
      coordinates: { chromosome: "chr17", position: 45967065 },
    },
    {
      fdr: 1.2670298464825929,
      trait: "pgc-panic-disorder",
      score: -0.06409073,
      TF_impact: "none",
      snp: "rs7203458",
      coordinates: { chromosome: "chr16", position: 73192477 },
    },
    {
      fdr: 1.0532919823642377,
      trait: "bipolar-2019",
      score: 0.0025053024,
      TF_impact: "none",
      snp: "rs13000014",
      coordinates: { chromosome: "chr2", position: 98766867 },
    },
    {
      fdr: 0.9530976944866976,
      trait: "anxiety",
      score: -0.07411337,
      TF_impact: "none",
      snp: "rs6896953",
      coordinates: { chromosome: "chr5", position: 83089284 },
    },
    {
      fdr: 0.4196144845540071,
      trait: "autism",
      score: 0.093707085,
      TF_impact: "none",
      snp: "rs6431489",
      coordinates: { chromosome: "chr2", position: 236902166 },
    },
    {
      fdr: 1.3325786765505054,
      trait: "bipolar-I",
      score: 0.0129470825,
      TF_impact: "pos",
      snp: "rs6805895",
      coordinates: { chromosome: "chr3", position: 156010985 },
    },
    {
      fdr: 0.009176027104411151,
      trait: "scz",
      score: -0.1474886,
      TF_impact: "pos",
      snp: "rs9661794",
      coordinates: { chromosome: "chr1", position: 97870773 },
    },
    {
      fdr: 1.475751385739258,
      trait: "anorexia",
      score: -0.019143581,
      TF_impact: "neg",
      snp: "rs12320284",
      coordinates: { chromosome: "chr12", position: 49429144 },
    },
    {
      fdr: 1.5309513049269585,
      trait: "anorexia",
      score: 0.02274561,
      TF_impact: "pos",
      snp: "rs10445919",
      coordinates: { chromosome: "chr2", position: 23012697 },
    },
    {
      fdr: 0.011922303818979062,
      trait: "bipolar-2019",
      score: -0.14462996,
      TF_impact: "pos",
      snp: "rs1953723",
      coordinates: { chromosome: "chr14", position: 29761297 },
    },
    {
      fdr: 0.12152179732545991,
      trait: "anorexia",
      score: 0.11538458,
      TF_impact: "pos",
      snp: "rs7866620",
      coordinates: { chromosome: "chr9", position: 124802650 },
    },
    {
      fdr: 1.063898165665496,
      trait: "MDD",
      score: -0.0030322075,
      TF_impact: "none",
      snp: "rs280299",
      coordinates: { chromosome: "chr6", position: 50656132 },
    },
    {
      fdr: 3.2267330108279858e-6,
      trait: "ADHD-meta-filtered",
      score: 0.21582365,
      TF_impact: "pos",
      snp: "rs28579454",
      coordinates: { chromosome: "chr15", position: 80351159 },
    },
    {
      fdr: 1.5798091716640892,
      trait: "ADHD-meta-filtered",
      score: 0.044211388,
      TF_impact: "none",
      snp: "rs1446335",
      coordinates: { chromosome: "chr15", position: 80412033 },
    },
    {
      fdr: 0.00887752790031877,
      trait: "MDD",
      score: 0.147933,
      TF_impact: "none",
      snp: "rs11207138",
      coordinates: { chromosome: "chr1", position: 39926485 },
    },
    {
      fdr: 0.0010303845669395306,
      trait: "bipolar-I",
      score: -0.16891766,
      TF_impact: "none",
      snp: "rs1459540",
      coordinates: { chromosome: "chr4", position: 117773188 },
    },
    {
      fdr: 0.006280536355730501,
      trait: "bipolar-2012",
      score: 0.15166855,
      TF_impact: "none",
      snp: "rs9376004",
      coordinates: { chromosome: "chr6", position: 134035907 },
    },
    {
      fdr: 7.540657838350286e-12,
      trait: "bipolar-2019",
      score: 0.29576826,
      TF_impact: "none",
      snp: "rs12191379",
      coordinates: { chromosome: "chr6", position: 124683704 },
    },
    {
      fdr: 0.007297412780383562,
      trait: "bipolar-2019",
      score: 0.15023518,
      TF_impact: "pos",
      snp: "rs2667762",
      coordinates: { chromosome: "chr15", position: 77590075 },
    },
    {
      fdr: 1.2230514001856124e-9,
      trait: "MDD",
      score: 0.26477718,
      TF_impact: "none",
      snp: "rs2678273",
      coordinates: { chromosome: "chr2", position: 215619859 },
    },
    {
      fdr: 1.491764365644789,
      trait: "bipolar-I",
      score: -0.019870758,
      TF_impact: "none",
      snp: "rs6496774",
      coordinates: { chromosome: "chr15", position: 91227417 },
    },
    {
      fdr: 1.1924747731155866e-6,
      trait: "autism",
      score: -0.22330761,
      TF_impact: "pos",
      snp: "rs12519002",
      coordinates: { chromosome: "chr5", position: 57889847 },
    },
    {
      fdr: 1.5184045972336094e-10,
      trait: "autism",
      score: 0.2763605,
      TF_impact: "pos",
      snp: "rs72828517",
      coordinates: { chromosome: "chr6", position: 19035804 },
    },
    {
      fdr: 1.2235241716853473,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.008720875,
      TF_impact: "pos",
      snp: "rs11097575",
      coordinates: { chromosome: "chr4", position: 97721267 },
    },
    {
      fdr: 0.41024817309411693,
      trait: "autism",
      score: 0.09421396,
      TF_impact: "pos",
      snp: "rs7603584",
      coordinates: { chromosome: "chr2", position: 201440422 },
    },
    {
      fdr: 1.1003332882699322,
      trait: "bipolar-I",
      score: 0.0043797493,
      TF_impact: "none",
      snp: "rs6740035",
      coordinates: { chromosome: "chr2", position: 98960115 },
    },
    {
      fdr: 1.1503576753979001,
      trait: "bipolar-I",
      score: -0.06770754,
      TF_impact: "none",
      snp: "rs596179",
      coordinates: { chromosome: "chr3", position: 182855345 },
    },
    {
      fdr: 0.0003723607266884475,
      trait: "autism",
      score: 0.17851162,
      TF_impact: "pos",
      snp: "rs2721811",
      coordinates: { chromosome: "chr7", position: 24709810 },
    },
    {
      fdr: 0.0010952455361558258,
      trait: "autism",
      score: -0.16838789,
      TF_impact: "none",
      snp: "rs1908814",
      coordinates: { chromosome: "chr8", position: 11897546 },
    },
    {
      fdr: 1.4507432332678083,
      trait: "bipolar-I",
      score: 0.017765045,
      TF_impact: "none",
      snp: "rs11136345",
      coordinates: { chromosome: "chr8", position: 143985944 },
    },
    {
      fdr: 0.004750000094798082,
      trait: "bipolar-2019",
      score: -0.1546216,
      TF_impact: "none",
      snp: "rs1402030",
      coordinates: { chromosome: "chr17", position: 40068768 },
    },
    {
      fdr: 1.6068003211374604,
      trait: "anxiety",
      score: 0.029286385,
      TF_impact: "pos",
      snp: "rs4771314",
      coordinates: { chromosome: "chr13", position: 98664201 },
    },
    {
      fdr: 1.0669203212968421,
      trait: "anxiety",
      score: -0.003153801,
      TF_impact: "none",
      snp: "rs3927078",
      coordinates: { chromosome: "chr2", position: 46670534 },
    },
    {
      fdr: 1.618301909571843,
      trait: "anxiety",
      score: -0.033498764,
      TF_impact: "none",
      snp: "rs17662779",
      coordinates: { chromosome: "chr17", position: 34567128 },
    },
    {
      fdr: 1.1714818280639205,
      trait: "anxiety",
      score: 0.0069613457,
      TF_impact: "none",
      snp: "rs35840820",
      coordinates: { chromosome: "chr4", position: 81527372 },
    },
    {
      fdr: 0.009118309595676926,
      trait: "autism",
      score: 0.14744091,
      TF_impact: "none",
      snp: "rs2976882",
      coordinates: { chromosome: "chr8", position: 8467350 },
    },
    {
      fdr: 0.012942139473480893,
      trait: "MDD",
      score: -0.14360714,
      TF_impact: "none",
      snp: "rs149280103",
      coordinates: { chromosome: "chr18", position: 51154196 },
    },
    {
      fdr: 0.0017333964404941276,
      trait: "MDD",
      score: -0.16395569,
      TF_impact: "pos",
      snp: "rs73738225",
      coordinates: { chromosome: "chr6", position: 27213931 },
    },
    {
      fdr: 7.674479981189463e-11,
      trait: "bipolar-I",
      score: -0.28005505,
      TF_impact: "pos",
      snp: "rs1480162",
      coordinates: { chromosome: "chr5", position: 147092245 },
    },
    {
      fdr: 0.0011297743060903176,
      trait: "anorexia",
      score: -0.16805172,
      TF_impact: "none",
      snp: "rs3781445",
      coordinates: { chromosome: "chr10", position: 124999801 },
    },
    {
      fdr: 0.10296445051774233,
      trait: "ADHD-meta-filtered",
      score: -0.117898464,
      TF_impact: "none",
      snp: "rs7922641",
      coordinates: { chromosome: "chr10", position: 113187401 },
    },
    {
      fdr: 0.00011379058269884686,
      trait: "anorexia",
      score: -0.18856096,
      TF_impact: "none",
      snp: "rs718947",
      coordinates: { chromosome: "chr10", position: 125050580 },
    },
    {
      fdr: 1.4883611446888223,
      trait: "MDD",
      score: 0.019666195,
      TF_impact: "none",
      snp: "rs11700794",
      coordinates: { chromosome: "chr21", position: 17659083 },
    },
    {
      fdr: 1.1027039549307966,
      trait: "bipolar-2019",
      score: 0.004430294,
      TF_impact: "none",
      snp: "rs2721921",
      coordinates: { chromosome: "chr8", position: 132499596 },
    },
    {
      fdr: 1.1018771191920835,
      trait: "bipolar-2019",
      score: 0.004409313,
      TF_impact: "neg",
      snp: "rs34044266",
      coordinates: { chromosome: "chr17", position: 75028091 },
    },
    {
      fdr: 1.9189102056027775e-8,
      trait: "cannabis",
      score: 0.24869108,
      TF_impact: "none",
      snp: "rs11188284",
      coordinates: { chromosome: "chr10", position: 95337497 },
    },
    {
      fdr: 0.0005083630416628227,
      trait: "bipolar-2019",
      score: -0.17545891,
      TF_impact: "none",
      snp: "rs76664163",
      coordinates: { chromosome: "chr19", position: 46413718 },
    },
    {
      fdr: 1.0523624718438502,
      trait: "MDD",
      score: -0.0025992393,
      TF_impact: "neg",
      snp: "rs1107070",
      coordinates: { chromosome: "chr14", position: 103504171 },
    },
    {
      fdr: 1.4582534427845968,
      trait: "MDD",
      score: 0.018018246,
      TF_impact: "pos",
      snp: "rs61943400",
      coordinates: { chromosome: "chr12", position: 118398236 },
    },
    {
      fdr: 6.345977839034749e-14,
      trait: "MDD",
      score: -0.32137823,
      TF_impact: "pos",
      snp: "rs9958401",
      coordinates: { chromosome: "chr18", position: 61891982 },
    },
    {
      fdr: 1.6184059757690867,
      trait: "bipolar-II",
      score: -0.03264761,
      TF_impact: "none",
      snp: "rs72689945",
      coordinates: { chromosome: "chr8", position: 143146930 },
    },
    {
      fdr: 1.2571350674121042,
      trait: "autism",
      score: -0.00996685,
      TF_impact: "none",
      snp: "rs7810588",
      coordinates: { chromosome: "chr7", position: 30182049 },
    },
    {
      fdr: 1.4885673567238487,
      trait: "cannabis",
      score: 0.05325508,
      TF_impact: "none",
      snp: "rs77068358",
      coordinates: { chromosome: "chr17", position: 80796816 },
    },
    {
      fdr: 1.3303775394029247,
      trait: "MDD",
      score: 0.012825489,
      TF_impact: "none",
      snp: "rs2250002",
      coordinates: { chromosome: "chr10", position: 71345340 },
    },
    {
      fdr: 1.4385304641206453,
      trait: "MDD",
      score: 0.017224312,
      TF_impact: "pos",
      snp: "rs1035223",
      coordinates: { chromosome: "chr18", position: 54762511 },
    },
    {
      fdr: 1.1821332578082124,
      trait: "MDD",
      score: 0.06649828,
      TF_impact: "none",
      snp: "rs4608489",
      coordinates: { chromosome: "chr2", position: 217063538 },
    },
    {
      fdr: 0.0001242214498579566,
      trait: "ADHD-meta-filtered",
      score: -0.18787098,
      TF_impact: "pos",
      snp: "rs1961979",
      coordinates: { chromosome: "chr4", position: 89001286 },
    },
    {
      fdr: 1.6176644713084067,
      trait: "cannabis",
      score: 0.032652378,
      TF_impact: "pos",
      snp: "rs36195228",
      coordinates: { chromosome: "chr8", position: 85056113 },
    },
    {
      fdr: 0.9688292268840479,
      trait: "anxiety",
      score: -0.07342291,
      TF_impact: "none",
      snp: "rs4757754",
      coordinates: { chromosome: "chr11", position: 19110654 },
    },
    {
      fdr: 1.3208018670864563,
      trait: "opioid-unexposed-vs-exposed",
      score: 0.012294292,
      TF_impact: "none",
      snp: "rs639218",
      coordinates: { chromosome: "chr17", position: 77273809 },
    },
    {
      fdr: 0.0015930394988797338,
      trait: "ADHD-meta-filtered",
      score: 0.16471386,
      TF_impact: "pos",
      snp: "rs7549876",
      coordinates: { chromosome: "chr1", position: 43294565 },
    },
    {
      fdr: 1.5166867364792989,
      trait: "cannabis",
      score: 0.021264553,
      TF_impact: "none",
      snp: "rs1922785",
      coordinates: { chromosome: "chr2", position: 58702543 },
    },
    {
      fdr: 1.5124538260978362,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.021222591,
      TF_impact: "none",
      snp: "rs13242167",
      coordinates: { chromosome: "chr7", position: 37037296 },
    },
    {
      fdr: 1.6175001914359544,
      trait: "MDD",
      score: -0.033069134,
      TF_impact: "none",
      snp: "rs62038334",
      coordinates: { chromosome: "chr16", position: 12168207 },
    },
    {
      fdr: 0.05378322638242636,
      trait: "MDD",
      score: 0.12708473,
      TF_impact: "none",
      snp: "rs6499040",
      coordinates: { chromosome: "chr16", position: 65787054 },
    },
    {
      fdr: 1.0201828790175076,
      trait: "autism",
      score: -0.001180172,
      TF_impact: "neg",
      snp: "rs2349075",
      coordinates: { chromosome: "chr2", position: 201358843 },
    },
    {
      fdr: 0.07014125841497003,
      trait: "bipolar-I",
      score: -0.12340069,
      TF_impact: "pos",
      snp: "rs1427281",
      coordinates: { chromosome: "chr15", position: 58667074 },
    },
    {
      fdr: 0.0026108421177891746,
      trait: "MDD",
      score: 0.16035962,
      TF_impact: "pos",
      snp: "rs2403202",
      coordinates: { chromosome: "chr14", position: 103613705 },
    },
    {
      fdr: 1.5780107083982164,
      trait: "cannabis",
      score: 0.04483986,
      TF_impact: "none",
      snp: "rs7793710",
      coordinates: { chromosome: "chr7", position: 73557201 },
    },
    {
      fdr: 1.576081624867468,
      trait: "MDD",
      score: 0.043780804,
      TF_impact: "none",
      snp: "rs9998816",
      coordinates: { chromosome: "chr4", position: 158914546 },
    },
    {
      fdr: 2.9149064324637947e-5,
      trait: "MDD",
      score: -0.19882536,
      TF_impact: "none",
      snp: "rs7776539",
      coordinates: { chromosome: "chr7", position: 3290148 },
    },
    {
      fdr: 1.2443961772178724,
      trait: "autism",
      score: -0.009503841,
      TF_impact: "pos",
      snp: "rs62064665",
      coordinates: { chromosome: "chr17", position: 46004161 },
    },
    {
      fdr: 0.008545862094553443,
      trait: "cannabis",
      score: -0.14867163,
      TF_impact: "pos",
      snp: "rs28453960",
      coordinates: { chromosome: "chr22", position: 49933522 },
    },
    {
      fdr: 0.12851533998789189,
      trait: "bipolar-2019",
      score: -0.114326954,
      TF_impact: "none",
      snp: "rs3024747",
      coordinates: { chromosome: "chr13", position: 113167473 },
    },
    {
      fdr: 0.7137773324847225,
      trait: "MDD",
      score: -0.08237839,
      TF_impact: "neg",
      snp: "rs12526735",
      coordinates: { chromosome: "chr6", position: 72939099 },
    },
    {
      fdr: 0.23401927266210074,
      trait: "bipolar-2019",
      score: 0.104969025,
      TF_impact: "pos",
      snp: "rs13428225",
      coordinates: { chromosome: "chr2", position: 185351793 },
    },
    {
      fdr: 1.764617075703282e-5,
      trait: "autism",
      score: 0.20310116,
      TF_impact: "none",
      snp: "rs3745300",
      coordinates: { chromosome: "chr19", position: 49155618 },
    },
    {
      fdr: 1.1064871704051553,
      trait: "scz",
      score: 0.0045609474,
      TF_impact: "none",
      snp: "rs6482674",
      coordinates: { chromosome: "chr10", position: 128390728 },
    },
    {
      fdr: 2.870250592152828e-5,
      trait: "bipolar-2019",
      score: -0.19919252,
      TF_impact: "neg",
      snp: "rs1013278",
      coordinates: { chromosome: "chr7", position: 117963766 },
    },
    {
      fdr: 1.0253005368609736,
      trait: "bipolar-I",
      score: -0.0014166832,
      TF_impact: "none",
      snp: "rs9876378",
      coordinates: { chromosome: "chr3", position: 85022127 },
    },
    {
      fdr: 1.253150157015212,
      trait: "autism",
      score: 0.06428051,
      TF_impact: "pos",
      snp: "rs12992419",
      coordinates: { chromosome: "chr2", position: 141630618 },
    },
    {
      fdr: 1.5027779695548769,
      trait: "anorexia",
      score: -0.020538807,
      TF_impact: "none",
      snp: "rs2074509",
      coordinates: { chromosome: "chr6", position: 30908461 },
    },
    {
      fdr: 1.2445541582286779,
      trait: "cannabis",
      score: 0.0093717575,
      TF_impact: "none",
      snp: "rs521354",
      coordinates: { chromosome: "chr1", position: 156123040 },
    },
    {
      fdr: 1.4931453184677244,
      trait: "autism",
      score: 0.019752026,
      TF_impact: "none",
      snp: "rs1860071",
      coordinates: { chromosome: "chr7", position: 140210460 },
    },
    {
      fdr: 1.2500783679161789,
      trait: "MDD",
      score: 0.009539843,
      TF_impact: "none",
      snp: "rs2997754",
      coordinates: { chromosome: "chr10", position: 75144379 },
    },
    {
      fdr: 1.1148371740448837,
      trait: "anorexia",
      score: 0.0048668385,
      TF_impact: "pos",
      snp: "rs9429742",
      coordinates: { chromosome: "chr1", position: 114380168 },
    },
    {
      fdr: 0.0003993128845710251,
      trait: "MDD",
      score: 0.17756128,
      TF_impact: "none",
      snp: "rs298558",
      coordinates: { chromosome: "chr5", position: 17341167 },
    },
    {
      fdr: 5.100238085037319e-11,
      trait: "bipolar-I",
      score: -0.28351808,
      TF_impact: "pos",
      snp: "rs1214761",
      coordinates: { chromosome: "chr6", position: 43386693 },
    },
    {
      fdr: 1.4829789452798834,
      trait: "anorexia",
      score: -0.019463062,
      TF_impact: "none",
      snp: "rs7831883",
      coordinates: { chromosome: "chr8", position: 26658697 },
    },
    {
      fdr: 3.0622219660796448e-6,
      trait: "MDD",
      score: -0.21695566,
      TF_impact: "none",
      snp: "rs1553597",
      coordinates: { chromosome: "chr18", position: 55833792 },
    },
    {
      fdr: 1.3152780781992535,
      trait: "autism",
      score: 0.012056828,
      TF_impact: "pos",
      snp: "rs10777725",
      coordinates: { chromosome: "chr12", position: 95764126 },
    },
    {
      fdr: 1.6294591823282172,
      trait: "bipolar-2019",
      score: 0.034713745,
      TF_impact: "none",
      snp: "rs716191",
      coordinates: { chromosome: "chr4", position: 94995768 },
    },
    {
      fdr: 1.573246298304562,
      trait: "autism",
      score: 0.043940067,
      TF_impact: "neg",
      snp: "rs11986122",
      coordinates: { chromosome: "chr8", position: 10152439 },
    },
    {
      fdr: 1.63531866526068,
      trait: "bipolar-2012",
      score: -0.03866005,
      TF_impact: "none",
      snp: "rs13012495",
      coordinates: { chromosome: "chr2", position: 98749181 },
    },
    {
      fdr: 0.009878469471737754,
      trait: "anorexia",
      score: 0.14643908,
      TF_impact: "none",
      snp: "rs11814972",
      coordinates: { chromosome: "chr10", position: 98173507 },
    },
    {
      fdr: 0.840551270731994,
      trait: "bipolar-I",
      score: -0.07779217,
      TF_impact: "none",
      snp: "rs923724",
      coordinates: { chromosome: "chr12", position: 99246152 },
    },
    {
      fdr: 1.2773847493796446,
      trait: "anorexia",
      score: -0.010779381,
      TF_impact: "none",
      snp: "rs7964074",
      coordinates: { chromosome: "chr12", position: 57536591 },
    },
    {
      fdr: 1.16664142407552,
      trait: "MDD",
      score: -0.006913185,
      TF_impact: "pos",
      snp: "rs28541419",
      coordinates: { chromosome: "chr15", position: 88402647 },
    },
    {
      fdr: 1.0877846284275539,
      trait: "anorexia",
      score: -0.004053116,
      TF_impact: "pos",
      snp: "rs2090259",
      coordinates: { chromosome: "chr8", position: 51993877 },
    },
    {
      fdr: 1.1444557232450552,
      trait: "scz",
      score: -0.006154537,
      TF_impact: "none",
      snp: "rs12338960",
      coordinates: { chromosome: "chr9", position: 82128493 },
    },
    {
      fdr: 0.4022807201143974,
      trait: "bipolar-I",
      score: -0.09508705,
      TF_impact: "none",
      snp: "rs7259187",
      coordinates: { chromosome: "chr19", position: 2686647 },
    },
    {
      fdr: 0.009431100033230578,
      trait: "ADHD-meta-filtered",
      score: 0.1469264,
      TF_impact: "pos",
      snp: "rs4078520",
      coordinates: { chromosome: "chr11", position: 723429 },
    },
    {
      fdr: 2.722487705893195e-7,
      trait: "autism",
      score: -0.23258352,
      TF_impact: "pos",
      snp: "rs7719215",
      coordinates: { chromosome: "chr5", position: 54601388 },
    },
    {
      fdr: 0.000182571414541746,
      trait: "bipolar-I",
      score: 0.18482208,
      TF_impact: "pos",
      snp: "rs10826321",
      coordinates: { chromosome: "chr10", position: 59580445 },
    },
    {
      fdr: 1.2633938829419765e-6,
      trait: "MDD",
      score: -0.22284985,
      TF_impact: "none",
      snp: "rs4886617",
      coordinates: { chromosome: "chr15", position: 73797452 },
    },
    {
      fdr: 1.5045579090537085,
      trait: "bipolar-2019",
      score: 0.020531178,
      TF_impact: "neg",
      snp: "rs1990565",
      coordinates: { chromosome: "chr19", position: 46289409 },
    },
    {
      fdr: 0.0004174711794905304,
      trait: "MDD",
      score: 0.17697048,
      TF_impact: "pos",
      snp: "rs28377268",
      coordinates: { chromosome: "chr9", position: 95462774 },
    },
    {
      fdr: 1.6054870157550383,
      trait: "ADHD-meta-filtered",
      score: -0.028755665,
      TF_impact: "none",
      snp: "rs10033356",
      coordinates: { chromosome: "chr4", position: 80037099 },
    },
    {
      fdr: 1.2267627151679308,
      trait: "anorexia",
      score: 0.008669853,
      TF_impact: "none",
      snp: "rs1264307",
      coordinates: { chromosome: "chr6", position: 30912980 },
    },
    {
      fdr: 1.063801957623656,
      trait: "anorexia",
      score: 0.0028910637,
      TF_impact: "neg",
      snp: "rs28544076",
      coordinates: { chromosome: "chr1", position: 241950596 },
    },
    {
      fdr: 0.19223982470398024,
      trait: "bipolar-2019",
      score: 0.10816145,
      TF_impact: "pos",
      snp: "rs58988283",
      coordinates: { chromosome: "chr2", position: 209261433 },
    },
    {
      fdr: 1.2392059617695028,
      trait: "MDD",
      score: 0.009167671,
      TF_impact: "none",
      snp: "rs376358",
      coordinates: { chromosome: "chr2", position: 104786677 },
    },
    {
      fdr: 0.9322618879797235,
      trait: "autism",
      score: 0.07471609,
      TF_impact: "pos",
      snp: "rs413778",
      coordinates: { chromosome: "chr17", position: 45639519 },
    },
    {
      fdr: 1.4781575271754477,
      trait: "bipolar-I",
      score: 0.019153118,
      TF_impact: "pos",
      snp: "rs11717383",
      coordinates: { chromosome: "chr3", position: 52253452 },
    },
    {
      fdr: 3.919085840615976e-5,
      trait: "pgc-panic-disorder",
      score: -0.19641829,
      TF_impact: "none",
      snp: "rs3949492",
      coordinates: { chromosome: "chr20", position: 4040488 },
    },
    {
      fdr: 0.001262020968977273,
      trait: "bipolar-2019",
      score: 0.16689491,
      TF_impact: "none",
      snp: "rs12754658",
      coordinates: { chromosome: "chr1", position: 59072800 },
    },
    {
      fdr: 0.4454399325197137,
      trait: "bipolar-2019",
      score: -0.09272003,
      TF_impact: "neg",
      snp: "rs3817030",
      coordinates: { chromosome: "chr16", position: 69316132 },
    },
    {
      fdr: 0.004312418916541921,
      trait: "MDD",
      score: 0.15551043,
      TF_impact: "none",
      snp: "rs359708",
      coordinates: { chromosome: "chr2", position: 126370357 },
    },
    {
      fdr: 1.5587512280010283,
      trait: "ADHD-meta-filtered",
      score: 0.047484875,
      TF_impact: "pos",
      snp: "rs9560010",
      coordinates: { chromosome: "chr13", position: 111214173 },
    },
    {
      fdr: 0.4327979651818529,
      trait: "anorexia",
      score: 0.09307432,
      TF_impact: "none",
      snp: "rs3818524",
      coordinates: { chromosome: "chr6", position: 33692918 },
    },
    {
      fdr: 1.6406938201821e-5,
      trait: "autism",
      score: 0.20373487,
      TF_impact: "none",
      snp: "rs6928669",
      coordinates: { chromosome: "chr6", position: 23689021 },
    },
    {
      fdr: 1.4415398072615833,
      trait: "opioid-unexposed-vs-exposed",
      score: 0.05620432,
      TF_impact: "none",
      snp: "rs1715491",
      coordinates: { chromosome: "chr17", position: 77282742 },
    },
    {
      fdr: 1.6252095448034387,
      trait: "anorexia",
      score: -0.036375523,
      TF_impact: "pos",
      snp: "rs824202",
      coordinates: { chromosome: "chr15", position: 23764434 },
    },
    {
      fdr: 0.17438269265368708,
      trait: "ADHD-meta-filtered",
      score: 0.10967827,
      TF_impact: "none",
      snp: "rs62206557",
      coordinates: { chromosome: "chr20", position: 37180220 },
    },
    {
      fdr: 0.0004762287145057847,
      trait: "ADHD-meta-filtered",
      score: 0.17586803,
      TF_impact: "none",
      snp: "rs1249582",
      coordinates: { chromosome: "chr11", position: 68998427 },
    },
    {
      fdr: 6.627393342430431e-12,
      trait: "MDD",
      score: 0.2980237,
      TF_impact: "pos",
      snp: "rs13248317",
      coordinates: { chromosome: "chr8", position: 20423431 },
    },
    {
      fdr: 1.2459133367190458,
      trait: "bipolar-2019",
      score: -0.009562492,
      TF_impact: "none",
      snp: "rs10263430",
      coordinates: { chromosome: "chr7", position: 29975830 },
    },
    {
      fdr: 3.13386249959013e-7,
      trait: "bipolar-2019",
      score: -0.23154068,
      TF_impact: "pos",
      snp: "rs6426841",
      coordinates: { chromosome: "chr1", position: 163820413 },
    },
    {
      fdr: 1.5264033237930936,
      trait: "bipolar-II",
      score: 0.021909714,
      TF_impact: "none",
      snp: "rs77570544",
      coordinates: { chromosome: "chr4", position: 111363088 },
    },
    {
      fdr: 1.7740637956051785e-5,
      trait: "cannabis",
      score: -0.20298862,
      TF_impact: "pos",
      snp: "rs1348716",
      coordinates: { chromosome: "chr5", position: 61451158 },
    },
    {
      fdr: 1.5262235802856936,
      trait: "ADHD-meta-filtered",
      score: 0.05044031,
      TF_impact: "pos",
      snp: "rs7664633",
      coordinates: { chromosome: "chr4", position: 175807763 },
    },
    {
      fdr: 1.6077952665547581,
      trait: "bipolar-2019",
      score: 0.028757095,
      TF_impact: "pos",
      snp: "rs10414516",
      coordinates: { chromosome: "chr19", position: 2019759 },
    },
    {
      fdr: 1.225694356593695,
      trait: "ADHD-meta-filtered",
      score: 0.00865221,
      TF_impact: "pos",
      snp: "rs12483621",
      coordinates: { chromosome: "chr21", position: 36450798 },
    },
    {
      fdr: 0.056474085532756305,
      trait: "MDD",
      score: -0.1260848,
      TF_impact: "none",
      snp: "rs11853271",
      coordinates: { chromosome: "chr15", position: 90482598 },
    },
    {
      fdr: 0.8416270392747669,
      trait: "bipolar-I",
      score: 0.07766867,
      TF_impact: "pos",
      snp: "rs184301136",
      coordinates: { chromosome: "chr12", position: 57591140 },
    },
    {
      fdr: 0.9515452846823383,
      trait: "autism",
      score: 0.07408857,
      TF_impact: "none",
      snp: "rs4841541",
      coordinates: { chromosome: "chr8", position: 11454320 },
    },
    {
      fdr: 0.9617693230699587,
      trait: "autism",
      score: 0.07356405,
      TF_impact: "none",
      snp: "rs74466898",
      coordinates: { chromosome: "chr18", position: 58192707 },
    },
    {
      fdr: 0.30080524564602046,
      trait: "anorexia",
      score: -0.10089636,
      TF_impact: "none",
      snp: "rs3804771",
      coordinates: { chromosome: "chr3", position: 141972565 },
    },
    {
      fdr: 1.2656549598798532,
      trait: "MDD",
      score: 0.010147572,
      TF_impact: "pos",
      snp: "rs12892062",
      coordinates: { chromosome: "chr14", position: 103417175 },
    },
    {
      fdr: 1.076866759093618,
      trait: "bipolar-2019",
      score: -0.0035467148,
      TF_impact: "pos",
      snp: "rs6080766",
      coordinates: { chromosome: "chr20", position: 17662462 },
    },
    {
      fdr: 1.4971238697351892,
      trait: "opioid-unexposed-vs-exposed",
      score: 0.0200243,
      TF_impact: "neg",
      snp: "rs1966072",
      coordinates: { chromosome: "chr8", position: 104324084 },
    },
    {
      fdr: 0.005687193153466059,
      trait: "MDD",
      score: -0.1528287,
      TF_impact: "pos",
      snp: "rs9564313",
      coordinates: { chromosome: "chr13", position: 66349555 },
    },
    {
      fdr: 1.5470252741949997,
      trait: "cannabis",
      score: 0.02409935,
      TF_impact: "pos",
      snp: "rs3903344",
      coordinates: { chromosome: "chr8", position: 85032135 },
    },
    {
      fdr: 1.797178121012965e-5,
      trait: "MDD",
      score: -0.20304585,
      TF_impact: "pos",
      snp: "rs1406713",
      coordinates: { chromosome: "chr15", position: 92918033 },
    },
    {
      fdr: 0.0007680143524167251,
      trait: "autism",
      score: 0.1717391,
      TF_impact: "pos",
      snp: "rs73129824",
      coordinates: { chromosome: "chr20", position: 21554779 },
    },
    {
      fdr: 1.6219858228799149,
      trait: "bipolar-2019",
      score: -0.03407669,
      TF_impact: "none",
      snp: "rs2673582",
      coordinates: { chromosome: "chr8", position: 132507817 },
    },
    {
      fdr: 0.9765659416439946,
      trait: "anorexia",
      score: -0.073132515,
      TF_impact: "pos",
      snp: "rs167849",
      coordinates: { chromosome: "chr1", position: 96389200 },
    },
    {
      fdr: 0.0007890036520580782,
      trait: "autism",
      score: -0.17143202,
      TF_impact: "none",
      snp: "rs66691214",
      coordinates: { chromosome: "chr7", position: 30149188 },
    },
    {
      fdr: 0.03860773304192226,
      trait: "MDD",
      score: -0.13166332,
      TF_impact: "pos",
      snp: "rs11609859",
      coordinates: { chromosome: "chr12", position: 124804115 },
    },
    {
      fdr: 0.7148464994926609,
      trait: "bipolar-I",
      score: -0.08240938,
      TF_impact: "pos",
      snp: "rs4964649",
      coordinates: { chromosome: "chr12", position: 108175527 },
    },
    {
      fdr: 1.459480036139301,
      trait: "bipolar-2019",
      score: 0.05528164,
      TF_impact: "none",
      snp: "rs11640354",
      coordinates: { chromosome: "chr16", position: 12363065 },
    },
    {
      fdr: 1.5707708134927203,
      trait: "cannabis",
      score: -0.048160553,
      TF_impact: "none",
      snp: "rs611005",
      coordinates: { chromosome: "chr18", position: 35078630 },
    },
    {
      fdr: 1.3821005773624924,
      trait: "bipolar-I",
      score: 0.059135437,
      TF_impact: "neg",
      snp: "rs6065778",
      coordinates: { chromosome: "chr20", position: 45059511 },
    },
    {
      fdr: 1.364394422035104,
      trait: "autism",
      score: 0.014063835,
      TF_impact: "pos",
      snp: "rs12547721",
      coordinates: { chromosome: "chr8", position: 8713161 },
    },
    {
      fdr: 1.114888835073089,
      trait: "anxiety",
      score: 0.004887104,
      TF_impact: "none",
      snp: "rs72817564",
      coordinates: { chromosome: "chr5", position: 165151668 },
    },
    {
      fdr: 7.049235973173097e-6,
      trait: "ADHD-meta-filtered",
      score: 0.21022654,
      TF_impact: "none",
      snp: "rs5745568",
      coordinates: { chromosome: "chr6", position: 33580617 },
    },
    {
      fdr: 0.00037110336079799837,
      trait: "MDD",
      score: -0.17856932,
      TF_impact: "pos",
      snp: "rs9462535",
      coordinates: { chromosome: "chr6", position: 39348016 },
    },
    {
      fdr: 1.1505585483977525,
      trait: "anxiety",
      score: -0.006398678,
      TF_impact: "none",
      snp: "rs7795555",
      coordinates: { chromosome: "chr7", position: 154104933 },
    },
    {
      fdr: 1.4797296501316368,
      trait: "MDD",
      score: -0.019372463,
      TF_impact: "neg",
      snp: "rs4907311",
      coordinates: { chromosome: "chr2", position: 96345624 },
    },
    {
      fdr: 1.6197211473447795,
      trait: "autism",
      score: 0.030435562,
      TF_impact: "none",
      snp: "rs62057151",
      coordinates: { chromosome: "chr17", position: 45826476 },
    },
    {
      fdr: 1.1081684779967664,
      trait: "bipolar-2019",
      score: -0.00481081,
      TF_impact: "none",
      snp: "rs56303180",
      coordinates: { chromosome: "chr14", position: 99253536 },
    },
    {
      fdr: 5.909596091175391e-11,
      trait: "bipolar-I",
      score: 0.2815733,
      TF_impact: "none",
      snp: "rs2286940",
      coordinates: { chromosome: "chr3", position: 37028615 },
    },
    {
      fdr: 1.2178873825470249,
      trait: "autism",
      score: 0.065190315,
      TF_impact: "pos",
      snp: "rs11685464",
      coordinates: { chromosome: "chr2", position: 236875967 },
    },
    {
      fdr: 0.5139658236170919,
      trait: "anxiety",
      score: -0.089429855,
      TF_impact: "none",
      snp: "rs66477321",
      coordinates: { chromosome: "chr17", position: 33523806 },
    },
    {
      fdr: 1.319530855510509,
      trait: "cannabis",
      score: 0.012244701,
      TF_impact: "none",
      snp: "rs66791174",
      coordinates: { chromosome: "chr3", position: 100517049 },
    },
    {
      fdr: 0.0003714703431072226,
      trait: "bipolar-I",
      score: -0.17877865,
      TF_impact: "none",
      snp: "rs11990215",
      coordinates: { chromosome: "chr8", position: 143995983 },
    },
    {
      fdr: 0.007455956221630704,
      trait: "ADHD-meta-filtered",
      score: -0.150033,
      TF_impact: "none",
      snp: "rs2236947",
      coordinates: { chromosome: "chr3", position: 50334001 },
    },
    {
      fdr: 0.03836663503575924,
      trait: "autism",
      score: 0.13146305,
      TF_impact: "none",
      snp: "rs17153498",
      coordinates: { chromosome: "chr8", position: 11589446 },
    },
    {
      fdr: 1.302239894433959e-7,
      trait: "anorexia",
      score: 0.23706913,
      TF_impact: "pos",
      snp: "rs3934393",
      coordinates: { chromosome: "chr6", position: 46410524 },
    },
    {
      fdr: 1.6287860142220927,
      trait: "anorexia",
      score: 0.034526348,
      TF_impact: "pos",
      snp: "rs3227",
      coordinates: { chromosome: "chr6", position: 33694518 },
    },
    {
      fdr: 0.005373115134471292,
      trait: "bipolar-2019",
      score: -0.15347862,
      TF_impact: "none",
      snp: "rs56293342",
      coordinates: { chromosome: "chr2", position: 161427107 },
    },
    {
      fdr: 0.011218606975561261,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.1451478,
      TF_impact: "pos",
      snp: "rs6747398",
      coordinates: { chromosome: "chr2", position: 64922741 },
    },
    {
      fdr: 0.38267106945534013,
      trait: "MDD",
      score: -0.096279144,
      TF_impact: "none",
      snp: "rs146299656",
      coordinates: { chromosome: "chr9", position: 4555436 },
    },
    {
      fdr: 1.496172635191732,
      trait: "autism",
      score: -0.020096302,
      TF_impact: "pos",
      snp: "rs56101454",
      coordinates: { chromosome: "chr6", position: 29685936 },
    },
    {
      fdr: 0.00036596423353319764,
      trait: "bipolar-I",
      score: -0.17911386,
      TF_impact: "pos",
      snp: "rs7656375",
      coordinates: { chromosome: "chr4", position: 142840715 },
    },
    {
      fdr: 1.3652751388978233,
      trait: "bipolar-2019",
      score: -0.06023693,
      TF_impact: "none",
      snp: "rs2043332",
      coordinates: { chromosome: "chr19", position: 10780563 },
    },
    {
      fdr: 1.2880589830759293,
      trait: "bipolar-I",
      score: -0.06306124,
      TF_impact: "pos",
      snp: "rs58231871",
      coordinates: { chromosome: "chr7", position: 27604074 },
    },
    {
      fdr: 0.5529394594205357,
      trait: "cannabis",
      score: 0.08774233,
      TF_impact: "pos",
      snp: "rs4647102",
      coordinates: { chromosome: "chr5", position: 60903536 },
    },
    {
      fdr: 1.3998136239290924,
      trait: "MDD",
      score: -0.015727043,
      TF_impact: "none",
      snp: "rs113670431",
      coordinates: { chromosome: "chr4", position: 47270605 },
    },
    {
      fdr: 1.618164590663747,
      trait: "MDD",
      score: -0.033050537,
      TF_impact: "none",
      snp: "rs12694082",
      coordinates: { chromosome: "chr2", position: 207252032 },
    },
    {
      fdr: 1.5775848121329474,
      trait: "autism",
      score: 0.044670105,
      TF_impact: "pos",
      snp: "rs1497027",
      coordinates: { chromosome: "chr9", position: 73548438 },
    },
    {
      fdr: 1.4452131777393387,
      trait: "cannabis",
      score: -0.017629623,
      TF_impact: "none",
      snp: "rs41483748",
      coordinates: { chromosome: "chr2", position: 40258384 },
    },
    {
      fdr: 1.5113487541919421,
      trait: "anorexia",
      score: -0.05241394,
      TF_impact: "none",
      snp: "rs9830600",
      coordinates: { chromosome: "chr3", position: 94414768 },
    },
    {
      fdr: 0.06701416439593026,
      trait: "autism",
      score: 0.12392521,
      TF_impact: "none",
      snp: "rs3989103",
      coordinates: { chromosome: "chr8", position: 11449337 },
    },
    {
      fdr: 0.0017077304161663973,
      trait: "cannabis",
      score: -0.16424608,
      TF_impact: "none",
      snp: "rs17509575",
      coordinates: { chromosome: "chr2", position: 117889976 },
    },
    {
      fdr: 1.555275693874421,
      trait: "bipolar-2019",
      score: 0.04634285,
      TF_impact: "none",
      snp: "rs34001546",
      coordinates: { chromosome: "chr1", position: 150487239 },
    },
    {
      fdr: 1.6332776349186544,
      trait: "anorexia",
      score: 0.038821697,
      TF_impact: "pos",
      snp: "rs10400567",
      coordinates: { chromosome: "chr12", position: 49331937 },
    },
    {
      fdr: 1.2783431726619792,
      trait: "bipolar-2019",
      score: 0.010727406,
      TF_impact: "pos",
      snp: "rs13157748",
      coordinates: { chromosome: "chr5", position: 59831832 },
    },
    {
      fdr: 1.5150611202668725,
      trait: "bipolar-I",
      score: 0.051647663,
      TF_impact: "none",
      snp: "rs2836762",
      coordinates: { chromosome: "chr21", position: 38931202 },
    },
    {
      fdr: 1.2086178951323903,
      trait: "MDD",
      score: -0.008285046,
      TF_impact: "none",
      snp: "rs7923465",
      coordinates: { chromosome: "chr10", position: 12719685 },
    },
    {
      fdr: 3.24934334129846e-6,
      trait: "cannabis",
      score: 0.21569014,
      TF_impact: "pos",
      snp: "rs10767439",
      coordinates: { chromosome: "chr11", position: 25734803 },
    },
    {
      fdr: 1.5761414108715055,
      trait: "cannabis",
      score: 0.044002533,
      TF_impact: "none",
      snp: "rs7807955",
      coordinates: { chromosome: "chr7", position: 37475854 },
    },
    {
      fdr: 0.0006588516684706329,
      trait: "bipolar-2019",
      score: 0.17302799,
      TF_impact: "none",
      snp: "rs1007537",
      coordinates: { chromosome: "chr15", position: 77620375 },
    },
    {
      fdr: 0.0013655241203363174,
      trait: "bipolar-2019",
      score: -0.166358,
      TF_impact: "none",
      snp: "rs6751612",
      coordinates: { chromosome: "chr2", position: 209425702 },
    },
    {
      fdr: 1.62019248486974,
      trait: "bipolar-I",
      score: 0.033780098,
      TF_impact: "pos",
      snp: "rs576",
      coordinates: { chromosome: "chr3", position: 69967316 },
    },
    {
      fdr: 1.3142356174059697,
      trait: "bipolar-I",
      score: -0.012116432,
      TF_impact: "none",
      snp: "rs11242430",
      coordinates: { chromosome: "chr5", position: 138487177 },
    },
    {
      fdr: 0.00025705797764391427,
      trait: "anorexia",
      score: -0.18191957,
      TF_impact: "none",
      snp: "rs6668345",
      coordinates: { chromosome: "chr1", position: 71565599 },
    },
    {
      fdr: 3.1845381894477214e-6,
      trait: "anxiety",
      score: -0.21632242,
      TF_impact: "none",
      snp: "rs2600073",
      coordinates: { chromosome: "chr3", position: 10804259 },
    },
    {
      fdr: 1.5581954283400308,
      trait: "ADHD-meta-filtered",
      score: 0.047366142,
      TF_impact: "none",
      snp: "rs10950400",
      coordinates: { chromosome: "chr7", position: 1842834 },
    },
    {
      fdr: 0.012563564532778376,
      trait: "bipolar-2019",
      score: 0.14394236,
      TF_impact: "pos",
      snp: "rs35138622",
      coordinates: { chromosome: "chr19", position: 58401421 },
    },
    {
      fdr: 1.6208454202156248,
      trait: "anxiety",
      score: 0.04022789,
      TF_impact: "pos",
      snp: "rs12917636",
      coordinates: { chromosome: "chr16", position: 55036277 },
    },
    {
      fdr: 1.607356917759277,
      trait: "bipolar-I",
      score: -0.028933048,
      TF_impact: "none",
      snp: "rs7570730",
      coordinates: { chromosome: "chr2", position: 27799808 },
    },
    {
      fdr: 4.588121558673949e-9,
      trait: "autism",
      score: 0.2569661,
      TF_impact: "pos",
      snp: "rs327287",
      coordinates: { chromosome: "chr5", position: 38642450 },
    },
    {
      fdr: 7.269296735463821e-6,
      trait: "pgc-panic-disorder",
      score: 0.21028471,
      TF_impact: "pos",
      snp: "rs1865093",
      coordinates: { chromosome: "chr19", position: 39445494 },
    },
    {
      fdr: 1.194204989998354,
      trait: "anorexia",
      score: 0.007646084,
      TF_impact: "none",
      snp: "rs67060340",
      coordinates: { chromosome: "chr3", position: 48877888 },
    },
    {
      fdr: 1.17167130398133,
      trait: "MDD",
      score: -0.0071024895,
      TF_impact: "pos",
      snp: "rs71546165",
      coordinates: { chromosome: "chr7", position: 54327009 },
    },
    {
      fdr: 1.098473660716105,
      trait: "bipolar-2019",
      score: -0.06949425,
      TF_impact: "none",
      snp: "rs941520",
      coordinates: { chromosome: "chr14", position: 99243365 },
    },
    {
      fdr: 0.8606007631393966,
      trait: "ADHD-meta-filtered",
      score: -0.07726288,
      TF_impact: "none",
      snp: "rs2498442",
      coordinates: { chromosome: "chr6", position: 98045334 },
    },
    {
      fdr: 0.05026796870261333,
      trait: "ADHD-meta-filtered",
      score: 0.12820911,
      TF_impact: "pos",
      snp: "rs4690138",
      coordinates: { chromosome: "chr4", position: 80017552 },
    },
    {
      fdr: 0.11788534216728674,
      trait: "MDD",
      score: -0.1160264,
      TF_impact: "none",
      snp: "rs9920038",
      coordinates: { chromosome: "chr15", position: 66415523 },
    },
    {
      fdr: 0.0015902379994653538,
      trait: "ADHD-meta-filtered",
      score: -0.1649108,
      TF_impact: "pos",
      snp: "rs718571",
      coordinates: { chromosome: "chr18", position: 42642213 },
    },
    {
      fdr: 1.0493959379533004,
      trait: "ADHD-meta-filtered",
      score: 0.07085943,
      TF_impact: "none",
      snp: "rs17254786",
      coordinates: { chromosome: "chr3", position: 43247135 },
    },
    {
      fdr: 1.3271688101616237,
      trait: "anorexia",
      score: -0.06196928,
      TF_impact: "pos",
      snp: "rs7796558",
      coordinates: { chromosome: "chr7", position: 104089931 },
    },
    {
      fdr: 1.5779537898156963,
      trait: "MDD",
      score: -0.044788837,
      TF_impact: "neg",
      snp: "rs77328845",
      coordinates: { chromosome: "chr10", position: 107299926 },
    },
    {
      fdr: 0.43228264958329354,
      trait: "anxiety",
      score: 0.09315348,
      TF_impact: "none",
      snp: "rs61141806",
      coordinates: { chromosome: "chr4", position: 169900557 },
    },
    {
      fdr: 0.5591296938631488,
      trait: "ADHD-meta-filtered",
      score: -0.087626934,
      TF_impact: "pos",
      snp: "rs57191490",
      coordinates: { chromosome: "chr12", position: 49671106 },
    },
    {
      fdr: 0.0035482095858788924,
      trait: "bipolar-2012",
      score: 0.157413,
      TF_impact: "none",
      snp: "rs940455",
      coordinates: { chromosome: "chr7", position: 146966476 },
    },
    {
      fdr: 1.5242557245101358,
      trait: "bipolar-2019",
      score: 0.050971508,
      TF_impact: "none",
      snp: "rs329304",
      coordinates: { chromosome: "chr5", position: 134562139 },
    },
    {
      fdr: 1.283052763132915,
      trait: "bipolar-I",
      score: 0.010947466,
      TF_impact: "pos",
      snp: "rs138322482",
      coordinates: { chromosome: "chr8", position: 26240376 },
    },
    {
      fdr: 0.481681799975037,
      trait: "MDD",
      score: -0.09086609,
      TF_impact: "pos",
      snp: "rs7269614",
      coordinates: { chromosome: "chr20", position: 47205863 },
    },
    {
      fdr: 1.2442701506000402,
      trait: "bipolar-I",
      score: -0.009482384,
      TF_impact: "pos",
      snp: "rs17177789",
      coordinates: { chromosome: "chr14", position: 63212991 },
    },
    {
      fdr: 1.2791211680506687,
      trait: "anorexia",
      score: 0.010781288,
      TF_impact: "none",
      snp: "rs2803340",
      coordinates: { chromosome: "chr1", position: 1946632 },
    },
    {
      fdr: 4.9530896889061574e-9,
      trait: "autism",
      score: 0.25617027,
      TF_impact: "pos",
      snp: "rs16875447",
      coordinates: { chromosome: "chr5", position: 78472665 },
    },
    {
      fdr: 2.3782724678026485e-8,
      trait: "bipolar-I",
      score: 0.24714088,
      TF_impact: "none",
      snp: "rs76369658",
      coordinates: { chromosome: "chr11", position: 134398216 },
    },
    {
      fdr: 1.531119669794467,
      trait: "bipolar-2019",
      score: 0.023156643,
      TF_impact: "none",
      snp: "rs10931887",
      coordinates: { chromosome: "chr2", position: 200104182 },
    },
    {
      fdr: 1.1597016551126522,
      trait: "anxiety",
      score: -0.006738186,
      TF_impact: "none",
      snp: "rs2706240",
      coordinates: { chromosome: "chr13", position: 50682866 },
    },
    {
      fdr: 1.5369981959371017,
      trait: "ADHD-meta-filtered",
      score: 0.023424149,
      TF_impact: "pos",
      snp: "rs727257",
      coordinates: { chromosome: "chr14", position: 91058332 },
    },
    {
      fdr: 1.1395895507265987,
      trait: "bipolar-I",
      score: 0.0058350563,
      TF_impact: "neg",
      snp: "rs2239017",
      coordinates: { chromosome: "chr12", position: 2180485 },
    },
    {
      fdr: 1.3304305667271352,
      trait: "bipolar-II",
      score: -0.061864853,
      TF_impact: "pos",
      snp: "rs9468220",
      coordinates: { chromosome: "chr6", position: 27765197 },
    },
    {
      fdr: 1.1262881902955266,
      trait: "bipolar-2019",
      score: -0.06853533,
      TF_impact: "neg",
      snp: "rs267003",
      coordinates: { chromosome: "chr5", position: 155451382 },
    },
    {
      fdr: 1.5148940779768028,
      trait: "MDD",
      score: 0.052372932,
      TF_impact: "pos",
      snp: "rs309452",
      coordinates: { chromosome: "chr9", position: 37433962 },
    },
    {
      fdr: 0.05258915139141056,
      trait: "bipolar-I",
      score: 0.12745762,
      TF_impact: "pos",
      snp: "rs12575395",
      coordinates: { chromosome: "chr11", position: 111301339 },
    },
    {
      fdr: 1.5631091721906767,
      trait: "autism",
      score: 0.04616165,
      TF_impact: "pos",
      snp: "rs75732533",
      coordinates: { chromosome: "chr7", position: 158868921 },
    },
    {
      fdr: 1.6241182855597962,
      trait: "autism",
      score: -0.03196621,
      TF_impact: "pos",
      snp: "rs62064664",
      coordinates: { chromosome: "chr17", position: 46004096 },
    },
    {
      fdr: 1.158368494486516,
      trait: "bipolar-2012",
      score: 0.006515503,
      TF_impact: "none",
      snp: "rs17149512",
      coordinates: { chromosome: "chr7", position: 87398843 },
    },
    {
      fdr: 8.174707100329709e-5,
      trait: "cannabis",
      score: -0.19103765,
      TF_impact: "pos",
      snp: "rs12237222",
      coordinates: { chromosome: "chr9", position: 83219828 },
    },
    {
      fdr: 1.3843367648522649,
      trait: "bipolar-II",
      score: 0.058986187,
      TF_impact: "pos",
      snp: "rs5758065",
      coordinates: { chromosome: "chr22", position: 40765045 },
    },
    {
      fdr: 1.610891139409977,
      trait: "bipolar-2019",
      score: -0.041541576,
      TF_impact: "neg",
      snp: "rs12724450",
      coordinates: { chromosome: "chr1", position: 150508095 },
    },
    {
      fdr: 1.5639797654006202,
      trait: "ADHD-meta-filtered",
      score: -0.026316166,
      TF_impact: "none",
      snp: "rs463334",
      coordinates: { chromosome: "chr3", position: 13070723 },
    },
    {
      fdr: 0.05426715321124799,
      trait: "bipolar-2019",
      score: -0.12682343,
      TF_impact: "none",
      snp: "rs2005983",
      coordinates: { chromosome: "chr22", position: 43036313 },
    },
    {
      fdr: 1.5300670646987768,
      trait: "bipolar-2019",
      score: 0.02224636,
      TF_impact: "none",
      snp: "rs10819162",
      coordinates: { chromosome: "chr9", position: 126463259 },
    },
    {
      fdr: 1.5180244204997675,
      trait: "autism",
      score: 0.021422386,
      TF_impact: "none",
      snp: "rs7866443",
      coordinates: { chromosome: "chr9", position: 116417597 },
    },
    {
      fdr: 0.204394221117905,
      trait: "MDD",
      score: 0.10721111,
      TF_impact: "none",
      snp: "rs10067299",
      coordinates: { chromosome: "chr5", position: 124660916 },
    },
    {
      fdr: 1.1371178977435272,
      trait: "MDD",
      score: 0.005680561,
      TF_impact: "none",
      snp: "rs4598945",
      coordinates: { chromosome: "chr17", position: 65433859 },
    },
    {
      fdr: 1.4623915427033165,
      trait: "ADHD-meta-filtered",
      score: 0.055401325,
      TF_impact: "none",
      snp: "rs10447760",
      coordinates: { chromosome: "chr7", position: 114083210 },
    },
    {
      fdr: 1.4879738922591832,
      trait: "MDD",
      score: -0.05368185,
      TF_impact: "pos",
      snp: "rs145232240",
      coordinates: { chromosome: "chr4", position: 79598985 },
    },
    {
      fdr: 1.564130503126401,
      trait: "cannabis",
      score: 0.045795918,
      TF_impact: "pos",
      snp: "rs10118853",
      coordinates: { chromosome: "chr9", position: 108889742 },
    },
    {
      fdr: 1.5095148800509663,
      trait: "anorexia",
      score: -0.052253723,
      TF_impact: "none",
      snp: "rs10135188",
      coordinates: { chromosome: "chr14", position: 32004590 },
    },
    {
      fdr: 1.629961119879007,
      trait: "MDD",
      score: 0.037779808,
      TF_impact: "pos",
      snp: "rs12992411",
      coordinates: { chromosome: "chr2", position: 145038465 },
    },
    {
      fdr: 1.0309420073640005,
      trait: "cannabis",
      score: 0.0015153885,
      TF_impact: "none",
      snp: "rs11198872",
      coordinates: { chromosome: "chr10", position: 119315803 },
    },
    {
      fdr: 1.6221835958661137,
      trait: "opioid-vs-exposed-ctrl",
      score: -0.03203249,
      TF_impact: "pos",
      snp: "rs6504407",
      coordinates: { chromosome: "chr17", position: 66275871 },
    },
    {
      fdr: 0.3839585907595512,
      trait: "ADHD-meta-filtered",
      score: 0.095924854,
      TF_impact: "none",
      snp: "rs11596250",
      coordinates: { chromosome: "chr10", position: 104784635 },
    },
    {
      fdr: 0.0009550720969196811,
      trait: "scz",
      score: -0.1698842,
      TF_impact: "none",
      snp: "rs6930933",
      coordinates: { chromosome: "chr6", position: 32415633 },
    },
    {
      fdr: 1.6279903228333503,
      trait: "autism",
      score: 0.036637306,
      TF_impact: "none",
      snp: "rs17762308",
      coordinates: { chromosome: "chr17", position: 45703582 },
    },
    {
      fdr: 1.2816382749133484e-12,
      trait: "autism",
      score: 0.30704832,
      TF_impact: "pos",
      snp: "rs6945690",
      coordinates: { chromosome: "chr7", position: 3722691 },
    },
    {
      fdr: 0.15206600835720693,
      trait: "bipolar-2019",
      score: 0.1117506,
      TF_impact: "none",
      snp: "rs11669127",
      coordinates: { chromosome: "chr19", position: 58492463 },
    },
    {
      fdr: 0.22166814949379607,
      trait: "anxiety",
      score: -0.10616112,
      TF_impact: "neg",
      snp: "rs11246872",
      coordinates: { chromosome: "chr12", position: 131583545 },
    },
    {
      fdr: 1.2682883436654908,
      trait: "autism",
      score: 0.06385231,
      TF_impact: "neg",
      snp: "rs9961111",
      coordinates: { chromosome: "chr18", position: 52160869 },
    },
    {
      fdr: 1.486945516554034,
      trait: "bipolar-2019",
      score: -0.019712925,
      TF_impact: "none",
      snp: "rs566370",
      coordinates: { chromosome: "chr4", position: 151990529 },
    },
    {
      fdr: 0.00029171246182054216,
      trait: "anorexia",
      score: -0.18092346,
      TF_impact: "none",
      snp: "rs1700159",
      coordinates: { chromosome: "chr12", position: 51912002 },
    },
    {
      fdr: 0.008924760022755227,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.1477766,
      TF_impact: "none",
      snp: "rs6741762",
      coordinates: { chromosome: "chr2", position: 196068834 },
    },
    {
      fdr: 4.638744927994534e-5,
      trait: "cannabis",
      score: -0.1951909,
      TF_impact: "pos",
      snp: "rs799168",
      coordinates: { chromosome: "chr7", position: 73636534 },
    },
    {
      fdr: 1.5989928907705042,
      trait: "autism",
      score: -0.028526306,
      TF_impact: "pos",
      snp: "rs10421292",
      coordinates: { chromosome: "chr19", position: 49146241 },
    },
    {
      fdr: 1.485899138544507,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.05386734,
      TF_impact: "none",
      snp: "rs1995755",
      coordinates: { chromosome: "chr17", position: 77287459 },
    },
    {
      fdr: 0.0005930349980370544,
      trait: "bipolar-I",
      score: 0.17393875,
      TF_impact: "none",
      snp: "rs10480087",
      coordinates: { chromosome: "chr7", position: 29923499 },
    },
    {
      fdr: 1.277267938459359,
      trait: "bipolar-I",
      score: 0.010621071,
      TF_impact: "none",
      snp: "rs237475",
      coordinates: { chromosome: "chr20", position: 49432969 },
    },
    {
      fdr: 0.6437747552495191,
      trait: "MDD",
      score: 0.08461094,
      TF_impact: "none",
      snp: "rs4891478",
      coordinates: { chromosome: "chr18", position: 72368663 },
    },
    {
      fdr: 0.6954657002221505,
      trait: "ADHD-meta-filtered",
      score: -0.083199024,
      TF_impact: "pos",
      snp: "rs4783757",
      coordinates: { chromosome: "chr16", position: 61421349 },
    },
    {
      fdr: 1.1646050505780914,
      trait: "bipolar-2019",
      score: -0.0068712234,
      TF_impact: "pos",
      snp: "rs12724815",
      coordinates: { chromosome: "chr1", position: 95084505 },
    },
    {
      fdr: 1.0757803512913153,
      trait: "bipolar-2019",
      score: 0.0033512115,
      TF_impact: "none",
      snp: "rs2667772",
      coordinates: { chromosome: "chr15", position: 77581562 },
    },
    {
      fdr: 2.4951262993364484e-5,
      trait: "anorexia",
      score: -0.2004838,
      TF_impact: "pos",
      snp: "rs9839226",
      coordinates: { chromosome: "chr3", position: 172160313 },
    },
    {
      fdr: 0.0032051900731887616,
      trait: "ADHD-meta-filtered",
      score: 0.15856123,
      TF_impact: "none",
      snp: "rs10759922",
      coordinates: { chromosome: "chr9", position: 117412158 },
    },
    {
      fdr: 0.053721507434940675,
      trait: "MDD",
      score: -0.12670755,
      TF_impact: "none",
      snp: "rs28384416",
      coordinates: { chromosome: "chr12", position: 109477378 },
    },
    {
      fdr: 1.385431659519227,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.015099525,
      TF_impact: "pos",
      snp: "rs11706886",
      coordinates: { chromosome: "chr3", position: 105747211 },
    },
  ],
  "VLPFC-neurons": [
    {
      fdr: 1.3966074242918167e-10,
      trait: "ADHD-meta-filtered",
      score: 0.46029377,
      TF_impact: "none",
      snp: "rs76708321",
      coordinates: { chromosome: "chr2", position: 10810992 },
    },
    {
      fdr: 1.8902596451661955,
      trait: "MDD",
      score: 0.065422535,
      TF_impact: "none",
      snp: "rs7206087",
      coordinates: { chromosome: "chr16", position: 65787218 },
    },
    {
      fdr: 1.866335985316953,
      trait: "ADHD-meta-filtered",
      score: -0.07068062,
      TF_impact: "pos",
      snp: "rs530614",
      coordinates: { chromosome: "chr6", position: 33747004 },
    },
    {
      fdr: 0.07171459150783484,
      trait: "bipolar-2019",
      score: -0.20290375,
      TF_impact: "none",
      snp: "rs28464158",
      coordinates: { chromosome: "chr8", position: 9606262 },
    },
    {
      fdr: 1.8852480474611013,
      trait: "bipolar-2019",
      score: -0.052619457,
      TF_impact: "pos",
      snp: "rs73050128",
      coordinates: { chromosome: "chr7", position: 1922246 },
    },
    {
      fdr: 1.5797923662846127,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.029216766,
      TF_impact: "none",
      snp: "rs4729566",
      coordinates: { chromosome: "chr7", position: 100016690 },
    },
    {
      fdr: 1.8488824447855206,
      trait: "bipolar-2012",
      score: -0.04779911,
      TF_impact: "pos",
      snp: "rs13321742",
      coordinates: { chromosome: "chr3", position: 171802003 },
    },
    {
      fdr: 0.3966822667210127,
      trait: "MDD",
      score: -0.15772343,
      TF_impact: "pos",
      snp: "rs34002388",
      coordinates: { chromosome: "chr1", position: 49293168 },
    },
    {
      fdr: 0.008302672119579533,
      trait: "bipolar-II",
      score: -0.24610138,
      TF_impact: "none",
      snp: "rs72674307",
      coordinates: { chromosome: "chr14", position: 42215423 },
    },
    {
      fdr: 0.18711144071125263,
      trait: "anxiety",
      score: 0.17833996,
      TF_impact: "pos",
      snp: "rs16869385",
      coordinates: { chromosome: "chr6", position: 32176214 },
    },
    {
      fdr: 0.45495111082824724,
      trait: "bipolar-2019",
      score: -0.152915,
      TF_impact: "pos",
      snp: "rs55848443",
      coordinates: { chromosome: "chr4", position: 37219430 },
    },
    {
      fdr: 1.841097028549368,
      trait: "MDD",
      score: -0.074409485,
      TF_impact: "neg",
      snp: "rs10263892",
      coordinates: { chromosome: "chr7", position: 32785569 },
    },
    {
      fdr: 1.272958540852672,
      trait: "MDD",
      score: 0.012421131,
      TF_impact: "none",
      snp: "rs2000919",
      coordinates: { chromosome: "chr11", position: 112591113 },
    },
    {
      fdr: 1.0180898622399195,
      trait: "scz",
      score: 0.00033283234,
      TF_impact: "none",
      snp: "rs7200336",
      coordinates: { chromosome: "chr16", position: 4372606 },
    },
    {
      fdr: 0.045392654539891095,
      trait: "bipolar-II",
      score: -0.21321869,
      TF_impact: "pos",
      snp: "rs10503626",
      coordinates: { chromosome: "chr8", position: 18689574 },
    },
    {
      fdr: 1.1422531194795051,
      trait: "bipolar-2019",
      score: 0.112298965,
      TF_impact: "none",
      snp: "rs6478741",
      coordinates: { chromosome: "chr9", position: 126478770 },
    },
    {
      fdr: 1.5794820271859678,
      trait: "bipolar-I",
      score: 0.027499676,
      TF_impact: "pos",
      snp: "rs72669161",
      coordinates: { chromosome: "chr4", position: 122537923 },
    },
    {
      fdr: 1.7472191365799443,
      trait: "bipolar-I",
      score: 0.036533356,
      TF_impact: "pos",
      snp: "rs12623170",
      coordinates: { chromosome: "chr2", position: 27797290 },
    },
    {
      fdr: 1.747215815156827,
      trait: "MDD",
      score: 0.08131409,
      TF_impact: "pos",
      snp: "rs6673687",
      coordinates: { chromosome: "chr1", position: 205701241 },
    },
    {
      fdr: 1.1789629873183285,
      trait: "bipolar-2019",
      score: -0.00998354,
      TF_impact: "none",
      snp: "rs1995210",
      coordinates: { chromosome: "chr3", position: 2391127 },
    },
    {
      fdr: 2.145367928896106e-6,
      trait: "bipolar-I",
      score: 0.36041117,
      TF_impact: "none",
      snp: "rs3095337",
      coordinates: { chromosome: "chr6", position: 30769814 },
    },
    {
      fdr: 7.490108988865847e-6,
      trait: "autism",
      score: 0.34574556,
      TF_impact: "pos",
      snp: "rs2945236",
      coordinates: { chromosome: "chr8", position: 8310872 },
    },
    {
      fdr: 0.24340013445780653,
      trait: "MDD",
      score: -0.1725893,
      TF_impact: "none",
      snp: "rs116974312",
      coordinates: { chromosome: "chr9", position: 123457752 },
    },
    {
      fdr: 3.877034581572161e-5,
      trait: "anorexia",
      score: 0.32462025,
      TF_impact: "pos",
      snp: "rs17042057",
      coordinates: { chromosome: "chr2", position: 21268991 },
    },
    {
      fdr: 0.0018467833334499361,
      trait: "bipolar-2019",
      score: -0.27118206,
      TF_impact: "pos",
      snp: "rs11784417",
      coordinates: { chromosome: "chr8", position: 143936584 },
    },
    {
      fdr: 1.023453139270244,
      trait: "cannabis",
      score: -0.12043381,
      TF_impact: "pos",
      snp: "rs1344047",
      coordinates: { chromosome: "chr21", position: 37144721 },
    },
    {
      fdr: 1.1054214561645321,
      trait: "bipolar-II",
      score: -0.11646891,
      TF_impact: "pos",
      snp: "rs75292817",
      coordinates: { chromosome: "chr8", position: 33708848 },
    },
    {
      fdr: 0.00021757514769366046,
      trait: "cannabis",
      score: 0.3003068,
      TF_impact: "pos",
      snp: "rs9566899",
      coordinates: { chromosome: "chr13", position: 41996192 },
    },
    {
      fdr: 1.2479101459838184,
      trait: "autism",
      score: 0.011321306,
      TF_impact: "none",
      snp: "rs1792313",
      coordinates: { chromosome: "chr11", position: 71364562 },
    },
    {
      fdr: 0.004985846842520344,
      trait: "bipolar-2019",
      score: 0.2535138,
      TF_impact: "none",
      snp: "rs1931895",
      coordinates: { chromosome: "chr6", position: 116251808 },
    },
    {
      fdr: 1.2492506284770801,
      trait: "MDD",
      score: 0.011346817,
      TF_impact: "pos",
      snp: "rs12124090",
      coordinates: { chromosome: "chr1", position: 11478363 },
    },
    {
      fdr: 1.6088043793416786,
      trait: "ADHD-meta-filtered",
      score: 0.029251575,
      TF_impact: "pos",
      snp: "rs72773227",
      coordinates: { chromosome: "chr10", position: 16951201 },
    },
    {
      fdr: 0.0008976269830953134,
      trait: "bipolar-2019",
      score: -0.2817068,
      TF_impact: "none",
      snp: "rs17028044",
      coordinates: { chromosome: "chr4", position: 151887741 },
    },
    {
      fdr: 0.0008422155399968074,
      trait: "bipolar-2019",
      score: -0.28293276,
      TF_impact: "none",
      snp: "rs616021",
      coordinates: { chromosome: "chr8", position: 9938864 },
    },
    {
      fdr: 1.8095727817168679,
      trait: "cannabis",
      score: 0.075172424,
      TF_impact: "none",
      snp: "rs7925990",
      coordinates: { chromosome: "chr11", position: 114027745 },
    },
    {
      fdr: 0.16488811653599655,
      trait: "ADHD-meta-filtered",
      score: 0.18195057,
      TF_impact: "pos",
      snp: "rs11741608",
      coordinates: { chromosome: "chr5", position: 104413091 },
    },
    {
      fdr: 1.8716689357313803,
      trait: "bipolar-I",
      score: 0.069523335,
      TF_impact: "neg",
      snp: "rs4714055",
      coordinates: { chromosome: "chr6", position: 37325988 },
    },
    {
      fdr: 1.8364560841985005,
      trait: "anxiety",
      score: -0.074706554,
      TF_impact: "pos",
      snp: "rs857166",
      coordinates: { chromosome: "chr16", position: 13839037 },
    },
    {
      fdr: 0.03376159586882224,
      trait: "bipolar-2019",
      score: 0.21818304,
      TF_impact: "none",
      snp: "rs2413720",
      coordinates: { chromosome: "chr22", position: 42802253 },
    },
    {
      fdr: 1.0178249432091299e-5,
      trait: "MDD",
      score: -0.34344244,
      TF_impact: "none",
      snp: "rs10815291",
      coordinates: { chromosome: "chr9", position: 5844824 },
    },
    {
      fdr: 1.4454084087323056,
      trait: "bipolar-2019",
      score: 0.095495224,
      TF_impact: "none",
      snp: "rs7825085",
      coordinates: { chromosome: "chr8", position: 27740783 },
    },
    {
      fdr: 0.003998731053507403,
      trait: "bipolar-I",
      score: -0.258821,
      TF_impact: "pos",
      snp: "rs8129599",
      coordinates: { chromosome: "chr21", position: 38170889 },
    },
    {
      fdr: 1.0965943472275845,
      trait: "ADHD-meta-filtered",
      score: -0.0059690475,
      TF_impact: "none",
      snp: "rs12534157",
      coordinates: { chromosome: "chr7", position: 155670674 },
    },
    {
      fdr: 0.005805950196993239,
      trait: "anxiety",
      score: -0.2521963,
      TF_impact: "none",
      snp: "rs7101278",
      coordinates: { chromosome: "chr10", position: 95260855 },
    },
    {
      fdr: 0.0002030949304488373,
      trait: "MDD",
      score: -0.30355406,
      TF_impact: "none",
      snp: "rs138157115",
      coordinates: { chromosome: "chr12", position: 52041848 },
    },
    {
      fdr: 2.4192511702407962e-12,
      trait: "bipolar-2019",
      score: 0.49395275,
      TF_impact: "none",
      snp: "rs7394459",
      coordinates: { chromosome: "chr11", position: 61869877 },
    },
    {
      fdr: 0.005220101761549785,
      trait: "ADHD-meta-filtered",
      score: 0.25240088,
      TF_impact: "none",
      snp: "rs7920108",
      coordinates: { chromosome: "chr10", position: 105017323 },
    },
    {
      fdr: 0.19133958789626138,
      trait: "bipolar-I",
      score: 0.17751837,
      TF_impact: "pos",
      snp: "rs11973169",
      coordinates: { chromosome: "chr7", position: 30005798 },
    },
    {
      fdr: 0.4383857082278018,
      trait: "ADHD-meta-filtered",
      score: 0.1524415,
      TF_impact: "none",
      snp: "rs2239339",
      coordinates: { chromosome: "chr16", position: 24218564 },
    },
    {
      fdr: 1.545548604403166,
      trait: "pgc-panic-disorder",
      score: -0.027353287,
      TF_impact: "none",
      snp: "rs2293271",
      coordinates: { chromosome: "chr2", position: 48560780 },
    },
    {
      fdr: 0.012646260272473406,
      trait: "anxiety",
      score: -0.23848677,
      TF_impact: "none",
      snp: "rs6791614",
      coordinates: { chromosome: "chr3", position: 102038604 },
    },
    {
      fdr: 0.8542776654667064,
      trait: "autism",
      score: -0.12748146,
      TF_impact: "none",
      snp: "rs80587",
      coordinates: { chromosome: "chr22", position: 36202003 },
    },
    {
      fdr: 0.00375885429542221,
      trait: "autism",
      score: -0.2597618,
      TF_impact: "pos",
      snp: "rs2045199",
      coordinates: { chromosome: "chr4", position: 60934467 },
    },
    {
      fdr: 2.67466534744087e-8,
      trait: "bipolar-2012",
      score: -0.41058445,
      TF_impact: "pos",
      snp: "rs12380490",
      coordinates: { chromosome: "chr9", position: 110426250 },
    },
    {
      fdr: 1.891635778934996,
      trait: "anorexia",
      score: -0.062484264,
      TF_impact: "none",
      snp: "rs4881291",
      coordinates: { chromosome: "chr10", position: 4108859 },
    },
    {
      fdr: 0.20817663703138675,
      trait: "cannabis",
      score: -0.17698765,
      TF_impact: "pos",
      snp: "rs2597277",
      coordinates: { chromosome: "chr7", position: 37683810 },
    },
    {
      fdr: 1.8850524698112487,
      trait: "scz",
      score: 0.064707756,
      TF_impact: "pos",
      snp: "rs198820",
      coordinates: { chromosome: "chr6", position: 26124015 },
    },
    {
      fdr: 1.6076302272762895,
      trait: "bipolar-I",
      score: 0.029247284,
      TF_impact: "none",
      snp: "rs1442104",
      coordinates: { chromosome: "chr4", position: 47210122 },
    },
    {
      fdr: 1.1873768726291176,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.11157513,
      TF_impact: "none",
      snp: "rs7649466",
      coordinates: { chromosome: "chr3", position: 105869001 },
    },
    {
      fdr: 1.0203436989736439,
      trait: "anxiety",
      score: 0.0004172325,
      TF_impact: "none",
      snp: "rs17628613",
      coordinates: { chromosome: "chr4", position: 170014603 },
    },
    {
      fdr: 7.393142767838138e-8,
      trait: "MDD",
      score: 0.3981738,
      TF_impact: "neg",
      snp: "rs12036585",
      coordinates: { chromosome: "chr1", position: 72178433 },
    },
    {
      fdr: 1.748155920022428,
      trait: "ADHD-meta-filtered",
      score: -0.038238525,
      TF_impact: "none",
      snp: "rs61867294",
      coordinates: { chromosome: "chr10", position: 104809449 },
    },
    {
      fdr: 1.0870161785952734,
      trait: "MDD",
      score: -0.0054240227,
      TF_impact: "pos",
      snp: "rs78757334",
      coordinates: { chromosome: "chr13", position: 26548505 },
    },
    {
      fdr: 1.1523209419069824,
      trait: "anxiety",
      score: 0.11196995,
      TF_impact: "none",
      snp: "rs6706053",
      coordinates: { chromosome: "chr2", position: 44290523 },
    },
    {
      fdr: 1.8952261707220024,
      trait: "bipolar-2019",
      score: -0.056794643,
      TF_impact: "pos",
      snp: "rs325400",
      coordinates: { chromosome: "chr15", position: 99712600 },
    },
    {
      fdr: 0.0018375501953845652,
      trait: "MDD",
      score: 0.26945257,
      TF_impact: "none",
      snp: "rs10493806",
      coordinates: { chromosome: "chr1", position: 88228266 },
    },
    {
      fdr: 1.8920532308362552,
      trait: "bipolar-I",
      score: 0.056573868,
      TF_impact: "none",
      snp: "rs9311270",
      coordinates: { chromosome: "chr3", position: 41352679 },
    },
    {
      fdr: 0.0005756416196545881,
      trait: "bipolar-2019",
      score: 0.28636026,
      TF_impact: "none",
      snp: "rs7010588",
      coordinates: { chromosome: "chr8", position: 143959608 },
    },
    {
      fdr: 1.8703994181389507,
      trait: "bipolar-II",
      score: 0.06861544,
      TF_impact: "neg",
      snp: "rs9896830",
      coordinates: { chromosome: "chr17", position: 51958032 },
    },
    {
      fdr: 1.8747498386418846,
      trait: "bipolar-I",
      score: 0.0478158,
      TF_impact: "pos",
      snp: "rs4859265",
      coordinates: { chromosome: "chr3", position: 183033342 },
    },
    {
      fdr: 1.6148331402503997,
      trait: "bipolar-2019",
      score: -0.031262875,
      TF_impact: "none",
      snp: "rs8087493",
      coordinates: { chromosome: "chr18", position: 43159234 },
    },
    {
      fdr: 1.5901857905748058,
      trait: "bipolar-2019",
      score: 0.02797556,
      TF_impact: "none",
      snp: "rs1078773",
      coordinates: { chromosome: "chr10", position: 12514034 },
    },
    {
      fdr: 0.28924482086730563,
      trait: "anorexia",
      score: -0.16729164,
      TF_impact: "pos",
      snp: "rs12802128",
      coordinates: { chromosome: "chr11", position: 74560127 },
    },
    {
      fdr: 1.4234397069320317,
      trait: "anorexia",
      score: -0.09816456,
      TF_impact: "none",
      snp: "rs7761589",
      coordinates: { chromosome: "chr6", position: 30890470 },
    },
    {
      fdr: 1.8411713890031112,
      trait: "MDD",
      score: -0.074235916,
      TF_impact: "pos",
      snp: "rs940310",
      coordinates: { chromosome: "chr18", position: 54388911 },
    },
    {
      fdr: 1.215254933507987,
      trait: "bipolar-I",
      score: -0.01152277,
      TF_impact: "pos",
      snp: "rs62105751",
      coordinates: { chromosome: "chr19", position: 9847213 },
    },
    {
      fdr: 1.1486614202236112,
      trait: "MDD",
      score: 0.0068035126,
      TF_impact: "pos",
      snp: "rs61901226",
      coordinates: { chromosome: "chr11", position: 112671366 },
    },
    {
      fdr: 1.029269657004999,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.0025920868,
      TF_impact: "pos",
      snp: "rs12602556",
      coordinates: { chromosome: "chr17", position: 67830745 },
    },
    {
      fdr: 0.0028040870250589443,
      trait: "bipolar-I",
      score: -0.26478672,
      TF_impact: "none",
      snp: "rs11242436",
      coordinates: { chromosome: "chr5", position: 138601629 },
    },
    {
      fdr: 7.708993524917472e-5,
      trait: "MDD",
      score: -0.317739,
      TF_impact: "neg",
      snp: "rs357524",
      coordinates: { chromosome: "chr9", position: 95426883 },
    },
    {
      fdr: 1.890208615884605,
      trait: "bipolar-2012",
      score: -0.061841965,
      TF_impact: "none",
      snp: "rs6497007",
      coordinates: { chromosome: "chr15", position: 85333786 },
    },
    {
      fdr: 1.545698403755364,
      trait: "anxiety",
      score: 0.09092665,
      TF_impact: "pos",
      snp: "rs8008675",
      coordinates: { chromosome: "chr14", position: 94868081 },
    },
    {
      fdr: 1.6724610287739903,
      trait: "pgc-panic-disorder",
      score: -0.034006596,
      TF_impact: "pos",
      snp: "rs2857106",
      coordinates: { chromosome: "chr6", position: 32819793 },
    },
    {
      fdr: 2.03305425800704e-7,
      trait: "bipolar-I",
      score: -0.3885846,
      TF_impact: "pos",
      snp: "rs9890374",
      coordinates: { chromosome: "chr17", position: 28265336 },
    },
    {
      fdr: 0.005280530041591287,
      trait: "anorexia",
      score: 0.252069,
      TF_impact: "none",
      snp: "rs56386725",
      coordinates: { chromosome: "chr14", position: 64417633 },
    },
    {
      fdr: 0.0001240596926494085,
      trait: "autism",
      score: 0.30857897,
      TF_impact: "pos",
      snp: "rs12550057",
      coordinates: { chromosome: "chr8", position: 8704758 },
    },
    {
      fdr: 1.7789558096328375,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.08031416,
      TF_impact: "none",
      snp: "rs17770142",
      coordinates: { chromosome: "chr16", position: 83717918 },
    },
    {
      fdr: 1.1929056968836333,
      trait: "MDD",
      score: 0.10886192,
      TF_impact: "none",
      snp: "rs10868001",
      coordinates: { chromosome: "chr9", position: 83366668 },
    },
    {
      fdr: 1.364134977669461,
      trait: "anxiety",
      score: -0.10171366,
      TF_impact: "none",
      snp: "rs72802493",
      coordinates: { chromosome: "chr2", position: 46777070 },
    },
    {
      fdr: 0.00014251154456131198,
      trait: "bipolar-I",
      score: -0.30819368,
      TF_impact: "none",
      snp: "rs1080500",
      coordinates: { chromosome: "chr3", position: 53141001 },
    },
    {
      fdr: 0.008795661950507372,
      trait: "cannabis",
      score: 0.24345636,
      TF_impact: "none",
      snp: "rs754333",
      coordinates: { chromosome: "chr9", position: 108924616 },
    },
    {
      fdr: 0.002203588871085118,
      trait: "bipolar-I",
      score: -0.26832247,
      TF_impact: "none",
      snp: "rs16865132",
      coordinates: { chromosome: "chr2", position: 6410574 },
    },
    {
      fdr: 0.0003238361705500103,
      trait: "bipolar-2019",
      score: 0.29457474,
      TF_impact: "none",
      snp: "rs72769966",
      coordinates: { chromosome: "chr16", position: 9123915 },
    },
    {
      fdr: 1.6477333367894635,
      trait: "ADHD-meta-filtered",
      score: 0.086285114,
      TF_impact: "neg",
      snp: "rs4635202",
      coordinates: { chromosome: "chr13", position: 31044522 },
    },
    {
      fdr: 0.005826218390930878,
      trait: "anorexia",
      score: 0.25036192,
      TF_impact: "none",
      snp: "rs249276",
      coordinates: { chromosome: "chr16", position: 9422827 },
    },
    {
      fdr: 1.482565875837907,
      trait: "MDD",
      score: -0.024184227,
      TF_impact: "none",
      snp: "rs28695930",
      coordinates: { chromosome: "chr8", position: 9606228 },
    },
    {
      fdr: 1.2803819584278251e-5,
      trait: "MDD",
      score: -0.3401184,
      TF_impact: "none",
      snp: "rs12923469",
      coordinates: { chromosome: "chr16", position: 79128723 },
    },
    {
      fdr: 0.014096121897270369,
      trait: "bipolar-I",
      score: 0.23459244,
      TF_impact: "none",
      snp: "rs9559493",
      coordinates: { chromosome: "chr13", position: 109088749 },
    },
    {
      fdr: 9.553946999121784e-5,
      trait: "bipolar-2019",
      score: -0.3141818,
      TF_impact: "none",
      snp: "rs17774047",
      coordinates: { chromosome: "chr15", position: 42721028 },
    },
    {
      fdr: 0.6287574022742795,
      trait: "autism",
      score: 0.13907337,
      TF_impact: "pos",
      snp: "rs73403546",
      coordinates: { chromosome: "chr15", position: 42150625 },
    },
    {
      fdr: 1.3630748347870385,
      trait: "scz",
      score: 0.016667366,
      TF_impact: "none",
      snp: "rs12577525",
      coordinates: { chromosome: "chr11", position: 17238569 },
    },
    {
      fdr: 1.8701214943152786,
      trait: "bipolar-2019",
      score: 0.06861162,
      TF_impact: "none",
      snp: "rs1434984",
      coordinates: { chromosome: "chr2", position: 14641313 },
    },
    {
      fdr: 1.541244366609411,
      trait: "anorexia",
      score: -0.09284639,
      TF_impact: "pos",
      snp: "rs35808061",
      coordinates: { chromosome: "chr11", position: 57779528 },
    },
    {
      fdr: 1.892138419279662,
      trait: "ADHD-meta-filtered",
      score: -0.05795431,
      TF_impact: "pos",
      snp: "rs8040226",
      coordinates: { chromosome: "chr15", position: 47384633 },
    },
    {
      fdr: 1.231898220979878e-6,
      trait: "anxiety",
      score: -0.36831236,
      TF_impact: "none",
      snp: "rs6575469",
      coordinates: { chromosome: "chr14", position: 94868350 },
    },
    {
      fdr: 0.003020206749675537,
      trait: "MDD",
      score: 0.26182938,
      TF_impact: "none",
      snp: "rs7701817",
      coordinates: { chromosome: "chr5", position: 124650872 },
    },
    {
      fdr: 0.0035768100859360267,
      trait: "MDD",
      score: 0.25908375,
      TF_impact: "none",
      snp: "rs74831413",
      coordinates: { chromosome: "chr2", position: 237512878 },
    },
    {
      fdr: 1.7754306238803783,
      trait: "bipolar-II",
      score: -0.040019512,
      TF_impact: "pos",
      snp: "rs16891334",
      coordinates: { chromosome: "chr6", position: 26124075 },
    },
    {
      fdr: 0.6812442348130895,
      trait: "bipolar-2012",
      score: -0.1370058,
      TF_impact: "none",
      snp: "rs11681427",
      coordinates: { chromosome: "chr2", position: 240766810 },
    },
    {
      fdr: 1.753471385899411,
      trait: "bipolar-I",
      score: -0.08263636,
      TF_impact: "pos",
      snp: "rs77457752",
      coordinates: { chromosome: "chr9", position: 13942941 },
    },
    {
      fdr: 7.864955982844039e-5,
      trait: "cannabis",
      score: -0.31741524,
      TF_impact: "pos",
      snp: "rs10520034",
      coordinates: { chromosome: "chr5", position: 129410822 },
    },
    {
      fdr: 1.839431692834351,
      trait: "MDD",
      score: 0.072707176,
      TF_impact: "pos",
      snp: "rs2232015",
      coordinates: { chromosome: "chr1", position: 107056636 },
    },
    {
      fdr: 1.7142458221727348,
      trait: "bipolar-II",
      score: -0.0847106,
      TF_impact: "none",
      snp: "rs55762233",
      coordinates: { chromosome: "chr19", position: 19256510 },
    },
    {
      fdr: 3.641053949232817e-10,
      trait: "MDD",
      score: -0.45184994,
      TF_impact: "none",
      snp: "rs1618400",
      coordinates: { chromosome: "chr11", position: 115707780 },
    },
    {
      fdr: 0.17320770078164127,
      trait: "bipolar-I",
      score: 0.18049908,
      TF_impact: "none",
      snp: "rs1452387",
      coordinates: { chromosome: "chr15", position: 73856484 },
    },
    {
      fdr: 1.298724725577258,
      trait: "autism",
      score: -0.015162468,
      TF_impact: "pos",
      snp: "rs7014953",
      coordinates: { chromosome: "chr8", position: 8310891 },
    },
    {
      fdr: 1.0858176768677505,
      trait: "ADHD-meta-filtered",
      score: 0.003680706,
      TF_impact: "none",
      snp: "rs11165651",
      coordinates: { chromosome: "chr1", position: 96472692 },
    },
    {
      fdr: 0.8611118733826296,
      trait: "bipolar-2019",
      score: 0.12558079,
      TF_impact: "none",
      snp: "rs10173260",
      coordinates: { chromosome: "chr2", position: 209513121 },
    },
    {
      fdr: 0.5481944852879986,
      trait: "anorexia",
      score: 0.14383364,
      TF_impact: "none",
      snp: "rs318198",
      coordinates: { chromosome: "chr16", position: 64207177 },
    },
    {
      fdr: 0.12259677455962886,
      trait: "anxiety",
      score: -0.19056082,
      TF_impact: "none",
      snp: "rs3774117",
      coordinates: { chromosome: "chr3", position: 10857556 },
    },
    {
      fdr: 1.172442112639632,
      trait: "autism",
      score: -0.112556934,
      TF_impact: "none",
      snp: "rs2921074",
      coordinates: { chromosome: "chr8", position: 8503629 },
    },
    {
      fdr: 0.8273060493262268,
      trait: "anorexia",
      score: 0.127532,
      TF_impact: "none",
      snp: "rs2405104",
      coordinates: { chromosome: "chr1", position: 208527388 },
    },
    {
      fdr: 1.8846311323501945,
      trait: "bipolar-2019",
      score: -0.059963226,
      TF_impact: "pos",
      snp: "rs28728306",
      coordinates: { chromosome: "chr7", position: 1922178 },
    },
    {
      fdr: 2.5523504502906285e-5,
      trait: "ADHD-meta-filtered",
      score: -0.33199596,
      TF_impact: "pos",
      snp: "rs6811025",
      coordinates: { chromosome: "chr4", position: 34159044 },
    },
    {
      fdr: 0.00023735029901442766,
      trait: "anxiety",
      score: -0.30076122,
      TF_impact: "none",
      snp: "rs11125089",
      coordinates: { chromosome: "chr2", position: 46781124 },
    },
    {
      fdr: 1.4993464279411775,
      trait: "bipolar-2019",
      score: 0.023295403,
      TF_impact: "pos",
      snp: "rs67263685",
      coordinates: { chromosome: "chr16", position: 9135088 },
    },
    {
      fdr: 0.00217013228593731,
      trait: "bipolar-I",
      score: -0.26871634,
      TF_impact: "none",
      snp: "rs73199533",
      coordinates: { chromosome: "chr12", position: 108175466 },
    },
    {
      fdr: 1.5835587478878599,
      trait: "anorexia",
      score: 0.08910322,
      TF_impact: "none",
      snp: "rs1524682",
      coordinates: { chromosome: "chr4", position: 57379036 },
    },
    {
      fdr: 1.8615380252782032,
      trait: "bipolar-2019",
      score: -0.048649788,
      TF_impact: "none",
      snp: "rs34849561",
      coordinates: { chromosome: "chr13", position: 41716647 },
    },
    {
      fdr: 1.898925701695984,
      trait: "ADHD",
      score: -0.055613518,
      TF_impact: "none",
      snp: "rs2916197",
      coordinates: { chromosome: "chr1", position: 153355859 },
    },
    {
      fdr: 1.6245835873523795,
      trait: "anorexia",
      score: -0.031896114,
      TF_impact: "none",
      snp: "rs7920451",
      coordinates: { chromosome: "chr10", position: 124907807 },
    },
    {
      fdr: 0.3611975841956376,
      trait: "bipolar-I",
      score: -0.16062975,
      TF_impact: "pos",
      snp: "rs11134593",
      coordinates: { chromosome: "chr5", position: 169862631 },
    },
    {
      fdr: 1.068851488013763e-6,
      trait: "anorexia",
      score: -0.37025833,
      TF_impact: "pos",
      snp: "rs3101018",
      coordinates: { chromosome: "chr6", position: 31738087 },
    },
    {
      fdr: 1.518705541649778,
      trait: "autism",
      score: -0.09398079,
      TF_impact: "pos",
      snp: "rs132734",
      coordinates: { chromosome: "chr22", position: 36201831 },
    },
    {
      fdr: 4.842748013749002e-8,
      trait: "bipolar-I",
      score: -0.40401554,
      TF_impact: "pos",
      snp: "rs6576976",
      coordinates: { chromosome: "chr2", position: 96580930 },
    },
    {
      fdr: 1.5941907757478224,
      trait: "scz",
      score: 0.028302193,
      TF_impact: "none",
      snp: "rs500571",
      coordinates: { chromosome: "chr11", position: 83594499 },
    },
    {
      fdr: 0.026889940467672617,
      trait: "bipolar-2019",
      score: 0.22250175,
      TF_impact: "pos",
      snp: "rs596136",
      coordinates: { chromosome: "chr18", position: 11999557 },
    },
    {
      fdr: 0.005011645148580032,
      trait: "pgc-panic-disorder",
      score: -0.25500154,
      TF_impact: "none",
      snp: "rs8042870",
      coordinates: { chromosome: "chr15", position: 96849640 },
    },
    {
      fdr: 1.896971534749727,
      trait: "autism",
      score: 0.06105852,
      TF_impact: "none",
      snp: "rs13264212",
      coordinates: { chromosome: "chr8", position: 11452340 },
    },
    {
      fdr: 0.006251906796813783,
      trait: "bipolar-I",
      score: -0.25089455,
      TF_impact: "pos",
      snp: "rs62239507",
      coordinates: { chromosome: "chr22", position: 50539788 },
    },
    {
      fdr: 1.1054648779980063e-5,
      trait: "ADHD-meta-filtered",
      score: 0.34026146,
      TF_impact: "none",
      snp: "rs6771703",
      coordinates: { chromosome: "chr3", position: 126082224 },
    },
    {
      fdr: 0.6033539521004384,
      trait: "bipolar-2019",
      score: -0.14240646,
      TF_impact: "neg",
      snp: "rs4845677",
      coordinates: { chromosome: "chr1", position: 154865574 },
    },
    {
      fdr: 1.3284096392536344,
      trait: "autism",
      score: 0.015079021,
      TF_impact: "none",
      snp: "rs62054435",
      coordinates: { chromosome: "chr17", position: 45747973 },
    },
    {
      fdr: 0.0010276239756615907,
      trait: "autism",
      score: 0.27804708,
      TF_impact: "none",
      snp: "rs7644373",
      coordinates: { chromosome: "chr3", position: 153997561 },
    },
    {
      fdr: 1.3526046992652978,
      trait: "bipolar-II",
      score: -0.102586746,
      TF_impact: "pos",
      snp: "rs2766557",
      coordinates: { chromosome: "chr6", position: 35757655 },
    },
    {
      fdr: 0.04470207729748437,
      trait: "bipolar-2019",
      score: 0.21188688,
      TF_impact: "none",
      snp: "rs74779340",
      coordinates: { chromosome: "chr7", position: 24781326 },
    },
    {
      fdr: 1.4489065714791112,
      trait: "bipolar-II",
      score: 0.020802975,
      TF_impact: "none",
      snp: "rs1469712",
      coordinates: { chromosome: "chr19", position: 19418012 },
    },
    {
      fdr: 1.7656263790673679,
      trait: "anorexia",
      score: 0.037686348,
      TF_impact: "none",
      snp: "rs11722498",
      coordinates: { chromosome: "chr4", position: 57234479 },
    },
    {
      fdr: 0.008921047115408262,
      trait: "ADHD-meta-filtered",
      score: 0.24308729,
      TF_impact: "pos",
      snp: "rs7810323",
      coordinates: { chromosome: "chr7", position: 1804158 },
    },
    {
      fdr: 1.626315910813748,
      trait: "pgc-panic-disorder",
      score: -0.031959057,
      TF_impact: "none",
      snp: "rs6979076",
      coordinates: { chromosome: "chr7", position: 1076611 },
    },
    {
      fdr: 2.372488287442076e-6,
      trait: "anorexia",
      score: 0.3592415,
      TF_impact: "none",
      snp: "rs2532935",
      coordinates: { chromosome: "chr6", position: 30926796 },
    },
    {
      fdr: 1.8908360740846537,
      trait: "autism",
      score: -0.05657673,
      TF_impact: "pos",
      snp: "rs62063295",
      coordinates: { chromosome: "chr17", position: 45967065 },
    },
    {
      fdr: 0.4654656253985967,
      trait: "pgc-panic-disorder",
      score: -0.15198565,
      TF_impact: "none",
      snp: "rs7203458",
      coordinates: { chromosome: "chr16", position: 73192477 },
    },
    {
      fdr: 1.0573295975975172,
      trait: "bipolar-2019",
      score: -0.118637085,
      TF_impact: "none",
      snp: "rs13000014",
      coordinates: { chromosome: "chr2", position: 98766867 },
    },
    {
      fdr: 8.535539147396753e-5,
      trait: "anxiety",
      score: -0.31601906,
      TF_impact: "none",
      snp: "rs6896953",
      coordinates: { chromosome: "chr5", position: 83089284 },
    },
    {
      fdr: 0.5841438428230481,
      trait: "autism",
      score: 0.14176273,
      TF_impact: "none",
      snp: "rs6431489",
      coordinates: { chromosome: "chr2", position: 236902166 },
    },
    {
      fdr: 0.03492606815477439,
      trait: "bipolar-I",
      score: 0.2175765,
      TF_impact: "pos",
      snp: "rs6805895",
      coordinates: { chromosome: "chr3", position: 156010985 },
    },
    {
      fdr: 1.8357752384411503,
      trait: "scz",
      score: -0.045428753,
      TF_impact: "pos",
      snp: "rs9661794",
      coordinates: { chromosome: "chr1", position: 97870773 },
    },
    {
      fdr: 1.586320051707472,
      trait: "anorexia",
      score: -0.090893745,
      TF_impact: "neg",
      snp: "rs12320284",
      coordinates: { chromosome: "chr12", position: 49429144 },
    },
    {
      fdr: 0.24617112976872288,
      trait: "anorexia",
      score: 0.17065334,
      TF_impact: "pos",
      snp: "rs10445919",
      coordinates: { chromosome: "chr2", position: 23012697 },
    },
    {
      fdr: 1.15438244830906,
      trait: "bipolar-2019",
      score: 0.0070943832,
      TF_impact: "pos",
      snp: "rs1953723",
      coordinates: { chromosome: "chr14", position: 29761297 },
    },
    {
      fdr: 1.6221479809518191,
      trait: "anorexia",
      score: 0.02999568,
      TF_impact: "pos",
      snp: "rs7866620",
      coordinates: { chromosome: "chr9", position: 124802650 },
    },
    {
      fdr: 1.1158828611269898,
      trait: "MDD",
      score: -0.11525297,
      TF_impact: "none",
      snp: "rs280299",
      coordinates: { chromosome: "chr6", position: 50656132 },
    },
    {
      fdr: 1.1069414850999185e-6,
      trait: "ADHD-meta-filtered",
      score: 0.36810017,
      TF_impact: "pos",
      snp: "rs28579454",
      coordinates: { chromosome: "chr15", position: 80351159 },
    },
    {
      fdr: 1.1778815605550048,
      trait: "ADHD-meta-filtered",
      score: 0.110414505,
      TF_impact: "none",
      snp: "rs1446335",
      coordinates: { chromosome: "chr15", position: 80412033 },
    },
    {
      fdr: 1.7280441860542006,
      trait: "MDD",
      score: -0.036890507,
      TF_impact: "none",
      snp: "rs11207138",
      coordinates: { chromosome: "chr1", position: 39926485 },
    },
    {
      fdr: 1.7525840254956453,
      trait: "bipolar-I",
      score: -0.082381725,
      TF_impact: "none",
      snp: "rs1459540",
      coordinates: { chromosome: "chr4", position: 117773188 },
    },
    {
      fdr: 1.8812993012530281,
      trait: "bipolar-2012",
      score: 0.06644535,
      TF_impact: "none",
      snp: "rs9376004",
      coordinates: { chromosome: "chr6", position: 134035907 },
    },
    {
      fdr: 1.7662919742203222,
      trait: "bipolar-2019",
      score: -0.03937769,
      TF_impact: "none",
      snp: "rs12191379",
      coordinates: { chromosome: "chr6", position: 124683704 },
    },
    {
      fdr: 1.3347763093650382,
      trait: "bipolar-2019",
      score: -0.017013073,
      TF_impact: "pos",
      snp: "rs2667762",
      coordinates: { chromosome: "chr15", position: 77590075 },
    },
    {
      fdr: 0.013254562887523417,
      trait: "MDD",
      score: 0.23603725,
      TF_impact: "none",
      snp: "rs2678273",
      coordinates: { chromosome: "chr2", position: 215619859 },
    },
    {
      fdr: 5.648107037412437e-10,
      trait: "bipolar-I",
      score: 0.4459858,
      TF_impact: "none",
      snp: "rs6496774",
      coordinates: { chromosome: "chr15", position: 91227417 },
    },
    {
      fdr: 0.40714624995692505,
      trait: "autism",
      score: -0.15691853,
      TF_impact: "pos",
      snp: "rs12519002",
      coordinates: { chromosome: "chr5", position: 57889847 },
    },
    {
      fdr: 1.4112384712660138,
      trait: "autism",
      score: -0.098499775,
      TF_impact: "pos",
      snp: "rs72828517",
      coordinates: { chromosome: "chr6", position: 19035804 },
    },
    {
      fdr: 2.2747721981504966e-7,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.38679838,
      TF_impact: "pos",
      snp: "rs11097575",
      coordinates: { chromosome: "chr4", position: 97721267 },
    },
    {
      fdr: 0.008002760457130836,
      trait: "autism",
      score: 0.24518728,
      TF_impact: "pos",
      snp: "rs7603584",
      coordinates: { chromosome: "chr2", position: 201440422 },
    },
    {
      fdr: 1.1376570807768454,
      trait: "bipolar-I",
      score: -0.008093357,
      TF_impact: "none",
      snp: "rs6740035",
      coordinates: { chromosome: "chr2", position: 98960115 },
    },
    {
      fdr: 1.0364153242780478,
      trait: "bipolar-I",
      score: 0.0012454987,
      TF_impact: "none",
      snp: "rs596179",
      coordinates: { chromosome: "chr3", position: 182855345 },
    },
    {
      fdr: 1.2033245432216575,
      trait: "autism",
      score: 0.009260654,
      TF_impact: "pos",
      snp: "rs2721811",
      coordinates: { chromosome: "chr7", position: 24709810 },
    },
    {
      fdr: 1.7567650154951433,
      trait: "autism",
      score: -0.03897524,
      TF_impact: "none",
      snp: "rs1908814",
      coordinates: { chromosome: "chr8", position: 11897546 },
    },
    {
      fdr: 1.0837002988856024,
      trait: "bipolar-I",
      score: -0.005261421,
      TF_impact: "none",
      snp: "rs11136345",
      coordinates: { chromosome: "chr8", position: 143985944 },
    },
    {
      fdr: 0.851868620417793,
      trait: "bipolar-2019",
      score: -0.12801981,
      TF_impact: "none",
      snp: "rs1402030",
      coordinates: { chromosome: "chr17", position: 40068768 },
    },
    {
      fdr: 0.2456579853685709,
      trait: "anxiety",
      score: -0.17231178,
      TF_impact: "pos",
      snp: "rs4771314",
      coordinates: { chromosome: "chr13", position: 98664201 },
    },
    {
      fdr: 0.07437254716825044,
      trait: "anxiety",
      score: 0.20050573,
      TF_impact: "none",
      snp: "rs3927078",
      coordinates: { chromosome: "chr2", position: 46670534 },
    },
    {
      fdr: 0.00032589134164125904,
      trait: "anxiety",
      score: -0.29630518,
      TF_impact: "none",
      snp: "rs17662779",
      coordinates: { chromosome: "chr17", position: 34567128 },
    },
    {
      fdr: 1.7071520853597604,
      trait: "anxiety",
      score: 0.034434795,
      TF_impact: "none",
      snp: "rs35840820",
      coordinates: { chromosome: "chr4", position: 81527372 },
    },
    {
      fdr: 1.7514832547336312,
      trait: "autism",
      score: 0.08017731,
      TF_impact: "none",
      snp: "rs2976882",
      coordinates: { chromosome: "chr8", position: 8467350 },
    },
    {
      fdr: 1.8916078590588732,
      trait: "MDD",
      score: -0.056373596,
      TF_impact: "none",
      snp: "rs149280103",
      coordinates: { chromosome: "chr18", position: 51154196 },
    },
    {
      fdr: 1.8912708641921798,
      trait: "MDD",
      score: -0.061843872,
      TF_impact: "pos",
      snp: "rs73738225",
      coordinates: { chromosome: "chr6", position: 27213931 },
    },
    {
      fdr: 0.5497772264773091,
      trait: "bipolar-I",
      score: -0.14541912,
      TF_impact: "pos",
      snp: "rs1480162",
      coordinates: { chromosome: "chr5", position: 147092245 },
    },
    {
      fdr: 1.2397855785397796,
      trait: "anorexia",
      score: -0.012530327,
      TF_impact: "none",
      snp: "rs3781445",
      coordinates: { chromosome: "chr10", position: 124999801 },
    },
    {
      fdr: 1.571964631815292,
      trait: "ADHD-meta-filtered",
      score: -0.02887392,
      TF_impact: "none",
      snp: "rs7922641",
      coordinates: { chromosome: "chr10", position: 113187401 },
    },
    {
      fdr: 1.1951449679447832,
      trait: "anorexia",
      score: 0.0089588165,
      TF_impact: "none",
      snp: "rs718947",
      coordinates: { chromosome: "chr10", position: 125050580 },
    },
    {
      fdr: 0.4220297675504492,
      trait: "MDD",
      score: -0.15546322,
      TF_impact: "none",
      snp: "rs11700794",
      coordinates: { chromosome: "chr21", position: 17659083 },
    },
    {
      fdr: 1.3279117484460488,
      trait: "bipolar-2019",
      score: 0.102507114,
      TF_impact: "none",
      snp: "rs2721921",
      coordinates: { chromosome: "chr8", position: 132499596 },
    },
    {
      fdr: 0.00932089609784354,
      trait: "bipolar-2019",
      score: -0.24394703,
      TF_impact: "neg",
      snp: "rs34044266",
      coordinates: { chromosome: "chr17", position: 75028091 },
    },
    {
      fdr: 0.004302669142845766,
      trait: "cannabis",
      score: 0.25586653,
      TF_impact: "none",
      snp: "rs11188284",
      coordinates: { chromosome: "chr10", position: 95337497 },
    },
    {
      fdr: 0.5403122980281224,
      trait: "bipolar-2019",
      score: -0.14638615,
      TF_impact: "none",
      snp: "rs76664163",
      coordinates: { chromosome: "chr19", position: 46413718 },
    },
    {
      fdr: 1.2599732802300547,
      trait: "MDD",
      score: -0.013505459,
      TF_impact: "neg",
      snp: "rs1107070",
      coordinates: { chromosome: "chr14", position: 103504171 },
    },
    {
      fdr: 1.605195705159632,
      trait: "MDD",
      score: 0.028989792,
      TF_impact: "pos",
      snp: "rs61943400",
      coordinates: { chromosome: "chr12", position: 118398236 },
    },
    {
      fdr: 3.814920841222968e-20,
      trait: "MDD",
      score: -0.6280179,
      TF_impact: "pos",
      snp: "rs9958401",
      coordinates: { chromosome: "chr18", position: 61891982 },
    },
    {
      fdr: 0.24628782125092188,
      trait: "bipolar-II",
      score: -0.1720233,
      TF_impact: "none",
      snp: "rs72689945",
      coordinates: { chromosome: "chr8", position: 143146930 },
    },
    {
      fdr: 0.003267345603903033,
      trait: "autism",
      score: 0.2605424,
      TF_impact: "none",
      snp: "rs7810588",
      coordinates: { chromosome: "chr7", position: 30182049 },
    },
    {
      fdr: 0.14662445730387674,
      trait: "cannabis",
      score: 0.18484735,
      TF_impact: "none",
      snp: "rs77068358",
      coordinates: { chromosome: "chr17", position: 80796816 },
    },
    {
      fdr: 1.4455555676019984,
      trait: "MDD",
      score: 0.020458698,
      TF_impact: "none",
      snp: "rs2250002",
      coordinates: { chromosome: "chr10", position: 71345340 },
    },
    {
      fdr: 9.820628072272414e-6,
      trait: "MDD",
      score: 0.34268045,
      TF_impact: "pos",
      snp: "rs1035223",
      coordinates: { chromosome: "chr18", position: 54762511 },
    },
    {
      fdr: 0.00034005030047692085,
      trait: "MDD",
      score: 0.29369068,
      TF_impact: "none",
      snp: "rs4608489",
      coordinates: { chromosome: "chr2", position: 217063538 },
    },
    {
      fdr: 1.8129668917993058,
      trait: "ADHD-meta-filtered",
      score: -0.07672691,
      TF_impact: "pos",
      snp: "rs1961979",
      coordinates: { chromosome: "chr4", position: 89001286 },
    },
    {
      fdr: 1.6363875840213093,
      trait: "cannabis",
      score: 0.030851841,
      TF_impact: "pos",
      snp: "rs36195228",
      coordinates: { chromosome: "chr8", position: 85056113 },
    },
    {
      fdr: 1.0675271606975538,
      trait: "anxiety",
      score: 0.0028276443,
      TF_impact: "none",
      snp: "rs4757754",
      coordinates: { chromosome: "chr11", position: 19110654 },
    },
    {
      fdr: 1.8668284018502714,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.07060337,
      TF_impact: "none",
      snp: "rs639218",
      coordinates: { chromosome: "chr17", position: 77273809 },
    },
    {
      fdr: 1.8953824715790948,
      trait: "ADHD-meta-filtered",
      score: -0.06267452,
      TF_impact: "pos",
      snp: "rs7549876",
      coordinates: { chromosome: "chr1", position: 43294565 },
    },
    {
      fdr: 1.218561408139507,
      trait: "cannabis",
      score: -0.10899162,
      TF_impact: "none",
      snp: "rs1922785",
      coordinates: { chromosome: "chr2", position: 58702543 },
    },
    {
      fdr: 1.7772858974330419,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.0401392,
      TF_impact: "none",
      snp: "rs13242167",
      coordinates: { chromosome: "chr7", position: 37037296 },
    },
    {
      fdr: 1.4119163370463983,
      trait: "MDD",
      score: -0.09851122,
      TF_impact: "none",
      snp: "rs62038334",
      coordinates: { chromosome: "chr16", position: 12168207 },
    },
    {
      fdr: 0.008057743243184645,
      trait: "MDD",
      score: 0.24492645,
      TF_impact: "none",
      snp: "rs6499040",
      coordinates: { chromosome: "chr16", position: 65787054 },
    },
    {
      fdr: 0.18357405274210736,
      trait: "autism",
      score: -0.180583,
      TF_impact: "neg",
      snp: "rs2349075",
      coordinates: { chromosome: "chr2", position: 201358843 },
    },
    {
      fdr: 1.738891989421239,
      trait: "bipolar-I",
      score: 0.03588915,
      TF_impact: "pos",
      snp: "rs1427281",
      coordinates: { chromosome: "chr15", position: 58667074 },
    },
    {
      fdr: 0.00010210400636138924,
      trait: "MDD",
      score: 0.31100297,
      TF_impact: "pos",
      snp: "rs2403202",
      coordinates: { chromosome: "chr14", position: 103613705 },
    },
    {
      fdr: 0.004479645497595942,
      trait: "cannabis",
      score: 0.25522995,
      TF_impact: "none",
      snp: "rs7793710",
      coordinates: { chromosome: "chr7", position: 73557201 },
    },
    {
      fdr: 1.7021907449538671,
      trait: "MDD",
      score: -0.0356822,
      TF_impact: "none",
      snp: "rs9998816",
      coordinates: { chromosome: "chr4", position: 158914546 },
    },
    {
      fdr: 1.8913345765249636,
      trait: "MDD",
      score: -0.06205225,
      TF_impact: "none",
      snp: "rs7776539",
      coordinates: { chromosome: "chr7", position: 3290148 },
    },
    {
      fdr: 1.8079938903337442,
      trait: "autism",
      score: 0.040786266,
      TF_impact: "pos",
      snp: "rs62064665",
      coordinates: { chromosome: "chr17", position: 46004161 },
    },
    {
      fdr: 1.5721667782281081,
      trait: "cannabis",
      score: 0.027200937,
      TF_impact: "pos",
      snp: "rs28453960",
      coordinates: { chromosome: "chr22", position: 49933522 },
    },
    {
      fdr: 1.0086041134817232e-5,
      trait: "bipolar-2019",
      score: -0.3432412,
      TF_impact: "none",
      snp: "rs3024747",
      coordinates: { chromosome: "chr13", position: 113167473 },
    },
    {
      fdr: 1.341216416833889,
      trait: "MDD",
      score: -0.10318184,
      TF_impact: "neg",
      snp: "rs12526735",
      coordinates: { chromosome: "chr6", position: 72939099 },
    },
    {
      fdr: 1.8449800252415568e-6,
      trait: "bipolar-2019",
      score: 0.36221933,
      TF_impact: "pos",
      snp: "rs13428225",
      coordinates: { chromosome: "chr2", position: 185351793 },
    },
    {
      fdr: 0.9135906680703871,
      trait: "autism",
      score: 0.12311745,
      TF_impact: "none",
      snp: "rs3745300",
      coordinates: { chromosome: "chr19", position: 49155618 },
    },
    {
      fdr: 1.1170015836183658e-7,
      trait: "scz",
      score: -0.39527416,
      TF_impact: "none",
      snp: "rs6482674",
      coordinates: { chromosome: "chr10", position: 128390728 },
    },
    {
      fdr: 1.103836995971031,
      trait: "bipolar-2019",
      score: 0.114681244,
      TF_impact: "neg",
      snp: "rs1013278",
      coordinates: { chromosome: "chr7", position: 117963766 },
    },
    {
      fdr: 1.6389631556395898,
      trait: "bipolar-I",
      score: -0.08838034,
      TF_impact: "none",
      snp: "rs9876378",
      coordinates: { chromosome: "chr3", position: 85022127 },
    },
    {
      fdr: 9.9069451946758e-10,
      trait: "autism",
      score: 0.4409418,
      TF_impact: "pos",
      snp: "rs12992419",
      coordinates: { chromosome: "chr2", position: 141630618 },
    },
    {
      fdr: 1.7946389396447007,
      trait: "anorexia",
      score: 0.039868355,
      TF_impact: "none",
      snp: "rs2074509",
      coordinates: { chromosome: "chr6", position: 30908461 },
    },
    {
      fdr: 0.005187483630022995,
      trait: "cannabis",
      score: 0.25238228,
      TF_impact: "none",
      snp: "rs521354",
      coordinates: { chromosome: "chr1", position: 156123040 },
    },
    {
      fdr: 0.004576623312751875,
      trait: "autism",
      score: 0.25480795,
      TF_impact: "none",
      snp: "rs1860071",
      coordinates: { chromosome: "chr7", position: 140210460 },
    },
    {
      fdr: 0.43924819908772844,
      trait: "MDD",
      score: -0.15412235,
      TF_impact: "none",
      snp: "rs2997754",
      coordinates: { chromosome: "chr10", position: 75144379 },
    },
    {
      fdr: 0.0506983008526566,
      trait: "anorexia",
      score: 0.20932531,
      TF_impact: "pos",
      snp: "rs9429742",
      coordinates: { chromosome: "chr1", position: 114380168 },
    },
    {
      fdr: 1.3347144894713323,
      trait: "MDD",
      score: 0.01533699,
      TF_impact: "none",
      snp: "rs298558",
      coordinates: { chromosome: "chr5", position: 17341167 },
    },
    {
      fdr: 2.6241668426681176e-24,
      trait: "bipolar-I",
      score: -0.6866088,
      TF_impact: "pos",
      snp: "rs1214761",
      coordinates: { chromosome: "chr6", position: 43386693 },
    },
    {
      fdr: 0.006507191896638208,
      trait: "anorexia",
      score: 0.24852276,
      TF_impact: "none",
      snp: "rs7831883",
      coordinates: { chromosome: "chr8", position: 26658697 },
    },
    {
      fdr: 1.854217173691826,
      trait: "MDD",
      score: -0.047907352,
      TF_impact: "none",
      snp: "rs1553597",
      coordinates: { chromosome: "chr18", position: 55833792 },
    },
    {
      fdr: 1.45092751293375,
      trait: "autism",
      score: 0.020917416,
      TF_impact: "pos",
      snp: "rs10777725",
      coordinates: { chromosome: "chr12", position: 95764126 },
    },
    {
      fdr: 0.923691111502732,
      trait: "bipolar-2019",
      score: -0.1243577,
      TF_impact: "none",
      snp: "rs716191",
      coordinates: { chromosome: "chr4", position: 94995768 },
    },
    {
      fdr: 2.6452895603334123e-5,
      trait: "autism",
      score: 0.3296337,
      TF_impact: "neg",
      snp: "rs11986122",
      coordinates: { chromosome: "chr8", position: 10152439 },
    },
    {
      fdr: 0.0075545111156615835,
      trait: "bipolar-2012",
      score: -0.2477746,
      TF_impact: "none",
      snp: "rs13012495",
      coordinates: { chromosome: "chr2", position: 98749181 },
    },
    {
      fdr: 1.899791801897247,
      trait: "anorexia",
      score: 0.06195116,
      TF_impact: "none",
      snp: "rs11814972",
      coordinates: { chromosome: "chr10", position: 98173507 },
    },
    {
      fdr: 0.00021728159726832767,
      trait: "bipolar-I",
      score: -0.3022108,
      TF_impact: "none",
      snp: "rs923724",
      coordinates: { chromosome: "chr12", position: 99246152 },
    },
    {
      fdr: 4.412129051661664e-16,
      trait: "anorexia",
      score: -0.5630827,
      TF_impact: "none",
      snp: "rs7964074",
      coordinates: { chromosome: "chr12", position: 57536591 },
    },
    {
      fdr: 1.1983656989262805,
      trait: "MDD",
      score: 0.109289646,
      TF_impact: "pos",
      snp: "rs28541419",
      coordinates: { chromosome: "chr15", position: 88402647 },
    },
    {
      fdr: 0.006647335186429712,
      trait: "anorexia",
      score: -0.24983025,
      TF_impact: "pos",
      snp: "rs2090259",
      coordinates: { chromosome: "chr8", position: 51993877 },
    },
    {
      fdr: 0.0018243973630496697,
      trait: "scz",
      score: -0.27141094,
      TF_impact: "none",
      snp: "rs12338960",
      coordinates: { chromosome: "chr9", position: 82128493 },
    },
    {
      fdr: 0.00021328916438373096,
      trait: "bipolar-I",
      score: -0.3028016,
      TF_impact: "none",
      snp: "rs7259187",
      coordinates: { chromosome: "chr19", position: 2686647 },
    },
    {
      fdr: 0.5358694054504888,
      trait: "ADHD-meta-filtered",
      score: 0.14510775,
      TF_impact: "pos",
      snp: "rs4078520",
      coordinates: { chromosome: "chr11", position: 723429 },
    },
    {
      fdr: 1.8957882330659832,
      trait: "autism",
      score: -0.055402756,
      TF_impact: "pos",
      snp: "rs7719215",
      coordinates: { chromosome: "chr5", position: 54601388 },
    },
    {
      fdr: 9.943706219633457e-14,
      trait: "bipolar-I",
      score: 0.5215173,
      TF_impact: "pos",
      snp: "rs10826321",
      coordinates: { chromosome: "chr10", position: 59580445 },
    },
    {
      fdr: 0.512266589245896,
      trait: "MDD",
      score: -0.14856386,
      TF_impact: "none",
      snp: "rs4886617",
      coordinates: { chromosome: "chr15", position: 73797452 },
    },
    {
      fdr: 2.904901120117722e-8,
      trait: "bipolar-2019",
      score: 0.40796566,
      TF_impact: "neg",
      snp: "rs1990565",
      coordinates: { chromosome: "chr19", position: 46289409 },
    },
    {
      fdr: 1.8222089949506084,
      trait: "MDD",
      score: 0.04305744,
      TF_impact: "pos",
      snp: "rs28377268",
      coordinates: { chromosome: "chr9", position: 95462774 },
    },
    {
      fdr: 1.8612149438283982,
      trait: "ADHD-meta-filtered",
      score: -0.048507214,
      TF_impact: "none",
      snp: "rs10033356",
      coordinates: { chromosome: "chr4", position: 80037099 },
    },
    {
      fdr: 1.3174085872154713,
      trait: "anorexia",
      score: -0.016201973,
      TF_impact: "none",
      snp: "rs1264307",
      coordinates: { chromosome: "chr6", position: 30912980 },
    },
    {
      fdr: 1.557578921345705,
      trait: "anorexia",
      score: -0.027894974,
      TF_impact: "neg",
      snp: "rs28544076",
      coordinates: { chromosome: "chr1", position: 241950596 },
    },
    {
      fdr: 1.3261824876775234,
      trait: "bipolar-2019",
      score: 0.10260391,
      TF_impact: "pos",
      snp: "rs58988283",
      coordinates: { chromosome: "chr2", position: 209261433 },
    },
    {
      fdr: 1.8952560589932423,
      trait: "MDD",
      score: -0.058399677,
      TF_impact: "none",
      snp: "rs376358",
      coordinates: { chromosome: "chr2", position: 104786677 },
    },
    {
      fdr: 1.5501458237546745e-8,
      trait: "autism",
      score: 0.41429782,
      TF_impact: "pos",
      snp: "rs413778",
      coordinates: { chromosome: "chr17", position: 45639519 },
    },
    {
      fdr: 1.0941733318965172,
      trait: "bipolar-I",
      score: -0.005853653,
      TF_impact: "pos",
      snp: "rs11717383",
      coordinates: { chromosome: "chr3", position: 52253452 },
    },
    {
      fdr: 1.4152797900292287,
      trait: "pgc-panic-disorder",
      score: -0.020689487,
      TF_impact: "none",
      snp: "rs3949492",
      coordinates: { chromosome: "chr20", position: 4040488 },
    },
    {
      fdr: 0.5544530383586226,
      trait: "bipolar-2019",
      score: 0.14336777,
      TF_impact: "none",
      snp: "rs12754658",
      coordinates: { chromosome: "chr1", position: 59072800 },
    },
    {
      fdr: 9.789298196154439e-5,
      trait: "bipolar-2019",
      score: -0.31333733,
      TF_impact: "neg",
      snp: "rs3817030",
      coordinates: { chromosome: "chr16", position: 69316132 },
    },
    {
      fdr: 1.0037955361667887,
      trait: "MDD",
      score: -0.0010781288,
      TF_impact: "none",
      snp: "rs359708",
      coordinates: { chromosome: "chr2", position: 126370357 },
    },
    {
      fdr: 0.0011413666625035125,
      trait: "ADHD-meta-filtered",
      score: 0.2764578,
      TF_impact: "pos",
      snp: "rs9560010",
      coordinates: { chromosome: "chr13", position: 111214173 },
    },
    {
      fdr: 0.2428862699025117,
      trait: "anorexia",
      score: 0.1710167,
      TF_impact: "none",
      snp: "rs3818524",
      coordinates: { chromosome: "chr6", position: 33692918 },
    },
    {
      fdr: 0.9605950935905213,
      trait: "autism",
      score: 0.121174335,
      TF_impact: "none",
      snp: "rs6928669",
      coordinates: { chromosome: "chr6", position: 23689021 },
    },
    {
      fdr: 1.382914486522285,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.10071564,
      TF_impact: "none",
      snp: "rs1715491",
      coordinates: { chromosome: "chr17", position: 77282742 },
    },
    {
      fdr: 0.0009314565639383141,
      trait: "anorexia",
      score: -0.28115368,
      TF_impact: "pos",
      snp: "rs824202",
      coordinates: { chromosome: "chr15", position: 23764434 },
    },
    {
      fdr: 0.0004514356941651931,
      trait: "ADHD-meta-filtered",
      score: 0.28994942,
      TF_impact: "none",
      snp: "rs62206557",
      coordinates: { chromosome: "chr20", position: 37180220 },
    },
    {
      fdr: 1.8754317131287628,
      trait: "ADHD-meta-filtered",
      score: 0.06752682,
      TF_impact: "none",
      snp: "rs1249582",
      coordinates: { chromosome: "chr11", position: 68998427 },
    },
    {
      fdr: 1.2333925176031661,
      trait: "MDD",
      score: 0.010587692,
      TF_impact: "pos",
      snp: "rs13248317",
      coordinates: { chromosome: "chr8", position: 20423431 },
    },
    {
      fdr: 1.316709798971855,
      trait: "bipolar-2019",
      score: -0.016180515,
      TF_impact: "none",
      snp: "rs10263430",
      coordinates: { chromosome: "chr7", position: 29975830 },
    },
    {
      fdr: 1.2231474843940575,
      trait: "bipolar-2019",
      score: 0.0101213455,
      TF_impact: "pos",
      snp: "rs6426841",
      coordinates: { chromosome: "chr1", position: 163820413 },
    },
    {
      fdr: 0.20175733956947137,
      trait: "bipolar-II",
      score: -0.1779232,
      TF_impact: "none",
      snp: "rs77570544",
      coordinates: { chromosome: "chr4", position: 111363088 },
    },
    {
      fdr: 0.28596580055842546,
      trait: "cannabis",
      score: -0.16786909,
      TF_impact: "pos",
      snp: "rs1348716",
      coordinates: { chromosome: "chr5", position: 61451158 },
    },
    {
      fdr: 8.530951182418809e-5,
      trait: "ADHD-meta-filtered",
      score: -0.31561375,
      TF_impact: "pos",
      snp: "rs7664633",
      coordinates: { chromosome: "chr4", position: 175807763 },
    },
    {
      fdr: 0.9683676271668131,
      trait: "bipolar-2019",
      score: -0.12226009,
      TF_impact: "pos",
      snp: "rs10414516",
      coordinates: { chromosome: "chr19", position: 2019759 },
    },
    {
      fdr: 2.8622537900427536e-13,
      trait: "ADHD-meta-filtered",
      score: 0.5123472,
      TF_impact: "pos",
      snp: "rs12483621",
      coordinates: { chromosome: "chr21", position: 36450798 },
    },
    {
      fdr: 0.02829320145028014,
      trait: "MDD",
      score: -0.22303247,
      TF_impact: "none",
      snp: "rs11853271",
      coordinates: { chromosome: "chr15", position: 90482598 },
    },
    {
      fdr: 0.005253507743840539,
      trait: "bipolar-I",
      score: 0.2524743,
      TF_impact: "pos",
      snp: "rs184301136",
      coordinates: { chromosome: "chr12", position: 57591140 },
    },
    {
      fdr: 0.8500634124680907,
      trait: "autism",
      score: 0.12651205,
      TF_impact: "none",
      snp: "rs4841541",
      coordinates: { chromosome: "chr8", position: 11454320 },
    },
    {
      fdr: 1.592173013247145,
      trait: "autism",
      score: 0.08869076,
      TF_impact: "none",
      snp: "rs74466898",
      coordinates: { chromosome: "chr18", position: 58192707 },
    },
    {
      fdr: 1.8988580554678383,
      trait: "anorexia",
      score: -0.06356859,
      TF_impact: "none",
      snp: "rs3804771",
      coordinates: { chromosome: "chr3", position: 141972565 },
    },
    {
      fdr: 1.7390471914304877,
      trait: "MDD",
      score: 0.03579378,
      TF_impact: "pos",
      snp: "rs12892062",
      coordinates: { chromosome: "chr14", position: 103417175 },
    },
    {
      fdr: 1.7045624566268613,
      trait: "bipolar-2019",
      score: 0.03425646,
      TF_impact: "pos",
      snp: "rs6080766",
      coordinates: { chromosome: "chr20", position: 17662462 },
    },
    {
      fdr: 0.28752517829527213,
      trait: "opioid-unexposed-vs-exposed",
      score: 0.16593838,
      TF_impact: "neg",
      snp: "rs1966072",
      coordinates: { chromosome: "chr8", position: 104324084 },
    },
    {
      fdr: 0.0004899694027081562,
      trait: "MDD",
      score: -0.290246,
      TF_impact: "pos",
      snp: "rs9564313",
      coordinates: { chromosome: "chr13", position: 66349555 },
    },
    {
      fdr: 1.6467261365760393,
      trait: "cannabis",
      score: 0.08609009,
      TF_impact: "pos",
      snp: "rs3903344",
      coordinates: { chromosome: "chr8", position: 85032135 },
    },
    {
      fdr: 1.803788560237592,
      trait: "MDD",
      score: 0.040345192,
      TF_impact: "pos",
      snp: "rs1406713",
      coordinates: { chromosome: "chr15", position: 92918033 },
    },
    {
      fdr: 1.8729233398159977,
      trait: "autism",
      score: -0.06907606,
      TF_impact: "pos",
      snp: "rs73129824",
      coordinates: { chromosome: "chr20", position: 21554779 },
    },
    {
      fdr: 1.2491525410831799,
      trait: "bipolar-2019",
      score: -0.107253075,
      TF_impact: "none",
      snp: "rs2673582",
      coordinates: { chromosome: "chr8", position: 132507817 },
    },
    {
      fdr: 8.360015083639485e-5,
      trait: "anorexia",
      score: -0.31634998,
      TF_impact: "pos",
      snp: "rs167849",
      coordinates: { chromosome: "chr1", position: 96389200 },
    },
    {
      fdr: 0.00021708930034734718,
      trait: "autism",
      score: -0.30215073,
      TF_impact: "none",
      snp: "rs66691214",
      coordinates: { chromosome: "chr7", position: 30149188 },
    },
    {
      fdr: 0.02357225767061461,
      trait: "MDD",
      score: -0.22667408,
      TF_impact: "pos",
      snp: "rs11609859",
      coordinates: { chromosome: "chr12", position: 124804115 },
    },
    {
      fdr: 1.8374499839119773,
      trait: "bipolar-I",
      score: 0.07285881,
      TF_impact: "pos",
      snp: "rs4964649",
      coordinates: { chromosome: "chr12", position: 108175527 },
    },
    {
      fdr: 1.43402347809643,
      trait: "bipolar-2019",
      score: 0.019963264,
      TF_impact: "none",
      snp: "rs11640354",
      coordinates: { chromosome: "chr16", position: 12363065 },
    },
    {
      fdr: 1.5344406929981083e-7,
      trait: "cannabis",
      score: 0.39038086,
      TF_impact: "none",
      snp: "rs611005",
      coordinates: { chromosome: "chr18", position: 35078630 },
    },
    {
      fdr: 1.0680439493524805,
      trait: "bipolar-I",
      score: -0.0045313835,
      TF_impact: "neg",
      snp: "rs6065778",
      coordinates: { chromosome: "chr20", position: 45059511 },
    },
    {
      fdr: 2.667195619428341e-5,
      trait: "autism",
      score: 0.32944775,
      TF_impact: "pos",
      snp: "rs12547721",
      coordinates: { chromosome: "chr8", position: 8713161 },
    },
    {
      fdr: 1.376823617470174,
      trait: "anxiety",
      score: 0.017377853,
      TF_impact: "none",
      snp: "rs72817564",
      coordinates: { chromosome: "chr5", position: 165151668 },
    },
    {
      fdr: 1.8524738094186348,
      trait: "ADHD-meta-filtered",
      score: 0.046161175,
      TF_impact: "none",
      snp: "rs5745568",
      coordinates: { chromosome: "chr6", position: 33580617 },
    },
    {
      fdr: 2.0756469470906378e-38,
      trait: "MDD",
      score: -0.85618067,
      TF_impact: "pos",
      snp: "rs9462535",
      coordinates: { chromosome: "chr6", position: 39348016 },
    },
    {
      fdr: 0.003687036146531152,
      trait: "anxiety",
      score: -0.26015234,
      TF_impact: "none",
      snp: "rs7795555",
      coordinates: { chromosome: "chr7", position: 154104933 },
    },
    {
      fdr: 0.008740418041542122,
      trait: "MDD",
      score: -0.24512196,
      TF_impact: "neg",
      snp: "rs4907311",
      coordinates: { chromosome: "chr2", position: 96345624 },
    },
    {
      fdr: 1.8672920892256197,
      trait: "autism",
      score: 0.06889939,
      TF_impact: "none",
      snp: "rs62057151",
      coordinates: { chromosome: "chr17", position: 45826476 },
    },
    {
      fdr: 1.7690338585383716,
      trait: "bipolar-2019",
      score: 0.037794113,
      TF_impact: "none",
      snp: "rs56303180",
      coordinates: { chromosome: "chr14", position: 99253536 },
    },
    {
      fdr: 0.8147074736203662,
      trait: "bipolar-I",
      score: 0.12832499,
      TF_impact: "none",
      snp: "rs2286940",
      coordinates: { chromosome: "chr3", position: 37028615 },
    },
    {
      fdr: 0.002958444558514081,
      trait: "autism",
      score: 0.26223946,
      TF_impact: "pos",
      snp: "rs11685464",
      coordinates: { chromosome: "chr2", position: 236875967 },
    },
    {
      fdr: 1.6430573329140976,
      trait: "anxiety",
      score: -0.08818817,
      TF_impact: "none",
      snp: "rs66477321",
      coordinates: { chromosome: "chr17", position: 33523806 },
    },
    {
      fdr: 2.643872013118562e-5,
      trait: "cannabis",
      score: -0.33150148,
      TF_impact: "none",
      snp: "rs66791174",
      coordinates: { chromosome: "chr3", position: 100517049 },
    },
    {
      fdr: 2.099086708243765e-7,
      trait: "bipolar-I",
      score: -0.38870668,
      TF_impact: "none",
      snp: "rs11990215",
      coordinates: { chromosome: "chr8", position: 143995983 },
    },
    {
      fdr: 1.8793265240723798,
      trait: "ADHD-meta-filtered",
      score: -0.05181074,
      TF_impact: "none",
      snp: "rs2236947",
      coordinates: { chromosome: "chr3", position: 50334001 },
    },
    {
      fdr: 1.0344897613173603,
      trait: "autism",
      score: -0.0027923584,
      TF_impact: "none",
      snp: "rs17153498",
      coordinates: { chromosome: "chr8", position: 11589446 },
    },
    {
      fdr: 0.0014947808215818577,
      trait: "anorexia",
      score: 0.2726369,
      TF_impact: "pos",
      snp: "rs3934393",
      coordinates: { chromosome: "chr6", position: 46410524 },
    },
    {
      fdr: 0.052038305911157,
      trait: "anorexia",
      score: 0.20863247,
      TF_impact: "pos",
      snp: "rs3227",
      coordinates: { chromosome: "chr6", position: 33694518 },
    },
    {
      fdr: 1.6921827306829864,
      trait: "bipolar-2019",
      score: -0.034838676,
      TF_impact: "none",
      snp: "rs56293342",
      coordinates: { chromosome: "chr2", position: 161427107 },
    },
    {
      fdr: 1.372881945366411,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.09942865,
      TF_impact: "pos",
      snp: "rs6747398",
      coordinates: { chromosome: "chr2", position: 64922741 },
    },
    {
      fdr: 1.3174782212672784,
      trait: "MDD",
      score: -0.01619482,
      TF_impact: "none",
      snp: "rs146299656",
      coordinates: { chromosome: "chr9", position: 4555436 },
    },
    {
      fdr: 1.8781934379557768,
      trait: "autism",
      score: -0.050673008,
      TF_impact: "pos",
      snp: "rs56101454",
      coordinates: { chromosome: "chr6", position: 29685936 },
    },
    {
      fdr: 0.41670440905440653,
      trait: "bipolar-I",
      score: -0.15587091,
      TF_impact: "pos",
      snp: "rs7656375",
      coordinates: { chromosome: "chr4", position: 142840715 },
    },
    {
      fdr: 0.0008609729192361407,
      trait: "bipolar-2019",
      score: -0.28238487,
      TF_impact: "none",
      snp: "rs2043332",
      coordinates: { chromosome: "chr19", position: 10780563 },
    },
    {
      fdr: 0.06612119867460073,
      trait: "bipolar-I",
      score: -0.20514536,
      TF_impact: "pos",
      snp: "rs58231871",
      coordinates: { chromosome: "chr7", position: 27604074 },
    },
    {
      fdr: 1.4865068854981744,
      trait: "cannabis",
      score: 0.022815228,
      TF_impact: "pos",
      snp: "rs4647102",
      coordinates: { chromosome: "chr5", position: 60903536 },
    },
    {
      fdr: 0.35724636563046097,
      trait: "MDD",
      score: -0.16102695,
      TF_impact: "none",
      snp: "rs113670431",
      coordinates: { chromosome: "chr4", position: 47270605 },
    },
    {
      fdr: 1.7304573844661706,
      trait: "MDD",
      score: -0.083806515,
      TF_impact: "none",
      snp: "rs12694082",
      coordinates: { chromosome: "chr2", position: 207252032 },
    },
    {
      fdr: 1.0722482874786985,
      trait: "autism",
      score: 0.0030665398,
      TF_impact: "pos",
      snp: "rs1497027",
      coordinates: { chromosome: "chr9", position: 73548438 },
    },
    {
      fdr: 1.3476387470080842,
      trait: "cannabis",
      score: -0.01768732,
      TF_impact: "none",
      snp: "rs41483748",
      coordinates: { chromosome: "chr2", position: 40258384 },
    },
    {
      fdr: 0.16852722617382632,
      trait: "anorexia",
      score: -0.18290424,
      TF_impact: "none",
      snp: "rs9830600",
      coordinates: { chromosome: "chr3", position: 94414768 },
    },
    {
      fdr: 0.33622753444895226,
      trait: "autism",
      score: 0.16119194,
      TF_impact: "none",
      snp: "rs3989103",
      coordinates: { chromosome: "chr8", position: 11449337 },
    },
    {
      fdr: 1.731941179329499,
      trait: "cannabis",
      score: -0.03700924,
      TF_impact: "none",
      snp: "rs17509575",
      coordinates: { chromosome: "chr2", position: 117889976 },
    },
    {
      fdr: 1.4134308450998008,
      trait: "bipolar-2019",
      score: 0.09710789,
      TF_impact: "none",
      snp: "rs34001546",
      coordinates: { chromosome: "chr1", position: 150487239 },
    },
    {
      fdr: 1.1730736554243075,
      trait: "anorexia",
      score: -0.11279917,
      TF_impact: "pos",
      snp: "rs10400567",
      coordinates: { chromosome: "chr12", position: 49331937 },
    },
    {
      fdr: 0.04298523304271872,
      trait: "bipolar-2019",
      score: 0.21271753,
      TF_impact: "pos",
      snp: "rs13157748",
      coordinates: { chromosome: "chr5", position: 59831832 },
    },
    {
      fdr: 8.92356873603706e-9,
      trait: "bipolar-I",
      score: 0.41969585,
      TF_impact: "none",
      snp: "rs2836762",
      coordinates: { chromosome: "chr21", position: 38931202 },
    },
    {
      fdr: 1.55087275643026,
      trait: "MDD",
      score: -0.09234285,
      TF_impact: "none",
      snp: "rs7923465",
      coordinates: { chromosome: "chr10", position: 12719685 },
    },
    {
      fdr: 6.293756271756408e-7,
      trait: "cannabis",
      score: 0.37486792,
      TF_impact: "pos",
      snp: "rs10767439",
      coordinates: { chromosome: "chr11", position: 25734803 },
    },
    {
      fdr: 0.7468804759147979,
      trait: "cannabis",
      score: 0.13196564,
      TF_impact: "none",
      snp: "rs7807955",
      coordinates: { chromosome: "chr7", position: 37475854 },
    },
    {
      fdr: 1.4382441042144274,
      trait: "bipolar-2019",
      score: 0.020238638,
      TF_impact: "none",
      snp: "rs1007537",
      coordinates: { chromosome: "chr15", position: 77620375 },
    },
    {
      fdr: 1.1437345502490919,
      trait: "bipolar-2019",
      score: 0.0066719055,
      TF_impact: "none",
      snp: "rs6751612",
      coordinates: { chromosome: "chr2", position: 209425702 },
    },
    {
      fdr: 0.33499758905664934,
      trait: "bipolar-I",
      score: 0.1613245,
      TF_impact: "pos",
      snp: "rs576",
      coordinates: { chromosome: "chr3", position: 69967316 },
    },
    {
      fdr: 1.8831722255659642,
      trait: "bipolar-I",
      score: -0.06797075,
      TF_impact: "none",
      snp: "rs11242430",
      coordinates: { chromosome: "chr5", position: 138487177 },
    },
    {
      fdr: 2.372478578535857e-6,
      trait: "anorexia",
      score: -0.3606887,
      TF_impact: "none",
      snp: "rs6668345",
      coordinates: { chromosome: "chr1", position: 71565599 },
    },
    {
      fdr: 0.07661673119732614,
      trait: "anxiety",
      score: -0.20142555,
      TF_impact: "none",
      snp: "rs2600073",
      coordinates: { chromosome: "chr3", position: 10804259 },
    },
    {
      fdr: 0.1734247278294003,
      trait: "ADHD-meta-filtered",
      score: 0.1803441,
      TF_impact: "none",
      snp: "rs10950400",
      coordinates: { chromosome: "chr7", position: 1842834 },
    },
    {
      fdr: 1.5528851087776547,
      trait: "bipolar-2019",
      score: 0.09056282,
      TF_impact: "pos",
      snp: "rs35138622",
      coordinates: { chromosome: "chr19", position: 58401421 },
    },
    {
      fdr: 1.330638165503528,
      trait: "anxiety",
      score: -0.01683855,
      TF_impact: "pos",
      snp: "rs12917636",
      coordinates: { chromosome: "chr16", position: 55036277 },
    },
    {
      fdr: 1.2644134776081213e-10,
      trait: "bipolar-I",
      score: 0.46001148,
      TF_impact: "none",
      snp: "rs7570730",
      coordinates: { chromosome: "chr2", position: 27799808 },
    },
    {
      fdr: 1.0825709796773249,
      trait: "autism",
      score: -0.0051984787,
      TF_impact: "pos",
      snp: "rs327287",
      coordinates: { chromosome: "chr5", position: 38642450 },
    },
    {
      fdr: 0.06675113848680102,
      trait: "pgc-panic-disorder",
      score: 0.20322514,
      TF_impact: "pos",
      snp: "rs1865093",
      coordinates: { chromosome: "chr19", position: 39445494 },
    },
    {
      fdr: 0.0981381787203989,
      trait: "anorexia",
      score: 0.19442606,
      TF_impact: "none",
      snp: "rs67060340",
      coordinates: { chromosome: "chr3", position: 48877888 },
    },
    {
      fdr: 0.07593829123971771,
      trait: "MDD",
      score: -0.2016592,
      TF_impact: "pos",
      snp: "rs71546165",
      coordinates: { chromosome: "chr7", position: 54327009 },
    },
    {
      fdr: 1.6952829589772145,
      trait: "bipolar-2019",
      score: -0.035054684,
      TF_impact: "none",
      snp: "rs941520",
      coordinates: { chromosome: "chr14", position: 99243365 },
    },
    {
      fdr: 1.751077178990178,
      trait: "ADHD-meta-filtered",
      score: -0.08251381,
      TF_impact: "none",
      snp: "rs2498442",
      coordinates: { chromosome: "chr6", position: 98045334 },
    },
    {
      fdr: 2.0475337958864621e-7,
      trait: "ADHD-meta-filtered",
      score: 0.38656044,
      TF_impact: "pos",
      snp: "rs4690138",
      coordinates: { chromosome: "chr4", position: 80017552 },
    },
    {
      fdr: 1.1151167029038436,
      trait: "MDD",
      score: -0.11538792,
      TF_impact: "none",
      snp: "rs9920038",
      coordinates: { chromosome: "chr15", position: 66415523 },
    },
    {
      fdr: 1.8884816106155227,
      trait: "ADHD-meta-filtered",
      score: -0.060366154,
      TF_impact: "pos",
      snp: "rs718571",
      coordinates: { chromosome: "chr18", position: 42642213 },
    },
    {
      fdr: 4.095378666044901e-13,
      trait: "ADHD-meta-filtered",
      score: 0.5088763,
      TF_impact: "none",
      snp: "rs17254786",
      coordinates: { chromosome: "chr3", position: 43247135 },
    },
    {
      fdr: 1.3534435335345636,
      trait: "anorexia",
      score: -0.017901897,
      TF_impact: "pos",
      snp: "rs7796558",
      coordinates: { chromosome: "chr7", position: 104089931 },
    },
    {
      fdr: 1.4466164778063917,
      trait: "MDD",
      score: -0.09719658,
      TF_impact: "neg",
      snp: "rs77328845",
      coordinates: { chromosome: "chr10", position: 107299926 },
    },
    {
      fdr: 0.00044806140786000947,
      trait: "anxiety",
      score: 0.28991413,
      TF_impact: "none",
      snp: "rs61141806",
      coordinates: { chromosome: "chr4", position: 169900557 },
    },
    {
      fdr: 1.6173236843959014,
      trait: "ADHD-meta-filtered",
      score: 0.08781624,
      TF_impact: "pos",
      snp: "rs57191490",
      coordinates: { chromosome: "chr12", position: 49671106 },
    },
    {
      fdr: 1.8996036805042356,
      trait: "bipolar-2012",
      score: 0.061929226,
      TF_impact: "none",
      snp: "rs940455",
      coordinates: { chromosome: "chr7", position: 146966476 },
    },
    {
      fdr: 2.8081638588485136e-6,
      trait: "bipolar-2019",
      score: 0.35668707,
      TF_impact: "none",
      snp: "rs329304",
      coordinates: { chromosome: "chr5", position: 134562139 },
    },
    {
      fdr: 1.266542003013741,
      trait: "bipolar-I",
      score: 0.012187481,
      TF_impact: "pos",
      snp: "rs138322482",
      coordinates: { chromosome: "chr8", position: 26240376 },
    },
    {
      fdr: 1.768706049330387,
      trait: "MDD",
      score: -0.039461136,
      TF_impact: "pos",
      snp: "rs7269614",
      coordinates: { chromosome: "chr20", position: 47205863 },
    },
    {
      fdr: 8.40470876649454e-12,
      trait: "bipolar-I",
      score: 0.48360062,
      TF_impact: "pos",
      snp: "rs17177789",
      coordinates: { chromosome: "chr14", position: 63212991 },
    },
    {
      fdr: 1.7462740142433122,
      trait: "anorexia",
      score: -0.038087368,
      TF_impact: "none",
      snp: "rs2803340",
      coordinates: { chromosome: "chr1", position: 1946632 },
    },
    {
      fdr: 7.290415099566199e-16,
      trait: "autism",
      score: 0.5574188,
      TF_impact: "pos",
      snp: "rs16875447",
      coordinates: { chromosome: "chr5", position: 78472665 },
    },
    {
      fdr: 1.8971713269746264,
      trait: "bipolar-I",
      score: 0.060884,
      TF_impact: "none",
      snp: "rs76369658",
      coordinates: { chromosome: "chr11", position: 134398216 },
    },
    {
      fdr: 0.19576731581099988,
      trait: "bipolar-2019",
      score: 0.17697144,
      TF_impact: "none",
      snp: "rs10931887",
      coordinates: { chromosome: "chr2", position: 200104182 },
    },
    {
      fdr: 1.1232875422157733,
      trait: "anxiety",
      score: 0.0056786537,
      TF_impact: "none",
      snp: "rs2706240",
      coordinates: { chromosome: "chr13", position: 50682866 },
    },
    {
      fdr: 1.7842677491428345,
      trait: "ADHD-meta-filtered",
      score: 0.077681065,
      TF_impact: "pos",
      snp: "rs727257",
      coordinates: { chromosome: "chr14", position: 91058332 },
    },
    {
      fdr: 1.895343187839861,
      trait: "bipolar-I",
      score: -0.062717915,
      TF_impact: "neg",
      snp: "rs2239017",
      coordinates: { chromosome: "chr12", position: 2180485 },
    },
    {
      fdr: 0.7775732101452058,
      trait: "bipolar-II",
      score: 0.12974262,
      TF_impact: "pos",
      snp: "rs9468220",
      coordinates: { chromosome: "chr6", position: 27765197 },
    },
    {
      fdr: 0.0002862635053941165,
      trait: "bipolar-2019",
      score: -0.29807377,
      TF_impact: "neg",
      snp: "rs267003",
      coordinates: { chromosome: "chr5", position: 155451382 },
    },
    {
      fdr: 1.445335347434181,
      trait: "MDD",
      score: 0.020522594,
      TF_impact: "pos",
      snp: "rs309452",
      coordinates: { chromosome: "chr9", position: 37433962 },
    },
    {
      fdr: 0.9438546424288563,
      trait: "bipolar-I",
      score: 0.12187576,
      TF_impact: "pos",
      snp: "rs12575395",
      coordinates: { chromosome: "chr11", position: 111301339 },
    },
    {
      fdr: 1.0114059694991893e-5,
      trait: "autism",
      score: -0.34382153,
      TF_impact: "pos",
      snp: "rs75732533",
      coordinates: { chromosome: "chr7", position: 158868921 },
    },
    {
      fdr: 1.2868642949438924,
      trait: "autism",
      score: 0.013122559,
      TF_impact: "pos",
      snp: "rs62064664",
      coordinates: { chromosome: "chr17", position: 46004096 },
    },
    {
      fdr: 6.218825319587021e-6,
      trait: "bipolar-2012",
      score: -0.3496523,
      TF_impact: "none",
      snp: "rs17149512",
      coordinates: { chromosome: "chr7", position: 87398843 },
    },
    {
      fdr: 2.0476556840652186e-7,
      trait: "cannabis",
      score: -0.38909817,
      TF_impact: "pos",
      snp: "rs12237222",
      coordinates: { chromosome: "chr9", position: 83219828 },
    },
    {
      fdr: 7.711649728299207e-17,
      trait: "bipolar-II",
      score: 0.5759001,
      TF_impact: "pos",
      snp: "rs5758065",
      coordinates: { chromosome: "chr22", position: 40765045 },
    },
    {
      fdr: 9.996653888117915e-6,
      trait: "bipolar-2019",
      score: -0.34343863,
      TF_impact: "neg",
      snp: "rs12724450",
      coordinates: { chromosome: "chr1", position: 150508095 },
    },
    {
      fdr: 0.5352343176504034,
      trait: "ADHD-meta-filtered",
      score: -0.14683342,
      TF_impact: "none",
      snp: "rs463334",
      coordinates: { chromosome: "chr3", position: 13070723 },
    },
    {
      fdr: 0.0035393258323696917,
      trait: "bipolar-2019",
      score: -0.26102638,
      TF_impact: "none",
      snp: "rs2005983",
      coordinates: { chromosome: "chr22", position: 43036313 },
    },
    {
      fdr: 0.00024346180065162103,
      trait: "bipolar-2019",
      score: 0.2986307,
      TF_impact: "none",
      snp: "rs10819162",
      coordinates: { chromosome: "chr9", position: 126463259 },
    },
    {
      fdr: 1.4220279359794854,
      trait: "autism",
      score: 0.01938963,
      TF_impact: "none",
      snp: "rs7866443",
      coordinates: { chromosome: "chr9", position: 116417597 },
    },
    {
      fdr: 0.01260766813971205,
      trait: "MDD",
      score: 0.2369585,
      TF_impact: "none",
      snp: "rs10067299",
      coordinates: { chromosome: "chr5", position: 124660916 },
    },
    {
      fdr: 1.6043174343004758,
      trait: "MDD",
      score: -0.030641079,
      TF_impact: "none",
      snp: "rs4598945",
      coordinates: { chromosome: "chr17", position: 65433859 },
    },
    {
      fdr: 1.842714467264075,
      trait: "ADHD-meta-filtered",
      score: -0.046885967,
      TF_impact: "none",
      snp: "rs10447760",
      coordinates: { chromosome: "chr7", position: 114083210 },
    },
    {
      fdr: 2.528857678908053e-5,
      trait: "MDD",
      score: -0.3322916,
      TF_impact: "pos",
      snp: "rs145232240",
      coordinates: { chromosome: "chr4", position: 79598985 },
    },
    {
      fdr: 1.3353007590656982,
      trait: "cannabis",
      score: -0.103514194,
      TF_impact: "pos",
      snp: "rs10118853",
      coordinates: { chromosome: "chr9", position: 108889742 },
    },
    {
      fdr: 1.4464760050049887,
      trait: "anorexia",
      score: 0.09540653,
      TF_impact: "none",
      snp: "rs10135188",
      coordinates: { chromosome: "chr14", position: 32004590 },
    },
    {
      fdr: 0.0002866573233599505,
      trait: "MDD",
      score: 0.29644966,
      TF_impact: "pos",
      snp: "rs12992411",
      coordinates: { chromosome: "chr2", position: 145038465 },
    },
    {
      fdr: 1.6044682090511768,
      trait: "cannabis",
      score: -0.03060627,
      TF_impact: "none",
      snp: "rs11198872",
      coordinates: { chromosome: "chr10", position: 119315803 },
    },
    {
      fdr: 1.689422985623576,
      trait: "opioid-vs-exposed-ctrl",
      score: -0.08600712,
      TF_impact: "pos",
      snp: "rs6504407",
      coordinates: { chromosome: "chr17", position: 66275871 },
    },
    {
      fdr: 1.7249301826957784e-9,
      trait: "ADHD-meta-filtered",
      score: 0.43569636,
      TF_impact: "none",
      snp: "rs11596250",
      coordinates: { chromosome: "chr10", position: 104784635 },
    },
    {
      fdr: 0.0010734837240857756,
      trait: "scz",
      score: -0.27901506,
      TF_impact: "none",
      snp: "rs6930933",
      coordinates: { chromosome: "chr6", position: 32415633 },
    },
    {
      fdr: 1.5911765010452652,
      trait: "autism",
      score: 0.088772774,
      TF_impact: "none",
      snp: "rs17762308",
      coordinates: { chromosome: "chr17", position: 45703582 },
    },
    {
      fdr: 0.001775830250921685,
      trait: "autism",
      score: 0.27016687,
      TF_impact: "pos",
      snp: "rs6945690",
      coordinates: { chromosome: "chr7", position: 3722691 },
    },
    {
      fdr: 1.2865998797073084,
      trait: "bipolar-2019",
      score: 0.013099909,
      TF_impact: "none",
      snp: "rs11669127",
      coordinates: { chromosome: "chr19", position: 58492463 },
    },
    {
      fdr: 0.0008533126837031249,
      trait: "anxiety",
      score: -0.28263092,
      TF_impact: "neg",
      snp: "rs11246872",
      coordinates: { chromosome: "chr12", position: 131583545 },
    },
    {
      fdr: 0.00413458204577938,
      trait: "autism",
      score: -0.2581706,
      TF_impact: "neg",
      snp: "rs9961111",
      coordinates: { chromosome: "chr18", position: 52160869 },
    },
    {
      fdr: 2.9519225175159185e-5,
      trait: "bipolar-2019",
      score: -0.3296938,
      TF_impact: "none",
      snp: "rs566370",
      coordinates: { chromosome: "chr4", position: 151990529 },
    },
    {
      fdr: 1.3696521318483237,
      trait: "anorexia",
      score: 0.01699543,
      TF_impact: "none",
      snp: "rs1700159",
      coordinates: { chromosome: "chr12", position: 51912002 },
    },
    {
      fdr: 1.7981663541215207,
      trait: "opioid-vs-unexposed-ctrl",
      score: -0.04169035,
      TF_impact: "none",
      snp: "rs6741762",
      coordinates: { chromosome: "chr2", position: 196068834 },
    },
    {
      fdr: 1.1963138896709156,
      trait: "cannabis",
      score: 0.009000778,
      TF_impact: "pos",
      snp: "rs799168",
      coordinates: { chromosome: "chr7", position: 73636534 },
    },
    {
      fdr: 1.2289390619572529,
      trait: "autism",
      score: -0.10820532,
      TF_impact: "pos",
      snp: "rs10421292",
      coordinates: { chromosome: "chr19", position: 49146241 },
    },
    {
      fdr: 1.4824317572217136,
      trait: "opioid-unexposed-vs-exposed",
      score: -0.024211407,
      TF_impact: "none",
      snp: "rs1995755",
      coordinates: { chromosome: "chr17", position: 77287459 },
    },
    {
      fdr: 1.4787417308432003,
      trait: "bipolar-I",
      score: 0.022166252,
      TF_impact: "none",
      snp: "rs10480087",
      coordinates: { chromosome: "chr7", position: 29923499 },
    },
    {
      fdr: 9.96061466524882e-5,
      trait: "bipolar-I",
      score: -0.31352234,
      TF_impact: "none",
      snp: "rs237475",
      coordinates: { chromosome: "chr20", position: 49432969 },
    },
    {
      fdr: 0.0027977838426575534,
      trait: "MDD",
      score: 0.26326418,
      TF_impact: "none",
      snp: "rs4891478",
      coordinates: { chromosome: "chr18", position: 72368663 },
    },
    {
      fdr: 1.8195981660031062,
      trait: "ADHD-meta-filtered",
      score: -0.043631554,
      TF_impact: "pos",
      snp: "rs4783757",
      coordinates: { chromosome: "chr16", position: 61421349 },
    },
    {
      fdr: 9.963390954335889e-13,
      trait: "bipolar-2019",
      score: -0.503459,
      TF_impact: "pos",
      snp: "rs12724815",
      coordinates: { chromosome: "chr1", position: 95084505 },
    },
    {
      fdr: 3.8131460217201585e-5,
      trait: "bipolar-2019",
      score: 0.32454872,
      TF_impact: "none",
      snp: "rs2667772",
      coordinates: { chromosome: "chr15", position: 77581562 },
    },
    {
      fdr: 0.2942626706986734,
      trait: "anorexia",
      score: -0.16684723,
      TF_impact: "pos",
      snp: "rs9839226",
      coordinates: { chromosome: "chr3", position: 172160313 },
    },
    {
      fdr: 1.8930167880798052,
      trait: "ADHD-meta-filtered",
      score: 0.05606985,
      TF_impact: "none",
      snp: "rs10759922",
      coordinates: { chromosome: "chr9", position: 117412158 },
    },
    {
      fdr: 1.6580989376886606,
      trait: "MDD",
      score: -0.08744955,
      TF_impact: "none",
      snp: "rs28384416",
      coordinates: { chromosome: "chr12", position: 109477378 },
    },
    {
      fdr: 0.06512930131065472,
      trait: "opioid-vs-unexposed-ctrl",
      score: 0.20377493,
      TF_impact: "pos",
      snp: "rs11706886",
      coordinates: { chromosome: "chr3", position: 105747211 },
    },
  ],
};
